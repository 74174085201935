import React from 'react';
import { func } from 'prop-types';
import { connect } from 'react-redux';

import { loadProfile } from '../../../actions/profile.actions';
import { changePassword, verifyNewEmail } from '../../../actions/member.actions';

import AccountDetails from '../../../components/admin/account-details/AccountDetails';
import Modal from '../../../components/modal/Modal';
import ModalLayout from '../../../components/modal/ModalLayout';
import ResetPassword from '../../../components/admin/reset-password/ResetPassword';
import PageLayout from '../../../components/layout/page/PageLayout';
import withAuthentication from '../../auth/withAuthentication';

class AccountDetailsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPasswordModal: false
    };
  }

  async componentDidMount() {
    const { dispatch } = this.props;
    dispatch(loadProfile());
  }

  onUpdateMember = (email) => {
    const { dispatch, profile } = this.props;

    dispatch(verifyNewEmail(profile.id, email));
  };

  onChangePassword = ({ current_password, password, password_confirm }) => {
    const { dispatch, profile } = this.props;

    dispatch(changePassword(profile.id, current_password, password, password_confirm));
    this.onClosePasswordModal();
  };

  onClosePasswordModal = () => {
    this.setState({ showPasswordModal: false });
  };

  onOpenPasswordModal = () => {
    this.setState({ showPasswordModal: true });
  };

  renderPasswordModal = () => {
    const { errors } = this.props;

    return this.state.showPasswordModal && (
      <Modal>
        <ModalLayout onClose={ this.onClosePasswordModal } title="Reset Password">
          <ResetPassword errors={ errors } onChangePassword={ this.onChangePassword } />
        </ModalLayout>
      </Modal>
    );
  };

  render() {
    const { profile, errors } = this.props;

    return (
      <PageLayout title="Account Details">
        <AccountDetails
          onOpenPasswordModal={ this.onOpenPasswordModal }
          profile={ profile }
          errors={ errors }
          onSave={ this.onUpdateMember }
        />
        { this.renderPasswordModal() }
      </PageLayout>
    );
  }
}

AccountDetailsContainer.defaultProps = {
  profile: {}
};

AccountDetailsContainer.propTypes = {
  dispatch: func.isRequired
};

const mapStateToProps = ({ profile, member }) => ({
  profile: profile.profile,
  errors: member.memberErrors
});

export default connect(mapStateToProps)(withAuthentication(AccountDetailsContainer));
