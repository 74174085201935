import { func } from 'prop-types';
import React from 'react';

import { REPORTED_ITEM_PROP_TYPE } from '../../../../tools/prop.types';
import { noOp } from '../../../../tools/helpers';

import Avatar from '../../../avatar/Avatar';
import Button from '../../../button/Button';

import './post-moderation-details.scss';

const renderPostDetails = (title, location, price, discount_price) => (
  <div className="post-details">
    <div className="post-details__info">
      <h5>{ title }</h5>
      <label>{ location }</label>
    </div>
    <div className="post-details__pricing">
      <h4>R{ discount_price }</h4>
      <span>R{ price }</span>
    </div>
  </div>
);

const PostSummary = ({ post: { title, location, image, price, discount_price } }) => (
  <div className="post-summary">
    <div className="post-summary__image">
      <img src={ image } alt={ title } width="100%" height="100%" />
    </div>
    { renderPostDetails(title, location, price, discount_price) }
  </div>
);

const renderCurator = ({ first_name, last_name }, dateReported, reasonForReport) => (
  <div className="curator">
    <h5 className="curator__header">Post Curator</h5>
    <div className="curator__user">
      <Avatar />
      <span>{ `${ first_name } ${ last_name }` }</span>
    </div>
    <div className="curator__date">
      <label>Date Reported: </label>
      <span>{ new Date(dateReported).toLocaleDateString() }</span>
    </div>
    <div className="curator__reason">
      <label>Reason for Report: </label>
      <span className="primary">{ reasonForReport }</span>
    </div>
  </div>
);

const renderReporter = ({ first_name, last_name }, additionalComment) => (
  <div className="reporter">
    <h5 className="reporter__header">Reporter</h5>
    <div className="reporter__user">
      <Avatar />
      <h5>{ `${ first_name } ${ last_name }` }</h5>
    </div>
    <div className="reporter__comment">
      <label>Additional Comment</label>
      <p>{ additionalComment ?? 'N/A' }</p>
    </div>
  </div>
);

const ModerationInfo = ({ item: { post: { member }, created_at, reason, reporter, comments } }) => (
  <div className="moderation-info">
    { renderCurator(member, created_at, reason) }
    { renderReporter(reporter, comments) }
  </div>
);

const PostModerationDetails = ({ item, onAcceptOrReject }) => (
  <div className="post-moderation">
    <div className="post-moderation__details">
      <PostSummary post={ item.post } />
      <ModerationInfo item={ item } />
    </div>
    <div className="post-moderation__actions">
      <Button label="Accept" onClick={ () => onAcceptOrReject('accept') } />
      <Button blank label="Reject" onClick={ () => onAcceptOrReject('reject') } />
    </div>
  </div>
);

PostModerationDetails.defaultProps = {
  item: {},
  onAcceptOrReject: noOp
};

PostModerationDetails.propTypes = {
  item: REPORTED_ITEM_PROP_TYPE,
  onAcceptOrReject: func
};

export default PostModerationDetails;
