import React from 'react';
import { bool, func, string } from 'prop-types';

import { noOp } from '../../../tools/helpers';

import Button from '../../button/Button';

import './form-action.scss';

const FormAction = ({ loading, label, disabled, onClick }) => (
  <div className="form-action">
    <Button
      loading={ loading }
      type="submit"
      label={ label }
      onClick={ onClick }
      disabled={ disabled }
    />
  </div>
);

FormAction.defaultProps = {
  onClick: noOp,
  loading: false,
  disabled: false
};

FormAction.propTypes = {
  loading: bool,
  disabled: bool,
  label: string.isRequired,
  onClick: func
};

export default FormAction;
