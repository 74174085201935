import { API_HOST, getHttpGetOptions, getHttpPutOptions } from './index';
import { getAuthHeaders } from '../auth.util';

const getMatrixEndpoint = () => `${ API_HOST }/reputation-matrix/`;
export const getRetrieveMatrixRequest = () => [
  getMatrixEndpoint(),
  getHttpGetOptions(getAuthHeaders())
];

const getActionEndpoint = (id) => `${ API_HOST }/reputation-actions/${id}/`;
export const getUpdateActionRequest = (id, action) => [
  getActionEndpoint(id),
  getHttpPutOptions(action, getAuthHeaders())
];
