import { func, node, number, shape, string } from 'prop-types';
import React, { useState } from 'react';

import { getDropdownPercentages } from '../../rep.helper';
import { noOp } from '../../../../../tools/helpers';

import Button from '../../../../button/Button';
import Form from '../../../../form/Form';
import FormRow from '../../../../form/formRow/FormRow';
import Input from '../../../../input/Input';
import Select from '../../../../select/Select';

import './reputation-level-edit.scss';

const getMinError = (minPostCount, maxPostCount) => {
  if (Number(minPostCount) >= Number(maxPostCount)) {
    return ['Minimum post count must be less than maximum'];
  }

  return undefined;
};

const getMaxError = (minPostCount, maxPostCount) => {
  if (Number(maxPostCount) === 0 || Number(maxPostCount) <= (minPostCount)) {
    return ['Maximum post count must be greater than minimum'];
  }

  return undefined;
};

const ReputationLevelForm = ({ rank, icon },
                             setPercentage,
                             percentage,
                             setPostFrequencyRange,
                             [minPostCount, maxPostCount],
                             onSave,
                             onCancel) => (
  <div className="rep-level-form">
    <Form>
      <FormRow>
        <Input label="Level Name"
               placeholder="Name"
               value={ rank }
               disabled
               leftLabel />
      </FormRow>
      <FormRow alignEnd>
        <Input label="Requirements"
               placeholder={ `Top ${ percentage }% of scorers` }
               disabled
               leftLabel />
        <Select placeholder="0%"
                items={ getDropdownPercentages() }
                value={ percentage }
                onChange={ ({ target: { value } }) => setPercentage(value) } />
      </FormRow>
      <FormRow>
        <Input placeholder={ `${ minPostCount ? minPostCount : 0 }-${ maxPostCount ? maxPostCount : 0 } posts per month` }
               disabled />
      </FormRow>
      <FormRow evenly>
        <Input label="Min post count"
               leftLabel
               placeholder="0"
               value={ minPostCount }
               error={ getMinError(minPostCount, maxPostCount) }
               onChange={ ({ target: { value } }) => setPostFrequencyRange([value, maxPostCount]) } />
      </FormRow>
      <FormRow>
        <Input label="Max post count"
               leftLabel
               placeholder="0"
               value={ maxPostCount }
               error={ getMaxError(minPostCount, maxPostCount) }
               onChange={ ({ target: { value } }) => setPostFrequencyRange([minPostCount, value]) } />
      </FormRow>
      <FormRow>
        <Button label="Save Changes"
                onClick={ onSave }
                disabled={ getMaxError(minPostCount, maxPostCount) || getMinError(minPostCount, maxPostCount) } />
      </FormRow>
      <FormRow>
        <Button label="Cancel" onClick={ onCancel } blank />
      </FormRow>
    </Form>
  </div>
);

const ReputationLevelEdit = ({ level, saveChanges, onCancel }) => {
  const [percentage, setPercentage] = useState(level.percentage);
  const [[minPostCount, maxPostCount], setPostFrequencyRange] = useState([level.minPostCount, level.maxPostCount]);

  const onSave = () => {
    saveChanges({
      ...level,
      percentage,
      minPostCount,
      maxPostCount
    });
  };

  return (
    <div className="rep-level-edit">
      <div className="rep-level-edit__icon">
        { level.icon }
      </div>
      { ReputationLevelForm(
        level,
        setPercentage,
        percentage,
        setPostFrequencyRange,
        [minPostCount, maxPostCount],
        onSave,
        onCancel
      ) }
    </div>
  );
};

ReputationLevelEdit.defaultProps = {
  level: {},
  saveChanges: noOp,
  onCancel: noOp
};

ReputationLevelEdit.propTypes = {
  level: shape({
    rank: string.isRequired,
    icon: node.isRequired,
    percentage: number.isRequired,
    minPostCount: number.isRequired,
    maxPostCount: number.isRequired
  }),
  saveChanges: func,
  onCancel: func
};

export default ReputationLevelEdit;
