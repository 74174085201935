export const LOAD_POSTS = '[POST_STORE] Load posts';
export const SET_POSTS = '[POST_STORE] Set posts';
export const loadPosts = (onSuccess, nextPage = 1) => ({
  type: LOAD_POSTS,
  onSuccess,
  nextPage
});

export const REMOVE_POST = '[POST_STORE] Remove post';
export const removePost = (id, onSuccess) => ({
  type: REMOVE_POST,
  id,
  onSuccess
});

export const LOAD_TAGS = '[POST_STORE] Load tags';
export const SET_TAGS = '[POST_STORE] Set tags';
export const loadTags = (stopLoading) => ({
  type: LOAD_TAGS,
  stopLoading
});

export const LOAD_CURATORS = '[POST_STORE] Load curators';
export const SET_CURATORS = '[POST_STORE] Set curators';
export const loadCurators = (onSuccess) => ({
  type: LOAD_CURATORS,
  onSuccess
});

export const CREATE_TAG = '[POST_STORE] Create tag';
export const createTag = (tag, stopLoading) => ({
  type: CREATE_TAG,
  tag,
  stopLoading
});

export const EDIT_TAG = '[POST_STORE] Edit tag';
export const editTag = (tag, stopLoading) => ({
  type: EDIT_TAG,
  tag,
  stopLoading
});

export const REMOVE_TAG = '[POST_STORE] Remove tag';
export const removeTag = (tag, stopLoading) => ({
  type: REMOVE_TAG,
  tag,
  stopLoading
});

export const LOAD_CATEGORIES = '[POST_STORE] Load categories';
export const SET_CATEGORIES = '[POST_STORE] Set categories';
export const loadCategories = (stopLoading) => ({
  type: LOAD_CATEGORIES,
  stopLoading
});

export const CREATE_CATEGORY = '[POST_STORE] Create category';
export const createCategory = (category, stopLoading) => ({
  type: CREATE_CATEGORY,
  category,
  stopLoading
});

export const EDIT_CATEGORY = '[POST_STORE] Edit category';
export const editCategory = (category, stopLoading) => ({
  type: EDIT_CATEGORY,
  category,
  stopLoading
});

export const REMOVE_CATEGORY = '[POST_STORE] Remove category';
export const removeCategory = (category, stopLoading) => ({
  type: REMOVE_CATEGORY,
  category,
  stopLoading
});

export const LOAD_EMPTY_CATEGORIES = '[POST_STORE] Load empty categories';
export const SET_EMPTY_CATEGORIES = '[POST_STORE] Set empty categories';
export const loadEmptyCategories = (stopLoading) => ({
  type: LOAD_EMPTY_CATEGORIES,
  stopLoading
});
