/**
 * @module Popper Component
 * @description
 * Wrapper around PopperJS (https://github.com/popperjs/react-popper), the element
 *  positioning library.
 * Will accept `children` that serve as the trigger element.
 * The popper element is the `DropdownMenu` component, constructed from provided
 *  `menuItems`, and is visible upon clicking the trigger element.
 */
import React, { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import { arrayOf, bool, func, node, number, oneOfType, shape, string } from 'prop-types';

import { getClassNames, isUndefined, noOp } from '../../tools/helpers';

import PopperMenu from './PopperMenu';

import './popper.scss';

/**
 * Popper options setup with placement and offset (menu distance from trigger).
 *
 * @param {String} placement - where to place the popper, relative to trigger
 *  ('top', 'top-start', 'bottom-end', 'left', 'right', etc.)
 */
const getPopperOptions = (placement, yOffset) => ({
  placement,
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, yOffset]
      }
    }
  ]
});

/**
 * Decide whether to display the overlay, based on `popperOpen`. Will
 *  hide the popper menu if clicked.
 *
 * @param {Boolean} popperOpen - state of whether the popper menu is visible
 * @param {Function} handleOpenPopper - function to set value of popperOpen
 */
const renderOverlay = (popperOpen, handleOpenPopper) =>
  popperOpen && <div className="popper__overlay" onClick={(e) => handleOpenPopper(e, false)} />;

const renderPopperElement = (menu, menuItems, closePopper, showAbout, small, notRestricted) =>
  menu || (
    <PopperMenu
      small={small}
      showAbout={showAbout}
      menuItems={menuItems}
      closePopper={closePopper}
      notRestricted={notRestricted}
    />
  );

const Popper = ({
  menu,
  menuItems,
  placement,
  expandPopper,
  onOpenPopper,
  open,
  yOffset,
  children,
  showAbout,
  small,
  notRestricted
}) => {
  // reference and setter to the dropdown trigger element:
  const [referenceElement, setReferenceElement] = useState(null);

  // reference and setter to the popup/dropdown element:
  const [popperElement, setPopperElement] = useState(null);

  // dropdown visiblity control:
  const [popperOpen, setPopperOpen] = useState(open);

  // initialise popper, with custom options
  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    getPopperOptions(placement, yOffset)
  );

  // sets visiblity, calls `onOpenPopper` handler
  const handleOpenPopper = (e, isOpen) => {
    if (e) {
      e.stopPropagation();
    }
    setPopperOpen(isOpen);
    onOpenPopper(isOpen);

    void update();
  };

  // when prop `open` is defined, it means something is controlling the popper,
  //  so it takes preference.
  useEffect(() => {
    if (!isUndefined(open)) {
      if (!open && popperOpen) {
        handleOpenPopper(open);
      }
    }

    /**
     * Close the select options if clicked outside of element.
     */
    const handleClickOutside = (event) => {
      if (popperElement && !popperElement.contains(event.target)) {
        setPopperOpen(false);
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <div className="popper">
      <div
        className="popper__trigger"
        ref={setReferenceElement}
        onClick={(e) => handleOpenPopper(e, !popperOpen)}
      >
        {children}
      </div>

      {renderOverlay(popperOpen, handleOpenPopper)}

      <div
        className={getClassNames('popper__menu', { open: popperOpen, expand: expandPopper, small })}
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        {renderPopperElement(menu, menuItems, handleOpenPopper, showAbout, small, notRestricted)}
      </div>
    </div>
  );
};

Popper.defaultProps = {
  menu: null,
  menuItems: [],
  placement: 'bottom-end',
  expandPopper: false,
  onOpenPopper: noOp,
  open: undefined,
  yOffset: 0,
  showAbout: false,
  small: false,
  isActivity: false
};

Popper.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  menu: node,
  menuItems: arrayOf(
    shape({
      label: string.isRequired,
      onClick: func
    })
  ),
  placement: string,
  expandPopper: bool,
  onOpenPopper: func,
  open: bool,
  yOffset: number,
  showAbout: bool,
  small: bool
};

export default Popper;
