import React from 'react';
import { useHistory } from 'react-router-dom';
import { arrayOf, node, oneOfType, string } from 'prop-types';

import { ReactComponent as LeftArrowIcon } from '../../../icons/left-arrow-icon.svg';

import './page-layout.scss';

const renderBack = (back, goBack) => back && (
  <LeftArrowIcon onClick={ goBack } />
);

const PageLayout = ({ title, back, children }) => {
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };

  return (
    <div className="page-layout">
      <h1 className="page-layout__title">{ renderBack(back, goBack) } { title }</h1>
      <div className="page-layout__content">
        { children }
      </div>
    </div>
  );
};

PageLayout.propTypes = {
  title: string.isRequired,
  children: oneOfType([arrayOf(node), node]).isRequired
};

export default PageLayout;
