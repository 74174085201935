import React from 'react';
import { arrayOf, node, oneOfType, string } from 'prop-types';

import './auth-layout.scss';

const renderLogo = (logo) => (
  <div className="auth-layout__logo">
    { logo }
    <div className="auth-layout__text">
      Smart<span className="auth-layout__text-split">B</span><span className="auth-layout__text-split-i">iii</span>
      <span className="auth-layout__text-portal">Admin portal</span>
    </div>
  </div>
);

const AuthLayout = ({ title, subtitle, children, logo }) => (
  <div className="auth-layout">
    { logo && renderLogo(logo) }
    <div className="auth-layout__detail">
      <div className="auth-layout__header">
        <h1 className="auth-layout__title">{ title }</h1>
        <p className="auth-layout__subtitle">{ subtitle }</p>
      </div>
      <div className="auth-layout__content">
        { children }
      </div>
    </div>
  </div>
);

AuthLayout.defaultProps = {
  subtitle: '',
  logo: undefined
};

AuthLayout.propTypes = {
  title: oneOfType([string, node]).isRequired,
  subtitle: string,
  children: oneOfType([arrayOf(node), node]).isRequired,
  logo: node
};

export default AuthLayout;
