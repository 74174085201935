import React, { createRef } from 'react';

import Form from '../../form/Form';
import FormRow from '../../form/formRow/FormRow';
import Input from '../../input/Input';
import TextArea from '../../textarea/TextArea';
import FormAction from '../../form/formAction/FormAction';

import './create-category.scss';
import { noOp } from '../../../tools/helpers';

const renderForm = (nameRef, descriptionRef, onClose, onCreate) => (
  <Form>
    <FormRow>
      <Input placeholder="Category name" label="Category Name" inputRef={ nameRef } />
    </FormRow>
    <FormRow>
      <TextArea placeholder="Enter a description..." label="Description" textAreaRef={ descriptionRef } />
    </FormRow>
    <div className="create-category__action">
      <FormAction label="Continue" onClick={ onCreate } />
    </div>
  </Form>
);

const CreateCategory = ({ onClose, saveCategory = noOp }) => {
  const nameRef = createRef();
  const descriptionRef = createRef();

  const onCreate = () => {
    saveCategory({ name: nameRef.current.value, description: descriptionRef.current.value });
  };

  return (
    <div className="create-category">
      <div className="create-category__form">
        { renderForm(nameRef, descriptionRef, onClose, onCreate) }
      </div>
    </div>
  );
};

export default CreateCategory;
