import React from 'react';
import { useHistory } from 'react-router';

import { ReactComponent as CategoryIcon } from './../../../icons/category-icon.svg';
import { ReactComponent as EmptyCategories } from './../../../icons/empty-categories.svg';
import { ReactComponent as Options } from './../../../icons/options-icon.svg';
import InformationCard from '../../card/InformationCard';
import CuratorsCard from '../../card/CuratorsCard';

import './posts-dashboard.scss';

const PostsDashboard = ({ categories, tags, curators, posts }) => {
  const history = useHistory();

  const navigateCurators = () => {
    history.push('/admin/curators');
  };

  const navigateReportedPosts = () => {
    history.push('/admin/moderation');
  };

  const navigatePostCategories = () => {
    history.push('/admin/categories');
  };

  const navigateEmptyCategories = () => {
    history.push('/admin/empty-categories');
  };

  const navigateTags = () => {
    history.push('/admin/tags');
  };

  const navigatePosts = () => {
    history.push('/admin/posts');
  };

  return (
    <div className="posts-dashboard">
      <div className="posts-dashboard__categories">
        <InformationCard navigate={ navigatePostCategories }
                         icon={ CategoryIcon }
                         numberToDisplay={ categories.length }
                         label="Post Categories" />
        <InformationCard icon={ EmptyCategories }
                         numberToDisplay={ categories?.filter(category => category.related_posts < 1)?.length ?? 0 }
                         label="Empty Categories"
                         large
                         navigate={ navigateEmptyCategories } />
        <InformationCard icon={ Options }
                         numberToDisplay={ tags.length }
                         label="Posts Tags"
                         navigate={ navigateTags } />
        <InformationCard icon={ EmptyCategories }
                         numberToDisplay={ posts }
                         label="All Posts"
                         navigate={ navigatePosts } />
      </div>
      <div className="posts-dashboard__curator-cards">
        <CuratorsCard
          key="all-posts"
          navigate={ navigateCurators }
          info="A full list of all the curators who have ever made a post"
          label="All Post Curators"
          curators={ curators }
        />
        <CuratorsCard
          key="member-moderation"
          navigate={ navigateReportedPosts }
          info="A list of entries that require attention from an administrator to be moderated"
          label="Member Moderation"
        />
      </div>
    </div>
  );
};

PostsDashboard.defaultProps = {
  categories: [],
  tags: [],
  posts: [],
  curators: []
};

export default PostsDashboard;

