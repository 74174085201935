import React from 'react';
import { arrayOf, bool, func, number, oneOfType, shape, string } from 'prop-types';

import { noOp } from '../../../tools/helpers';

import { ReactComponent as SearchIcon } from './../../../icons/search-icon.svg';

import Input from '../../input/Input';
import Button from '../../button/Button';
import Select from '../../select/Select';

import './list-action.scss';

const ListAction = ({ onSearch, onSort, onActionClick, buttonLabel, noButton, inputPlaceholder, itemsToSort }) => (
  <div className="members-list-action">
    <div className="members-list-action__filters">
      <Input placeholder={ inputPlaceholder } icon={ SearchIcon } onChange={ onSearch } />
      <Select placeholder="Sort by..." items={ itemsToSort } onChange={ onSort } />
    </div>
    <div className="members-list-action__invite">
      { !noButton && <Button label={ buttonLabel } onClick={ onActionClick } /> }
    </div>
  </div>
);

ListAction.defaultProps = {
  onSearch: noOp,
  onSort: noOp,
  onActionClick: noOp,
  buttonLabel: '',
  noButton: false,
  inputPlaceholder: ''
};

ListAction.propTypes = {
  onSearch: func,
  onSort: func,
  onActionClick: func,
  buttonLabel: string,
  noButton: bool,
  inputPlaceholder: string,
  itemsToSort: arrayOf(shape({
    value: oneOfType([string, number]).isRequired,
    text: string.isRequired
  }))
};

export default ListAction;
