import { SET_MATRIX } from '../actions/reputation.actions';

export const initialState = {
  matrix: []
};

export function reputationReducer(state = initialState, action) {
  switch (action.type) {
    case SET_MATRIX:
      const { matrix } = action;
      return {
        ...state,
        matrix
      };
    default:
      return state;
  }
}
