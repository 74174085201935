import { API_HOST, getHttpDeleteOptions, getHttpGetOptions, getHttpPostOptions, getHttpPutOptions } from './index';
import { getAuthHeaders } from '../auth.util';

const getRetrieveMembersEndpoint = () => `${ API_HOST }/members/`;
export const getRetrieveMembersRequest = (search, ordering) => [
  getRetrieveMembersEndpoint(),
  getHttpGetOptions(getAuthHeaders(), { search, ordering })
];

const getRetrieveMemberEndpoint = (id) => `${ API_HOST }/members/${ id }/`;
export const getRetrieveMemberRequest = (id) => [
  getRetrieveMemberEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

export const getUpdateMemberRequest = (id, member) => [
  getRetrieveMemberEndpoint(id),
  getHttpPutOptions(member, getAuthHeaders())
];

export const getUpdateAdminRequest = (id, admin) => [
  getRetrieveMemberEndpoint(id),
  getHttpPutOptions(admin, getAuthHeaders())
];

const getVerifyEmailEndpoint = (id) => `${ API_HOST }/members/${ id }/verify-email`;
export const getVerifyNewEmailRequest = (id, email) => [
  getVerifyEmailEndpoint(id),
  getHttpPutOptions(email, getAuthHeaders())
];

const getChangePasswordEndpoint = (id) => `${ API_HOST }/members/${ id }/change-password`;
export const getChangePasswordRequest = (id, current_password, password, password_confirm) => [
  getChangePasswordEndpoint(id),
  getHttpPutOptions({ current_password, password, password_confirm }, getAuthHeaders())
];

export const getRemoveMemberRequest = (id) => [
  getRetrieveMemberEndpoint(id),
  getHttpDeleteOptions(getAuthHeaders())
];

const getActivateMemberEndpoint = (id) => `${ API_HOST }/members/${ id }/activate`;
export const getActivateMemberRequest = (id) => [
  getActivateMemberEndpoint(id),
  getHttpPutOptions({ id }, getAuthHeaders())
];

const getDeactivateMemberEndpoint = (id) => `${ API_HOST }/members/${ id }/deactivate`;
export const getDeactivateMemberRequest = (id) => [
  getDeactivateMemberEndpoint(id),
  getHttpPutOptions({ id }, getAuthHeaders())
];

const getInviteAdminEndpoint = () => `${ API_HOST }/members/invite`;
export const getInviteAdminRequest = (email) => [
  getInviteAdminEndpoint(),
  getHttpPostOptions({ email }, getAuthHeaders())
];

const getCheckEmailEndpoint = (email) => `${ API_HOST }/members/${ email }/exists`;
export const getCheckEmailRequest = (email) => [
  getCheckEmailEndpoint(email),
  getHttpGetOptions(getAuthHeaders())
];

const getActivitiesEndpoint = (id) => `${API_HOST}/members/${id}/activities/`;
export const getActivitiesRequest = (id) => [
  getActivitiesEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

const getNotificationsEndpoint = (id, nextPage) =>
  !nextPage
    ? `${API_HOST}/members/1/notifications/`
    : `${API_HOST}/members/1/notifications/?page=${nextPage}`;
export const getNotificationsRequest = (id, nextPage) => [
  getNotificationsEndpoint(id, nextPage),
  getHttpGetOptions(getAuthHeaders())
];

const getUnreadNotificationsEndpoint = (id) => `${API_HOST}/members/1/notifications/unread/`;
export const getUnreadNotificationsRequest = (id) => [
  getUnreadNotificationsEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

const getReadNotificationEndpoint = (id, notificationId) =>
  `${API_HOST}/members/1/notifications/${notificationId}/read`;
export const getReadNotificationRequest = (id, notificationId) => [
  getReadNotificationEndpoint(id, notificationId),
  getHttpPutOptions(undefined, getAuthHeaders())
];

const getReadAllNotificationEndpoint = (id) => `${API_HOST}/members/1/notifications/all/`;
export const getReadAllNotificationRequest = (id) => [
  getReadAllNotificationEndpoint(id),
  getHttpPutOptions(undefined, getAuthHeaders())
];

const getProcessNotificationEndpoint = (id, notificationId) =>
  `${API_HOST}/members/1/notifications/${notificationId}/process`;
export const getProcessNotificationRequest = (id, notificationId) => [
  getProcessNotificationEndpoint(id, notificationId),
  getHttpPutOptions(undefined, getAuthHeaders())
];

const getRetrieveMemberPostsPublishedEndpoint = (id) =>
  `${API_HOST}/members/${id}/posts-published/`;
export const getRetrieveMemberPostsPublishedRequest = (id) => [
  getRetrieveMemberPostsPublishedEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];
