import React, { useEffect, useState } from 'react';

import './toggle-switch.scss';

const ToggleSwitch = ({ checked, onChange }) => {
  const [currentChecked, setCurrentChecked] = useState(checked);

  useEffect(() => {
    setCurrentChecked(checked);
    return () => {};
  }, [checked]);

  return (
    <label className="toggle-switch">
      <input
        onChange={onChange}
        type="checkbox"
        checked={currentChecked}
        onClick={() => setCurrentChecked(!currentChecked)}
      />
      <span className="toggle-switch__slider--round" />
    </label>
  );
};

export default ToggleSwitch;
