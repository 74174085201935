import { func, oneOf } from 'prop-types';
import React, { useState } from 'react';

import { BAN_STATUSES } from '../member-moderation.helper';
import { noOp } from '../../../../tools/helpers';

import Button from '../../../button/Button';
import Form from '../../../form/Form';
import FormRow from '../../../form/formRow/FormRow';
import TextArea from '../../../textarea/TextArea';

import './accept-reject-moderation.scss';

const renderReasonField = (reason, setReason, label, placeholder = '') => (
  <FormRow>
    <TextArea label={ label }
              placeholder={ placeholder }
              value={ reason }
              onChange={ ({ target: { value } }) => setReason(value) } />
  </FormRow>
);

const renderAcceptForm = (originalAction,
                          updatedAction,
                          setAction,
                          reason,
                          setReason) => (
  <Form>
    <FormRow>
      <p style={{ textAlign: 'center', width: '100%' }}>The will delete the post/comment linked to the report.</p>
    </FormRow>
    {/*<FormRow>*/}
    {/*  <Select placeholder="None"*/}
    {/*          label="Ban Status"*/}
    {/*          items={ getBanStatusesForDropdown(originalAction) }*/}
    {/*          value={ updatedAction }*/}
    {/*          onChange={ ({ target: { value } }) => setAction(value) } />*/}
    {/*</FormRow>*/}
    {/*{ isMoreSevere(originalAction, updatedAction) &&*/}
    {/*renderReasonField(reason, setReason, 'Reason for harsher penalty', 'State your reason for a harsher penalty')*/}
    {/*}*/}
  </Form>
);

const renderRejectForm = (reason, setReason) => (
  <Form>
    <FormRow>
      { renderReasonField(reason, setReason, 'Reason for rejection') }
    </FormRow>
  </Form>
);

const AcceptRejectModeration = ({ acceptOrReject, action, onAcceptOrReject, onCancel }) => {
  const [updatedAction, setAction] = useState(action);
  const [reason, setReason] = useState(undefined);

  const onSubmit = () => {
    onAcceptOrReject({
      action: updatedAction,
      state: acceptOrReject,
      reason
    });
  };

  return (
    <div className="accept-reject-moderation">
      { acceptOrReject === 'accept' && renderAcceptForm(action, updatedAction, setAction, reason, setReason) }
      { acceptOrReject === 'reject' && renderRejectForm(reason, setReason) }
      <div className="accept-reject-moderation__actions">
        <Button label="Submit" onClick={ onSubmit } />
        <Button blank label="Cancel" onClick={ onCancel } />
      </div>
    </div>
  );
};

AcceptRejectModeration.defaultProps = {
  acceptOrReject: 'accept',
  action: 'Warning',
  onAcceptOrReject: noOp,
  onCancel: noOp
};

AcceptRejectModeration.propTypes = {
  acceptOrReject: oneOf(['accept', 'reject']),
  action: oneOf(BAN_STATUSES),
  onAcceptOrReject: func,
  onCancel: func
};

export default AcceptRejectModeration;
