import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { resendOTP, validateOTP } from '../../../actions/auth.actions';

import { ReactComponent as AdminLogo } from '../../../icons/admin-logo.svg';

import AuthLayout from '../../../components/layout/auth/AuthLayout';
import OTP from '../../../components/auth/otp/OTP';

const OTPContainer = ({ dispatch, match: { params: { token } } }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const onValidateOTP = (otp) => {
    setLoading(true);
    dispatch(validateOTP(token, otp, history, () => setLoading(false)));
  };

  const onResendOtp = () => {
    dispatch(resendOTP(token));
  };

  return (
    <AuthLayout title="OTP verification" subtitle="We sent your code to your email address" logo={ <AdminLogo /> }>
      <OTP onValidateOTP={ onValidateOTP } onResendOtp={ onResendOtp } loading={ loading } />
    </AuthLayout>
  );
};

const mapStateToProps = ({ auth }) => ({
  errors: auth.passwordErrors
});

export default connect(mapStateToProps)(OTPContainer);
