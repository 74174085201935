import React from 'react';
import { sortItems, tableColumns, tableModifiers } from './post-curatorst.helpers';
import { arrayOf, func, number, shape, string } from 'prop-types';

import { noOp } from '../../../tools/helpers';

import Table from '../../table/Table';
import ListAction from '../../members/list-action/ListAction';
import Avatar from '../../avatar/Avatar';

import './post-curators.scss';

const renderFullName = (item) => (
  <div className="post-curators__name">
    <span>{ `${ item.first_name } ${ item.last_name }` }</span>
  </div>
);

const formatRows = (items) => items
  .map((item) => ({
    ...item,
    avatar: <Avatar imageSource={ item.profile_image } />,
    fullName: renderFullName(item)
  }));

const PostCurators = ({ curators, loading }) => (
  <div className="post-curators">
    <div className="post-curators__header">
      <ListAction
        inputPlaceholder="search curators..."
        buttonLabel="Add new category"
        noButton
        itemsToSort={ sortItems }
      />
    </div>
    <Table cols={ tableColumns }
           headerModifiers={ tableModifiers }
           columnModifiers={ tableModifiers }
           rowData={ formatRows(curators) }
           loading={ loading }
    />
  </div>
);

PostCurators.defaultProps = {
  curators: [],
  onClickAction: noOp,
  onEdit: noOp,
  onDelete: noOp
};

PostCurators.propTypes = {
  curators: arrayOf(shape({
    fullName: string,
    date: string,
    level: string,
    followers: number,
    connections: number,
    postNumber: number,
    mostUsedCategory: string
  })),
  onClickAction: func,
  onEdit: func,
  onDelete: func
};

export default PostCurators;
