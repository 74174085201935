export const RETRIEVE_MEMBERS = '[MEMBER_STORE] Retrieve members';
export const SET_MEMBERS = '[MEMBER_STORE] Set members';
export const retrieveMembers = ({ search, ordering, stopLoading }) => ({
  type: RETRIEVE_MEMBERS,
  search,
  ordering,
  stopLoading
});

export const INVITE_ADMINS = '[MEMBER_STORE] Invite admins';
export const SET_INVITE_ERRORS = '[MEMBER_STORE] Add invite error';
export const inviteAdmins = (emails, stopLoading) => ({
  type: INVITE_ADMINS,
  emails,
  stopLoading
});

export const RETRIEVE_MEMBER = '[MEMBER_STORE] Retrieve member';
export const SET_MEMBER = '[MEMBER_STORE] Set member';
export const retrieveMember = (id, stopLoading) => ({
  type: RETRIEVE_MEMBER,
  id,
  stopLoading
});

export const UPDATE_MEMBER = '[MEMBER_STORE] Update member';
export const SET_MEMBER_ERRORS = '[MEMBER_STORE] Set member errors';
export const updateMember = ( id, member, stopLoading ) => ({
  type: UPDATE_MEMBER,
  id,
  member,
  stopLoading
});

export const UPDATE_ADMIN = '[MEMBER_STORE] Update profile';
export const updateAdmin = (id, admin) => ({
  type: UPDATE_ADMIN,
  id,
  admin
});

export const VERIFY_NEW_EMAIL = '[MEMBER_STORE] Verify new email';
export const verifyNewEmail = (id, email) => ({
  type: VERIFY_NEW_EMAIL,
  id,
  email
});

export const CHANGE_PASSWORD = '[MEMBER_STORE] Change password';
export const changePassword = (id, current_password, password, password_confirm) => ({
  type: CHANGE_PASSWORD,
  id,
  current_password,
  password,
  password_confirm
});

export const DELETE_MEMBER = '[MEMBER_STORE] Delete member';
export const removeMember = (id, history) => ({
  type: DELETE_MEMBER,
  id,
  history
});

export const ACTIVATE_MEMBER = '[MEMBER_STORE] Activate member';
export const activateMember = (id, stopLoading) => ({
  type: ACTIVATE_MEMBER,
  id,
  stopLoading
});

export const DEACTIVATE_MEMBER = '[MEMBER_STORE] Deactivate member';
export const deactivateMember = (id, stopLoading) => ({
  type: DEACTIVATE_MEMBER,
  id,
  stopLoading
});

export const CHECK_IF_EMAIL_EXISTS = '[MEMBER_STORE] Check if email exists';
export const checkIfEmailExists = (email) => ({
  type: CHECK_IF_EMAIL_EXISTS,
  email
});

export const REMOVE_INVITE_ERROR = '[MEMBER_STORE] Remove invite error';
export const removeInviteError = (tag) => ({
  type: REMOVE_INVITE_ERROR,
  tag
});

export const RESET_INVITE_ERRORS = '[MEMBER_STORE] Reset invite errors';
export const resetInviteErrors = () => ({
  type: RESET_INVITE_ERRORS
});

export const RETRIEVE_REPORTED_POSTS = '[REPORT_STORE] Retrieve reported posts';
export const SET_REPORTED_POSTS = '[REPORT_STORE] Set reported posts';

export const retrieveReportedPosts = () => ({
  type: RETRIEVE_REPORTED_POSTS
});

export const LOAD_ACTIVITY = '[MEMBER_STORE] Load activity';
export const SET_ACTIVITIES = '[MEMBER_STORE] Set activities';
export const loadActivity = (id) => ({
  type: LOAD_ACTIVITY,
  id
});

export const LOAD_NOTIFICATIONS = '[MEMBER_STORE] Load notifications';
export const SET_NOTIFICATIONS = '[MEMBER_STORE] Set notifications';
export const loadNotifications = (id, nextPage) => ({
  type: LOAD_NOTIFICATIONS,
  id,
  nextPage
});

export const LOAD_UNREAD_NOTIFICATION = '[MEMBER_STORE] Load unread Notifications';
export const SET_UNREAD_NOTIFICATION = '[MEMBER_STORE] Set unread Notifications';
export const loadUnreadNotifications = (id) => ({
  type: LOAD_UNREAD_NOTIFICATION,
  id
});

export const READ_ALL_NOTIFICATION = '[MEMBER_STORE] Read all Notification';
export const readAllNotification = (id) => ({
  type: READ_ALL_NOTIFICATION,
  id
});

export const READ_NOTIFICATION = '[MEMBER_STORE] Read Notification';
export const readNotification = (id, notificationId) => ({
  type: READ_NOTIFICATION,
  id,
  notificationId
});

export const PROCESS_NOTIFICATION = '[MEMBER_STORE] Process Notification';
export const processNotification = (id, notificationId) => ({
  type: PROCESS_NOTIFICATION,
  id,
  notificationId
});
