import React from 'react';
import { func, string } from 'prop-types';

import { getClassNames, noOp } from '../../tools/helpers';

import Loader from '../loader/Loader';

import './button.scss';

const Button = ({ label, blank, type, onClick, disabled, loading }) => (
  <button disabled={ disabled } onClick={ onClick } className={ getClassNames('button', { blank, disabled }) } type={ type }>
    { !loading && label }
    { loading && <Loader /> }
  </button>
);

Button.defaultProps = {
  onClick: noOp,
  type: 'button'
};

Button.propTypes = {
  onClick: func,
  label: string.isRequired,
  type: string
};

export default Button;
