import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { requestLogin } from '../../../actions/auth.actions';

import { ReactComponent as AdminLogo } from '../../../icons/admin-logo.svg';

import AuthLayout from '../../../components/layout/auth/AuthLayout';
import Login from '../../../components/auth/login/Login';

const renderTitle = () => (
  <div className="auth-layout__text">
    Welcome to Smart<span className="auth-layout__text-split">B</span><span className="auth-layout__text-split-i">iii</span>
  </div>
);

const LoginContainer = ({ dispatch, errors }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const login = ({ email, password, rememberMe }) => {
    setLoading(true);
    dispatch(requestLogin(email, password, rememberMe, history, () => setLoading(false)));
  };

  return (
    <AuthLayout title={ renderTitle() } subtitle="Sign in with your email address and password" logo={ <AdminLogo /> }>
      <Login errors={ errors } onLogin={ login } loading={ loading } />
    </AuthLayout>
  );
};

const mapStateToProps = ({ auth }) => ({
  errors: auth.loginErrors
});

export default connect(mapStateToProps)(LoginContainer);
