import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { arrayOf, shape } from 'prop-types';

import { getClassNames, isEmpty, noOp } from '../../../tools/helpers';
import { howLongAgo } from '../../../tools/date.util';

import Tabs from '../../tabs/Tabs';
import Avatar from '../../avatar/Avatar';
import Button from '../../button/Button';
import NotFound from '../../not-found/NotFound';

import './notifications.scss';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import Loader from '../../loader/Loader';

const getItems = () => [
  {
    label: 'All'
  },
  {
    label: 'Unread'
  }
];

const confirmAndMarkAsRead = (
  notification,
  onConfirm,
  markAsRead,
  connectionId,
  onMarkAsProcessed
) => {
  onConfirm(connectionId);
  markAsRead(notification);
  onMarkAsProcessed(notification);
};

const deleteAndMarkAsRead = (
  notification,
  onDelete,
  markAsRead,
  connectionId,
  onMarkAsProcessed
) => {
  onDelete(connectionId);
  markAsRead(notification);
  onMarkAsProcessed(notification);
};

const renderConnectionActions = (
  notification,
  onConfirm,
  onDelete,
  markAsRead,
  onMarkAsProcessed,
  connections = []
) => {
  const connectionId = notification.connection?.id;
  const matchingConnection = connections.find((connection) => connection.id === connectionId);

  if (notification.type === 11 && !notification.processed && !matchingConnection) {
    return (
      <div className="notifications__connection-actions">
        <Button
          label="Confirm"
          onClick={() =>
            confirmAndMarkAsRead(
              notification,
              onConfirm,
              markAsRead,
              connectionId,
              onMarkAsProcessed
            )
          }
        />
        <Button
          label="Delete"
          hollow
          onClick={() =>
            deleteAndMarkAsRead(notification, onDelete, markAsRead, connectionId, onMarkAsProcessed)
          }
        />
      </div>
    );
  }
};

const getLimitedTitle = (title) => {
  if (title?.length > 40) {
    return title.substring(0, 39).trim() + '...';
  }

  return title;
};

// const getCommentNotification = (notification, history) => {
//   if (notification.comment?.post?.id) {
//     const navigateToPost = () =>
//       history.push(
//         `/member/${notification.member?.id}/posts?postId=${notification.comment?.post?.id}&commentId=${notification.comment?.id}`
//       );

//     return [
//       notification.comment?.post?.title,
//       notification.comment?.post?.image,
//       navigateToPost,
//       <label onClick={navigateToPost}>
//         <span>{notification.comment?.member?.first_name}</span>{' '}
//         <span>{notification.comment?.member?.last_name} </span>
//         has commented on your&nbsp;<span>{notification.comment?.post?.title}</span> post
//       </label>
//     ];
//   } else if (notification.comment?.list_id) {
//     const navigateToList = () =>
//       history.push(
//         `/member/${notification.member?.id}/lists/${notification.comment?.list_id}?commentId=${notification.comment?.id}`
//       );

//     return [
//       notification.comment?.list?.title,
//       notification.comment?.list?.image,
//       navigateToList,
//       <label onClick={navigateToList}>
//         <span>{notification.comment?.member?.first_name}</span>{' '}
//         <span>{notification.comment?.member?.last_name} </span>
//         has commented on your&nbsp;<span>{notification.comment?.list?.title}</span> list
//       </label>
//     ];
//   } else if (notification.comment?.list_item_id) {
//     const navigateToListItem = () =>
//       history.push(
//         `/member/${notification.member?.id}/lists/${notification.comment?.list_item?.list_id}` +
//           `?listItemId=${notification.comment.list_item_id}&listItemCommentId=${notification.comment?.id}`
//       );

//     return [
//       notification.comment?.list_item?.title,
//       notification.comment?.list_item?.image,
//       navigateToListItem,
//       <label onClick={navigateToListItem}>
//         <span>{notification.comment?.member?.first_name}</span>{' '}
//         <span>{notification.comment?.member?.last_name} </span>
//         has commented on your&nbsp;<span>{notification.comment?.list_item?.title}</span> list item
//       </label>
//     ];
//   }
// };

const mapLabel = (notification, history) => {
  switch (notification.type) {
    case 0:
      return [
        undefined,
        undefined,
        noOp,
        <label>
          You liked a <span>post</span>
        </label>
      ];
    // case 1:
    //   return [
    //     undefined,
    //     undefined,
    //     noOp,
    //     <label>
    //       You shared a <span>post</span>
    //     </label>
    //   ];
    // case 2:
    //   return [
    //     undefined,
    //     undefined,
    //     noOp,
    //     <label>
    //       You commented on a <span>post</span>
    //     </label>
    //   ];
    // case 3: {
    //   const navigateToPost = () =>
    //     history.push(
    //       `/member/${notification.member?.id}/posts?postId=${notification.like?.post?.id}`
    //     );
    //   return [
    //     notification.like?.post?.title,
    //     notification.like?.post?.image,
    //     navigateToPost,
    //     <label onClick={navigateToPost}>
    //       Your <span>{getLimitedTitle(notification.like?.post?.title)}</span> post has been liked
    //       by&nbsp;
    //       <span>{notification.like?.member?.first_name}</span>{' '}
    //       <span>{notification.like?.member?.last_name}</span>
    //     </label>
    //   ];
    // }
    // case 4:
    //   const navigateToFollower = () => history.push(`/member/${notification.follower?.id}/posts`);
    //   return [
    //     notification.follower?.first_name,
    //     notification.follower?.profile_image,
    //     navigateToFollower,
    //     <label onClick={navigateToFollower}>
    //       You were followed by{' '}
    //       <span>
    //         {notification.follower?.first_name} {notification.follower?.last_name}
    //       </span>
    //     </label>
    //   ];
    // case 5: {
    //   const navigateToList = () =>
    //     history.push(`/member/${notification.list?.member?.id}/lists/${notification.list?.id}`);
    //   return [
    //     notification.list?.title,
    //     notification.list?.image,
    //     navigateToList,
    //     <label onClick={navigateToList}>
    //       <span>
    //         {notification.list?.member?.first_name} {notification.list?.member?.last_name}{' '}
    //       </span>
    //       has shared the <span>{getLimitedTitle(notification.list?.title)}</span> list with you
    //     </label>
    //   ];
    // }
    // case 6: {
    //   const shoppingListItemId = notification.shopping_list_item_id;
    //   const navigateToListItem = () =>
    //     history.push(
    //       `/member/${notification.member?.id}/lists/${notification.list?.id}?listItemId=${shoppingListItemId}&shortlist=true`
    //     );
    //   return [
    //     notification.post?.title,
    //     notification.post?.image,
    //     navigateToListItem,
    //     <label onClick={navigateToListItem}>
    //       <span>{notification.member?.first_name}</span>{' '}
    //       <span>{notification.member?.last_name} </span>
    //       has shortlisted <span>{getLimitedTitle(notification?.post?.title)}</span> post to your{' '}
    //       <span>{getLimitedTitle(notification.list_item?.title)} </span> list item
    //     </label>
    //   ];
    // }
    // case 7: {
    //   return getCommentNotification(notification, history);
    // }
    // case 8: {
    //   const navigateToComment = () =>
    //     history.push(
    //       `/member/${notification.comment?.post?.member?.id}/posts?postId=${notification.comment?.post?.id}` +
    //         `&commentId=${notification.comment?.reply?.id}`
    //     );
    //   return [
    //     notification.comment?.post?.title,
    //     notification.comment?.post?.image,
    //     navigateToComment,
    //     <label onClick={navigateToComment}>
    //       <span>
    //         {notification.comment?.member?.first_name} {notification.comment?.member?.last_name}{' '}
    //       </span>
    //       has replied to your comment
    //     </label>
    //   ];
    // }
    // case 9: {
    //   const navigateToPost = () =>
    //     history.push(
    //       `/member/${notification.post?.member?.id}/posts?postId=${notification.post?.id}`
    //     );
    //   return [
    //     notification.post?.title,
    //     notification.post?.image,
    //     navigateToPost,
    //     <label onClick={navigateToPost}>
    //       <span>
    //         {notification.post?.member?.first_name} {notification.post?.member?.last_name}
    //       </span>{' '}
    //       has created a new post&nbsp;
    //       <span>{notification.post?.title}</span>
    //     </label>
    //   ];
    // }
    case 10:
      const navigateToComment = () =>
        history.push(
          '/admin/moderation'
        );
      return [
        notification.post?.title,
        notification.post?.image,
        navigateToComment,
        <label onClick={navigateToComment}>
          <span>{getLimitedTitle(notification?.post?.title)}</span> post was reported{' '}
        </label>
      ];
    // case 11: {
    //   const navigateToConnection = () =>
    //     history.push(`/member/${notification.connection?.id}/posts`);
    //   return [
    //     notification.connection?.first_name,
    //     notification.connection?.profile_image,
    //     navigateToConnection,
    //     <label onClick={navigateToConnection}>
    //       <span>{notification.connection?.first_name}</span>{' '}
    //       <span>{notification.connection?.last_name} </span>
    //       has requested to connect with you
    //     </label>
    //   ];
    // }
    // case 12: {
    //   const navigateToConnection = () =>
    //     history.push(`/member/${notification.connection?.id}/posts`);
    //   return [
    //     notification.connection?.first_name,
    //     notification.connection?.profile_image,
    //     navigateToConnection,
    //     <label onClick={navigateToConnection}>
    //       <span>{notification.connection?.first_name}</span>{' '}
    //       <span>{notification.connection?.last_name} </span>
    //       has accepted your connection request
    //     </label>
    //   ];
    // }
    // case 13: {
    //   const navigateToFollower = () => history.push(`/member/${notification.follower?.id}/posts`);
    //   return [
    //     notification.follower?.first_name,
    //     notification.follower?.profile_image,
    //     navigateToFollower,
    //     <label onClick={navigateToFollower}>
    //       You have been followed by{' '}
    //       <span>
    //         {notification.follower?.first_name} {notification.follower?.last_name}
    //       </span>
    //     </label>
    //   ];
    // }
    // case 14: {
    //   const navigateToUnfollower = () => history.push(`/member/${notification.follower?.id}/posts`);
    //   return [
    //     notification.follower?.first_name,
    //     notification.follower?.profile_image,
    //     navigateToUnfollower,
    //     <label onClick={navigateToUnfollower}>
    //       <span>
    //         {notification.follower?.first_name} {notification.follower?.last_name}
    //       </span>{' '}
    //       has unfollowed you.
    //     </label>
    //   ];
    // }
    // case 17: {
    //   const shoppingListItemId = notification.shopping_list_item_id;
    //   const navigateToList = () =>
    //     history.push(
    //       `/member/${notification.member?.id}/lists/${notification.list?.id}?listItemId=${shoppingListItemId}`
    //     );
    //   return [
    //     notification.list_item?.title,
    //     notification.post?.image,
    //     navigateToList,
    //     <label onClick={navigateToList}>
    //       Your <span>{notification.list_item?.title}</span> list item has found a new related post!
    //     </label>
    //   ];
    // }
    // case 25: {
    //   const navigateToPost = () =>
    //     history.push(
    //       `/member/${notification.post?.member?.id}/posts?postId=${notification.post?.id}`
    //     );
    //   return [
    //     notification.post?.title,
    //     notification.post?.image,
    //     navigateToPost,
    //     <label onClick={navigateToPost}>
    //       The <span>{notification.post?.title}</span> post was edited in your{' '}
    //       <span>{notification.list_item?.title}</span> list item!
    //     </label>
    //   ];
    // }
    // case 26: {
    //   const navigateToComment = () =>
    //     history.push(
    //       `/member/${notification.comment?.post?.member?.id}/posts?postId=${notification.comment?.post?.id}` +
    //         `&commentId=${notification.comment?.reply?.id}`
    //     );
    //   return [
    //     notification.post?.title,
    //     notification.post?.image,
    //     navigateToComment,
    //     <label onClick={navigateToComment}>
    //       The <span>{notification.post?.title}</span> post has a new comment in your{' '}
    //       <span>{notification.list_item?.title}</span> list item!
    //     </label>
    //   ];
    // }
    // case 27: {
    //   const navigateToComment = () =>
    //     history.push(
    //       `/member/${notification.comment?.post?.member?.id}/posts?postId=${notification.comment?.post?.id}` +
    //         `&commentId=${notification.comment?.reply?.id}`
    //     );
    //   return [
    //     notification.post?.title,
    //     notification.post?.image,
    //     navigateToComment,
    //     <label onClick={navigateToComment}>
    //       The <span>{notification.post?.title}</span> post has a reply to a comment in your{' '}
    //       <span>{notification.list_item?.title}</span> list item!
    //     </label>
    //   ];
    // }
    case 28: {
      const navigateToComment = () =>
        history.push(
          '/admin/moderation'
        );
      return [
        notification.post?.title,
        notification.post?.image,
        navigateToComment,
        <label onClick={navigateToComment}>
          <span>{getLimitedTitle(notification?.post?.title)}</span> post was reported as duplicate post{' '}
        </label>
      ];
    }
    case 29: {
      const navigateToComment = () =>
        history.push(
          '/admin/moderation'
        );
      return [
        notification.comment?.member?.title,
        notification.comment?.member?.profile_image,
        navigateToComment,
        <label onClick={navigateToComment}> 
          A comment by <span>{notification.comment?.member?.first_name}</span>{' '}
          <span>{notification.comment?.member?.last_name} </span> has been reported{' '}
        </label>
      ];
    }
    default:
      // eslint-disable-next-line no-console
      console.error(`Notification is not recognized: ${notification.type}`);
  }
};

const renderNotification = (notification, markAsRead, history, onOpenNotification) => {
  if (mapLabel(notification, history) !== undefined) {
  const [title, image, onClick, label] = mapLabel(notification, history);

  return (
    <div
      className="notifications__notification"
      onClick={() => {
        onOpenNotification(notification);
        onClick();
      }}
    >
      <div>
        <Avatar medium title={title} noTitle imageSource={image} />
        <div className="notifications__details">
          <div className="notifications__detail">{label}</div>
          {/*{ renderMutualConnections(notification.type, noOfMutual) }*/}
          <small className="notifications__time">{howLongAgo(notification.created_at)}</small>
        </div>
        {!notification.read && (
          <div className="notifications__notification--unread" onClick={markAsRead} />
        )}
      </div>
      
    </div>
  );
  };
};

const renderAll = (
  notifications,
  emptyMessage,
  markAsRead,
  onOpenNotification,
  onConfirm,
  onDelete,
  onMarkAsProcessed,
  connections,
  history
) => {
  if (isEmpty(notifications)) {
    return <NotFound message={emptyMessage} />;
  }

  return notifications.map((notification) => (
    <Fragment key={notification.id}>
      {renderNotification(
        notification,
        () => markAsRead(notification),
        history,
        onOpenNotification
      )}
      {renderConnectionActions(
        notification,
        onConfirm,
        onDelete,
        markAsRead,
        onMarkAsProcessed,
        connections
      )}
    </Fragment>
  ));
};

const getUnreadNotifications = (notifications) =>
  notifications.filter((notification) => !notification.read);

const renderUnread = (
  notifications,
  markAsRead,
  onOpenNotification,
  onConfirm,
  onDelete,
  onMarkAsProcessed,
  connections,
  history
) => {
  const unreadNotifications = getUnreadNotifications(notifications);

  return renderAll(
    unreadNotifications,
    'No New notifications',
    markAsRead,
    onOpenNotification,
    onConfirm,
    onDelete,
    onMarkAsProcessed,
    connections,
    history
  );
};

const Notifications = ({
  notifications,
  notificationLoadMore,
  loading,
  count,
  markAllAsRead,
  markAsRead,
  onConfirm,
  onDelete,
  onMarkAsProcessed,
  connections,
  closeNotifications,
  unreadCount
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [showAll, setShowAll] = useState(true);
  const [showUnread, setShowUnread] = useState(false);

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: () => notifications.length === count,
    onLoadMore: () => notificationLoadMore()
  });

  const history = useHistory();

  const onOpenNotification = (notification) => {
    markAsRead(notification);
    closeNotifications();
  };

  const onTabChange = (index) => {
    switch (index) {
      case 0:
        setShowAll(true);
        setShowUnread(false);
        break;
      case 1:
        setShowUnread(true);
        setShowAll(false);
        break;
      default:
        throw new Error('The tab is unknown');
    }

    setActiveIndex(index);
  };

  return (
    <div className="notifications">
      <div className={getClassNames('notifications__tabs', { centered: unreadCount === 0 })}>
        <Tabs
          items={getItems()}
          activeItem={activeIndex}
          onClickItem={(index) => onTabChange(index)}
        />
        {unreadCount > 0 && (
          <div className="notifications__mark-as-read-action" onClick={markAllAsRead}>
            Mark all as Read
          </div>
        )}
      </div>
      <div className="notifications__list">
        {showAll &&
          renderAll(
            notifications,
            'There are no notifications',
            markAsRead,
            onOpenNotification,
            onConfirm,
            onDelete,
            onMarkAsProcessed,
            connections,
            history
          )}
        {showUnread &&
          renderUnread(
            notifications,
            markAsRead,
            onOpenNotification,
            onConfirm,
            onDelete,
            onMarkAsProcessed,
            connections,
            history
          )}
        {!loading && notifications.length !== count && (
          <div ref={sentryRef}>
            <Loader orange />
          </div>
        )}
      </div>
    </div>
  );
};

Notifications.defaultProps = {
  notifications: []
};

Notifications.propTypes = {
  notifications: arrayOf(shape({}))
};

export default Notifications;
