import React from 'react';
import { func, instanceOf, oneOfType, shape, string } from 'prop-types';

import './under-construction.scss';

const renderLogo = (Logo) => (
  <div className="under-construction__logo">
    <Logo />
  </div>
);

const UnderConstruction = ({ title, subtitle, logo }) => (
  <div className="under-construction">
    { logo && renderLogo(logo) }
    <div className="under-construction__header">
      <h1 className="under-construction__title">{ title }</h1>
      <p className="under-construction__subtitle">{ subtitle }</p>
    </div>
  </div>
);

UnderConstruction.defaultProps = {
  subtitle: '',
  logo: undefined
};

UnderConstruction.propTypes = {
  title: string.isRequired,
  subtitle: string,
  logo: oneOfType([
    func,
    shape({
      current: instanceOf(Element)
    })
  ])
};

export default UnderConstruction;
