import Bowser from 'bowser';

/** A placeholder function that does nothing. */
export const noOp = () => {
};

/**
 * Joins a `baseClassName` together with an array of associated class `modifiers`.
 *
 * @param {string} baseClassName - the class name of the component,
 *  also used for prefixing modifiers
 * @param {object} modifiers - object with keys being the modifier name,
 *  their values being a boolean representing whether the modifier is active
 *
 * @example <caption>Get class names for element 'button' with modifier 'hollow'.</caption>
 * getClassNames('button', { hollow: true })
 * // returns 'button button--hollow'
 *
 */
export function getClassNames(baseClassName, modifiers = {}) {
  const classes = [baseClassName];

  Object.entries(modifiers).forEach(([name, active]) => {
    if (!active) return;

    classes.push(`${ baseClassName }--${ name }`);
  });

  return classes.join(' ');
}

export const toBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);

  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
});

/** Tool to check for undefined */
export const isUndefined = (value) => typeof value === 'undefined';

/** Tool to check for whether an array or string is undefined or empty */
export const isEmpty = (value) => !value || value.length === 0;

/** Used by event handlers to prevent default event behaviour. */
export const preventDefault = (e) => e.preventDefault();

/** An asynchronous delay function */
export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

/**
 * Generate a random ID, to avoid using `index` as `key`.
 * Converts a random seed to base 36 (numbers & letters),
 * then takes the first 9 chars after decimal.
 */
export const generateId = () => `_${ Math.random().toString(36).substr(2, 9) }`;

export const SNACK_WARNING = 'snack--warning'; // validation messages, can be recovered.
export const SNACK_SUCCESS = 'snack--success'; // successful action
export const SNACK_CRITICAL = 'snack--critical'; // critical error, cannot be recovered.
export const SNACK_INFO = 'snack--info'; // information about something

export const clone = (arg) => JSON.parse(JSON.stringify(arg));

const getBrowserOptions = () => Bowser.parse(window.navigator.userAgent);

export const isMobile = () => getBrowserOptions().platform.type !== 'desktop';

export const isSmallScreen = () => document.body.getBoundingClientRect().width >= 567;

export const isMedScreen = () => document.body.getBoundingClientRect().width >= 768;

export const isLargeScreen = () => document.body.getBoundingClientRect().width >= 1024;

export const getTitleInitials = (title) => {
  if (isUndefined(title) || isEmpty(title)) {
    return '';
  }

  const words = title.split(' ');

  const size = words.length >= 3 ? 3 : words.length;

  let initials = '';
  for (let i = 0; i < size; i++) {
    initials += words[i].substring(0, 1);
  }

  return initials.toUpperCase();
};
