import React, { useState } from 'react';
import { arrayOf, bool, func, number, shape } from 'prop-types';

import { isLargeScreen, isMedScreen, noOp } from '../../tools/helpers';

import Popper from '../popper/Popper';
import Avatar from '../avatar/Avatar';
import Activity from '../members/activity/Activity';
import Notifications from '../admin/notifications/Notifications';
import ExpanderSheet from '../expander-sheet/ExpanderSheet';
import { ReactComponent as AccountIcon } from './../../icons/account-icon.svg';
import { ReactComponent as ProfileIcon } from './../../icons/profile-icon.svg';
import { ReactComponent as SettingsIcon } from './../../icons/settings-small-icon.svg';
import { ReactComponent as NotificationIcon } from './../../icons/notification-icon.svg';
import { ReactComponent as DropdownIcon } from './../../icons/profile-dropdown-icon.svg';
import { ReactComponent as LogoutIcon } from './../../icons/logout-icon.svg';

import './profile.scss';

const getProfileMenuItems = (
  profile,
  onNavigateDetails,
  onNavigateAccount,
  onNavigateNotificationsSettings,
  setOpenActivity,
  onNavigateReputation,
  onLogOut
) => [
  {
    Icon: (
      <Avatar
        imageSource={profile?.profile_image}
        noTitle
        title={`${profile?.first_name} ${profile?.last_name}`}
      />
    ),
    label: `${profile?.first_name} ${profile?.last_name}`,
    subLabel: profile?.email,
    onClick: noOp,
    hover: false
  },
  {
    Icon: <ProfileIcon />,
    label: 'Personal Details',
    onClick: onNavigateDetails
  },
  {
    Icon: <AccountIcon />,
    label: 'Account Details',
    onClick: onNavigateAccount
  },
  // TODO: add in later phase
  // {
  //   Icon: <ReputationIcon />,
  //   label: 'My Reputation',
  //   onClick: onNavigateReputation
  // },
  // TODO: adding as a premium feature later
  // {
  //   Icon: <ActivityIcon />,
  //   label: 'Activity History',
  //   onClick: () => setOpenActivity(true)
  // },
  {
    Icon: <SettingsIcon />,
    label: 'Notification Settings',
    onClick: onNavigateNotificationsSettings
  },
  {
    Icon: <LogoutIcon />,
    label: 'Log Out',
    onClick: onLogOut
  }
];

const getActivity = (activities) => <Activity title="Activity History" activities={activities} />;

const renderUnreadNotificationCount = (unreadCount) => {
  if (unreadCount > 0) {
    return <div className="profile__notification--unread-count">{unreadCount}</div>;
  }
};

const getNotifications = (
  notifications,
  markAllAsRead,
  markAsRead,
  onConfirm,
  onDelete,
  onMarkAsProcessed,
  connections,
  closeNotifications,
  notificationLoadMore,
  loading,
  count,
  unreadCount
) => (
  <Notifications
    connections={connections}
    notifications={notifications}
    loading={loading}
    count={count}
    unreadCount={unreadCount}
    notificationLoadMore={notificationLoadMore}
    markAllAsRead={markAllAsRead}
    markAsRead={markAsRead}
    onConfirm={onConfirm}
    onDelete={onDelete}
    onMarkAsProcessed={onMarkAsProcessed}
    closeNotifications={closeNotifications}
  />
);

const renderNotifications = (
  notifications,
  markAllAsRead,
  markAsRead,
  onConfirm,
  onDelete,
  onMarkAsProcessed,
  connections,
  closeNotifications,
  notificationLoadMore,
  loading,
  count,
  unreadCount,
  openNotifications,
  notificationsOpen,
  setOpenExpander
) => {
  if (!isLargeScreen() && !isMedScreen()) {
    return (
      <div className="profile__notification">
        <NotificationIcon onClick={() => setOpenExpander(true)} />
        {renderUnreadNotificationCount(unreadCount)}
      </div>
    );
  }
  return (
    <div className="profile__notification">
      <Popper
        menu={getNotifications(
          notifications,
          markAllAsRead,
          markAsRead,
          onConfirm,
          onDelete,
          onMarkAsProcessed,
          connections,
          closeNotifications,
          notificationLoadMore,
          loading,
          count,
          unreadCount
        )}
        onOpenPopper={openNotifications}
        open={notificationsOpen}
      >
        {renderUnreadNotificationCount(unreadCount)}
        <NotificationIcon />
      </Popper>
    </div>
  );
};

const renderNotificationsExpander = (
  connections,
  notifications,
  loading,
  count,
  unreadCount,
  notificationLoadMore,
  markAllAsRead,
  markAsRead,
  onConfirm,
  onDelete,
  onMarkAsProcessed,
  setOpenExpander
) => {
  return (
    <ExpanderSheet
      heightRatio={1}
      onDismiss={() => setOpenExpander(false)}
      scrollLocking
      expandOnContentDrag={false}
    >
      <Notifications
        connections={connections}
        notifications={notifications}
        loading={loading}
        count={count}
        unreadCount={unreadCount}
        notificationLoadMore={notificationLoadMore}
        markAllAsRead={markAllAsRead}
        markAsRead={markAsRead}
        onConfirm={onConfirm}
        onDelete={onDelete}
        onMarkAsProcessed={onMarkAsProcessed}
        closeNotifications={() => setOpenExpander(false)}
      />
    </ExpanderSheet>
  );
};

const Profile = ({
  profile,
  activities,
  notifications,
  notificationLoadMore,
  loading,
  count,
  unreadCount,
  connections,
  markAllAsRead,
  markAsRead,
  onNavigateDetails,
  onNavigateAccount,
  onNavigateNotificationsSettings,
  activityOpen,
  setOpenActivity,
  notificationsOpen,
  setNotificationsOpen,
  onConfirm,
  onDelete,
  onMarkAsProcessed,
  onNavigateReputation,
  onLogOut
}) => {
  const menuItems = getProfileMenuItems(
    profile,
    onNavigateDetails,
    onNavigateAccount,
    onNavigateNotificationsSettings,
    setOpenActivity,
    onNavigateReputation,
    onLogOut
  );

  const [openExpander, setOpenExpander] = useState(false);

  const openNotifications = () => {
    setNotificationsOpen(true);
  };

  const closeNotifications = () => {
    setNotificationsOpen(false);
  };

  return (
    <div className="profile">
      <div className="profile__notification">
        {renderNotifications(
          notifications,
          markAllAsRead,
          markAsRead,
          onConfirm,
          onDelete,
          onMarkAsProcessed,
          connections,
          closeNotifications,
          notificationLoadMore,
          loading,
          count,
          unreadCount,
          openNotifications,
          notificationsOpen,
          setOpenExpander
        )}
      </div>
      <div className="profile__dropdown">
        <Popper name="activity-popper" menu={getActivity(activities)} open={activityOpen}>
          <Popper name="other-popper" showAbout menuItems={menuItems}>
            <div className="profile__picture">
              <Avatar
                imageSource={profile?.profile_image}
                title={`${profile?.first_name} ${profile?.last_name}`}
              />
            </div>
            <DropdownIcon />
          </Popper>
        </Popper>
      </div>
      {openExpander &&
        renderNotificationsExpander(
          connections,
          notifications,
          loading,
          count,
          unreadCount,
          notificationLoadMore,
          markAllAsRead,
          markAsRead,
          onConfirm,
          onDelete,
          onMarkAsProcessed,
          setOpenExpander
        )}
    </div>
  );
};

Profile.defaultProps = {
  onNavigateDetails: noOp,
  onNavigateAccount: noOp,
  activities: [],
  notifications: [],
  connections: [],
  markAsRead: noOp,
  markAllAsRead: noOp,
  onNavigateNotificationsSettings: noOp,
  onNavigateReputation: noOp,
  notificationsOpen: false,
  setNotificationsOpen: noOp
};

Profile.propTypes = {
  onNavigateDetails: func,
  onNavigateAccount: func,
  activities: arrayOf(
    shape({
      id: number,
      action: number
    })
  ),
  notifications: arrayOf(shape({})),
  connections: arrayOf(shape({})),
  markAsRead: func,
  markAllAsRead: func,
  onNavigateNotificationsSettings: func,
  onNavigateReputation: func,
  notificationsOpen: bool,
  setNotificationsOpen: func
};

export default Profile;
