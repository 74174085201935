import React from 'react';
import { func, string } from 'prop-types';

import { isEmpty, noOp } from '../../tools/helpers';

import Avatar from '../avatar/Avatar';

import './curators-card.scss';

const renderCurators = (curators) => {
  if (curators.length > 4) {
    return <>
      { curators.slice(0, 4).map((curator, index) =>
        <Avatar imageSource={ curator.profile_image }
                index={ curators.length - index }
                small
                squished />
      ) }
      <p>+ { curators.length - 4 } more</p>
    </>;
  }

  return curators.map((curator, index) =>
    <Avatar
      key={ curator.id }
      imageSource={ curator.profile_image }
      index={ curators.length - index }
      small
      squished
    />
  );
};

const CuratorsCard = ({ info, label, navigate, curators }) => (
  <div className="curators-card">
    <div className="curators-card__header">
      <h4>{ label }</h4>
      <p onClick={ navigate }>View all</p>
    </div>
    <div className="curators-card__info">
      <p>{ info }</p>
    </div>
    { !isEmpty(curators) && <div className="curators-card__avatars">
      { renderCurators(curators) }
    </div> }
  </div>
);

CuratorsCard.defaultProps = {
  info: '',
  label: '',
  navigateCurators: noOp
};

CuratorsCard.propTypes = {
  info: string,
  label: string,
  navigate: func
};

export default CuratorsCard;
