import { PROFILE_ID, SET_COMPLETE_ERRORS, SET_LOGIN_ERRORS, SET_PASSWORD_ERRORS } from '../actions/auth.actions';

export const initialState = {
  loginErrors: undefined,
  completeErrors: undefined,
  passwordErrors: undefined,
  profileId: undefined
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPLETE_ERRORS:
      const { completeErrors } = action;
      return {
        ...state,
        completeErrors
      };
    case SET_LOGIN_ERRORS:
      const { loginErrors } = action;
      return {
        ...state,
        loginErrors
      };
    case PROFILE_ID:
      const { profileId } = action;
      return {
        ...state,
        profileId
      };
    case SET_PASSWORD_ERRORS:
      const { passwordErrors } = action;
      return {
        ...state,
        passwordErrors
      };
    default:
      return state;
  }
};
