export const API_HOST = process.env.REACT_APP_API_ENDPOINT; // use api endpoint provided in .env
export const SERVER_HOST = process.env.REACT_APP_HOST; // use host provided in .env

const POST = 'POST';
export const getHttpPostOptions = (data, headers = null) => ({
  method: POST,
  data,
  headers
});

const PUT = 'PUT';
export const getHttpPutOptions = (data, headers = null) => ({
  method: PUT,
  data,
  headers
});

const GET = 'GET';
export const getHttpGetOptions = (headers = null, params = null) => ({
  method: GET,
  headers,
  params
});

const DELETE = 'DELETE';
export const getHttpDeleteOptions = (headers = null) => ({
  method: DELETE,
  headers
});
