import { func } from 'prop-types';
import React from 'react';

import { REPORTED_ITEM_PROP_TYPE } from '../../../../tools/prop.types';
import { noOp } from '../../../../tools/helpers';

import Avatar from '../../../avatar/Avatar';
import Button from '../../../button/Button';

import './comment-moderation-details.scss';

const renderCurator = ({ member, comment }, dateReported, reasonForReport) => {
  const date = new Date(dateReported);

  return (
    <div className="curator">
      <h5 className="curator__header">Comment Curator</h5>
      <div className="curator__user">
        <Avatar imageSource={ member.profile_image } />
        <span>{ `${ member.first_name } ${ member.last_name }` }</span>
      </div>
      <div className="curator__comment">
        <label>Comment: </label>
        <span>{ comment }</span>
      </div>
      <div className="curator__date">
        <label>Date Reported: </label>
        <span>{ date.toLocaleDateString() }</span>
      </div>
      <div className="curator__reason">
        <label>Reason for Report: </label>
        <span className="primary">{ reasonForReport }</span>
      </div>
    </div>
  );
};

const renderReporter = ({ first_name, last_name }, comment) => (
  <div className="reporter">
    <h5 className="reporter__header">Reporter</h5>
    <div className="reporter__user">
      <Avatar />
      <h5>{ `${ first_name } ${ last_name }` }</h5>
    </div>
    <div className="reporter__comment">
      <label>Additional Comment</label>
      <p>{ comment ?? 'N/A' }</p>
    </div>
  </div>
);

const ModerationInfo = ({ item: { comment, created_at, reason, reporter, comments } }) => (
  <div className="moderation-info">
    { renderCurator(comment, created_at, reason) }
    { renderReporter(reporter, comments) }
  </div>
);

const CommentModerationDetails = ({ item, onAcceptOrReject }) => (
  <div className="comment-moderation">
    <div className="comment-moderation__details">
      <ModerationInfo item={ item } />
    </div>
    <div className="comment-moderation__actions">
      <Button label="Accept" onClick={ () => onAcceptOrReject('accept') } />
      <Button blank label="Reject" onClick={ () => onAcceptOrReject('reject') } />
    </div>
  </div>
);

CommentModerationDetails.defaultProps = {
  item: {},
  onAcceptOrReject: noOp
};

CommentModerationDetails.propTypes = {
  item: REPORTED_ITEM_PROP_TYPE,
  onAcceptOrReject: func
};

export default CommentModerationDetails;
