import React from 'react';

import Button from '../../button/Button';

import './activate-member.scss';

const ActivateMember = ({ onClose, onActivate }) => (
  <div className="activate-member">
    <div className="activate-member__header">
      <p>Are you sure you want to deactivate this user? You can deactivate their account at any time</p>
    </div>
    <div className="activate-member__action">
      <Button label="Yes, activate user" onClick={ onActivate } />
      <Button blank label="No, keep user deactivated" onClick={ onClose } />
    </div>
  </div>
);

export default ActivateMember;
