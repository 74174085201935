import React from 'react';

import { MODERATED_MEMBER_PROP_TYPES } from '../../../tools/prop.types';
import withAuthentication from '../../auth/withAuthentication';

import ModeratedMembers from '../../../components/members/moderation/moderated/ModeratedMembers';
import PageLayout from '../../../components/layout/page/PageLayout';

const ModeratedMembersContainer = ({ items }) => (
  <PageLayout title="Moderated Users">
    <ModeratedMembers items={ items } />
  </PageLayout>
);

ModeratedMembersContainer.defaultProps = {
  items: [{
    id: 1,
    userName: 'Sam Smith',
    actionTaken: 'Temporary Ban',
    dateOfAction: new Date(2020, 1, 1),
    previousOffences: 2,
    status: 'Status',
    reasonForReport: 'Duplicate',
    actions: 'actions'
  }, {
    id: 2,
    userName: 'A really real name',
    actionTaken: 'Permanent Ban',
    dateOfAction: new Date(2020, 6, 25),
    previousOffences: 0,
    status: 'Status',
    reasonForReport: 'Racist',
    actions: 'actions'
  }, {
    id: 3,
    userName: 'Da user name',
    actionTaken: 'Warning',
    dateOfAction: new Date(2021, 9, 1),
    previousOffences: 5,
    status: 'Status',
    reasonForReport: 'Inappropriate',
    actions: 'actions'
  }]
};

ModeratedMembersContainer.propTypes = {
  items: MODERATED_MEMBER_PROP_TYPES
};

export default withAuthentication(ModeratedMembersContainer);
