import React, { createRef, useState } from 'react';
import { arrayOf, func, shape, string } from 'prop-types';

import { delay, noOp } from '../../../tools/helpers';

import Form from '../../form/Form';
import FormRow from '../../form/formRow/FormRow';
import Input from '../../input/Input';
import FormAction from '../../form/formAction/FormAction';

import './reset-password.scss';

const renderForm = (currentPasswordRef, newPasswordRef, confirmPasswordRef, onSubmit, loading, errors) => (
  <Form>
    <FormRow>
      <Input
        placeholder="Current Password"
        label="Current Password"
        inputRef={ currentPasswordRef }
        type="password"
        error={ errors?.current_password }
      />
    </FormRow>
    <FormRow>
      <Input
        placeholder="New Password"
        label="New Password"
        inputRef={ newPasswordRef }
        type="password"
        error={ errors?.password }
      />
    </FormRow>
    <FormRow>
      <Input
        placeholder="Confirm Password"
        label="Confirm Password"
        inputRef={ confirmPasswordRef }
        type="password"
        error={ errors?.password_confirm }
      />
    </FormRow>
    <div className="reset-password__form--action">
      <FormAction loading={ loading } label="Reset Password" onClick={ onSubmit } />
    </div>
  </Form>
);

const ResetPassword = ({ errors, onChangePassword }) => {
  const currentPasswordRef = createRef();
  const newPasswordRef = createRef();
  const confirmPasswordRef = createRef();

  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);

    onChangePassword({
      current_password: currentPasswordRef.current.value,
      password: newPasswordRef.current.value,
      password_confirm: confirmPasswordRef.current.value
    });

    await delay(1000);
    setLoading(false);
  };

  return (
    <div className="reset-password">
      <div className="reset-password__form">
        { renderForm(currentPasswordRef, newPasswordRef, confirmPasswordRef, onSubmit, loading, errors) }
      </div>
    </div>
  );
};

ResetPassword.defaultProps = {
  onChangePassword: noOp
};

ResetPassword.propTypes = {
  onChangePassword: func,
  errors: shape({
    current_password: arrayOf(string),
    password: arrayOf(string),
    password_confirm: arrayOf(string)
  })
};

export default ResetPassword;
