import React from 'react';
import { bool, func, number, string } from 'prop-types';

import { getClassNames, noOp } from '../../tools/helpers';

import Tooltip from '../tooltip/Tooltip';
import ImageInitialsPlaceholder from '../image/ImageInitialsPlaceholder';

import './avatar.scss';

class Avatar extends React.Component {
  renderImage = () => {
    const { imageSource, title, onOpenProfile, bordered, withToolTip } = this.props;

    if (!!withToolTip) {
      return imageSource ? (
        <Tooltip content={title} parentId={`#${withToolTip}`}>
          <img
            className={getClassNames('avatar__image', { bordered })}
            src={imageSource}
            id={withToolTip}
            alt="client logo"
            onClick={onOpenProfile}
          />
        </Tooltip>
      ) : (
        <Tooltip content={title} parentId={`#${withToolTip}`}>
          <div className="avatar__image" id={withToolTip}>
            <ImageInitialsPlaceholder title={title} />
          </div>
        </Tooltip>
      );
    }

    return imageSource ? (
      <img
        className={getClassNames('avatar__image', { bordered })}
        src={imageSource}
        alt="client logo"
        onClick={onOpenProfile}
      />
    ) : (
      <div className="avatar__image">
        <ImageInitialsPlaceholder title={title} />
      </div>
    );
  };

  renderName = (title, subtitle) => (
    <div className="avatar__name">
      <p>{title}</p>
      {subtitle && <p className="avatar__subtitle">{subtitle}</p>}
    </div>
  );

  renderLabel = (label) => (
    <div className="avatar__label">
      <label>{label}</label>
    </div>
  );

  render() {
    const { noTitle, large, medium, small, title, subtitle, squished, onClick, label, index } =
      this.props;

    return (
      <div
        className={getClassNames('avatar', { large, medium, small, squished })}
        onClick={onClick}
        style={{ zIndex: index }}
      >
        {this.renderImage()}
        {!noTitle && title && this.renderName(title, subtitle)}
        {label && this.renderLabel(label)}
      </div>
    );
  }
}

Avatar.defaultProps = {
  imageSource: undefined,
  onOpenProfile: noOp,
  large: false,
  small: false,
  medium: false,
  squished: false,
  noTitle: false,
  title: undefined,
  label: undefined,
  subtitle: undefined,
  hasIcon: true,
  onClick: noOp,
  bordered: false,
  index: 0
};

Avatar.propTypes = {
  imageSource: string,
  onOpenProfile: func,
  large: bool,
  medium: bool,
  squished: bool,
  noTitle: bool,
  title: string,
  label: string,
  subtitle: string,
  hasIcon: bool,
  small: bool,
  bordered: bool,
  index: number,
  withToolTip: string
};

export default Avatar;
