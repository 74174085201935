import React, { createRef, useEffect, useState } from 'react';
import { arrayOf, func, shape, string } from 'prop-types';

import { delay, noOp } from '../../../tools/helpers';

import { ReactComponent as EditIcon } from '../../../icons/edit-icon.svg';
import { ReactComponent as LockIcon } from '../../../icons/small-lock.svg';
import Form from '../../form/Form';
import FormRow from '../../form/formRow/FormRow';
import Input from '../../input/Input';
import FormAction from '../../form/formAction/FormAction';

import './account-details.scss';

const mapStatus = (active) => active ? 'Active' : 'Not yet verified';

const renderForm = (statusRef, emailRef, passwordRef, onOpenPasswordModal, onSubmit, errors, loading) => (
  <Form>
    <FormRow half>
      <Input
        placeholder="Status"
        readOnly
        disabled
        label="Account Status"
        inputRef={ statusRef }
        icon={ LockIcon }
      />
    </FormRow>
    <FormRow>
      <Input
        label="Email Address"
        placeholder="Email Address"
        type="email"
        inputRef={ emailRef }
        icon={ EditIcon }
      />
      <Input
        label="Password"
        placeholder="Password"
        readOnly
        inputRef={ passwordRef }
        link="reset"
        onLinkClick={ onOpenPasswordModal }
        type="password"
      />
    </FormRow>
    <FormAction loading={ loading } label="Verify new email" onClick={ onSubmit } />
  </Form>
);

const AccountDetails = ({ onOpenPasswordModal, profile, onSave, errors }) => {
  const statusRef = createRef();
  const emailRef = createRef();
  const passwordRef = createRef();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!!emailRef.current && profile && !loading) {
      emailRef.current.value = profile.email;
      passwordRef.current.value = '123456789';
      statusRef.current.value = mapStatus(profile.is_active);
    }

    return () => {
    };
  });

  const onSubmit = async () => {
    setLoading(true);

    onSave({
      email: emailRef.current.value
    });

    await delay(1000);
    setLoading(false);
  };

  return (
    <div className="account-details">
      <div className="account-details__personal-details">
        <h6 className="account-details__title">Account Details</h6>
        { renderForm(statusRef, emailRef, passwordRef, onOpenPasswordModal, onSubmit, errors, loading) }
      </div>
    </div>
  );
};

AccountDetails.defaultProps = {
  profile: undefined,
  onSave: noOp,
  errors: undefined
};

AccountDetails.propTypes = {
  onSave: func,
  errors: shape({
    email: arrayOf(string)
  })
};

export default AccountDetails;
