import React from 'react';

import withAuthentication from '../../auth/withAuthentication';

import PageLayout from '../../../components/layout/page/PageLayout';
import ReputationLevels from '../../../components/members/reputation/levels/ReputationLevels';

const ReputationLevelsContainer = () => (
  <PageLayout title="Reputation Levels">
    <ReputationLevels />
  </PageLayout>
);

export default withAuthentication(ReputationLevelsContainer);
