/**
 * @module SnackAlert Component
 * @description
 * A single message that enters in from the edge of the screen.
 * Allows triggering of an exit animation before removing the SnackAlert.
 */
import React, { useEffect, useState } from 'react';
import { bool, string } from 'prop-types';

import { getClassNames, SNACK_CRITICAL, SNACK_INFO, SNACK_SUCCESS, SNACK_WARNING } from '../../tools/helpers';

import { ReactComponent as IconSuccess } from '../../icons/success-icon.svg';
import { ReactComponent as IconWarning } from '../../icons/warning-icon.svg';
import { ReactComponent as IconError } from '../../icons/error-icon.svg';
import { ReactComponent as IconInfo } from '../../icons/info-icon.svg';

import './snack-alert.scss';

const renderIcon = (status) => {
  switch (status) {
    case SNACK_SUCCESS:
      return (
        <IconSuccess />
      );
    case SNACK_WARNING:
      return (
        <IconWarning />
      );
    case SNACK_CRITICAL:
      return (
        <IconError />
      );
    case SNACK_INFO:
    default:
      return (
        <IconInfo />
      );
  }
};

/** On mount, add an `entering` modifier, to trigger the enter animation. */
const SnackAlert = ({
  status = SNACK_SUCCESS, title, closing
}) => {
  const [entering, setEntering] = useState(true);

  // On initial load, remove `entering` to remove the enter animation. Clear timeout on cleanup
  useEffect(() => {
    const timeout = setTimeout(() => setEntering(false), 200);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className={ `${ getClassNames('snack', { entering, closing }) } ${ status }` }>
      <div className="snack__icon">
        { renderIcon(status) }
      </div>
      <div className="snack__title">
        <p>{ title }</p>
      </div>
    </div>
  );
};

SnackAlert.defaultProps = {
  status: SNACK_SUCCESS,
  closing: false
};

SnackAlert.propTypes = {
  status: string,
  title: string.isRequired,
  closing: bool
};

export default SnackAlert;
