import { all, call, put } from '@redux-saga/core/effects';
import axios from 'axios';
import { takeLatest } from 'redux-saga/effects';

import { addSystemNotice } from '../actions/system.actions';
import { getActionReportRequest, getRemoveReportRequest, getRetrieveReportedPostsRequest } from '../tools/api/moderation.endpoints';
import {
  ACTION_REPORT,
  REMOVE_REPORT,
  RETRIEVE_REPORTED_POSTS,
  retrieveReportedPosts as loadReports,
  SET_REPORTED_POSTS
} from '../actions/moderation.actions';
import { SNACK_CRITICAL, SNACK_SUCCESS } from '../tools/helpers';

export function* retrieveReportedPosts() {
  try {
    const [endpoint, requestOptions] = getRetrieveReportedPostsRequest();

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_REPORTED_POSTS, reportedPosts: data });
  } catch (error) {
    yield put(addSystemNotice('Error while retrieving posts requiring moderation', SNACK_CRITICAL));
  }
}

export function* watchForRetrieveReportedPosts() {
  yield takeLatest(RETRIEVE_REPORTED_POSTS, retrieveReportedPosts);
}

export function* removeReportRequest({ id }) {
  try {
    const [endpoint, requestOptions] = getRemoveReportRequest(id);

    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('Successfully rejected the report', SNACK_SUCCESS));

    yield put(loadReports());
  } catch (error) {
    yield put(addSystemNotice('Error while rejecting the report', SNACK_CRITICAL));
  }
}

export function* watchForRemoveReportRequest() {
  yield takeLatest(REMOVE_REPORT, removeReportRequest);
}

export function* actionReportRequest({ id, report }) {
  try {
    const [endpoint, requestOptions] = getActionReportRequest(id, report);

    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('Successfully actioned the report', SNACK_SUCCESS));

    yield put(loadReports());
  } catch (error) {
    yield put(addSystemNotice('Error while actioning the report', SNACK_CRITICAL));
  }
}

export function* watchForActionReportRequest() {
  yield takeLatest(ACTION_REPORT, actionReportRequest);
}

export default function* moderationSaga() {
  yield all([
    watchForRetrieveReportedPosts(),
    watchForRemoveReportRequest(),
    watchForActionReportRequest()
  ]);
}
