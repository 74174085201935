import React from 'react';
import { arrayOf, bool, node, oneOfType } from 'prop-types';

import { getClassNames } from '../../../tools/helpers';

import './form-row.scss';

const FormRow = ({ children, evenly, half, alignEnd }) => (
  <div className={ getClassNames('form-row', { evenly, half, 'align-end': alignEnd }) }>
    { children }
  </div>
);

FormRow.defaultProps = {
  evenly: false,
  half: false
};

FormRow.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  evenly: bool,
  half: bool
};

export default FormRow;
