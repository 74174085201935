import { arrayOf, bool, func, instanceOf, number, oneOf, oneOfType, shape, string } from 'prop-types';

import { BAN_STATUSES } from '../components/members/moderation/member-moderation.helper';

export const MEMBER_PROP_TYPE = shape({
  id: number,
  first_name: string,
  last_name: string,
  email: string,
  role: oneOfType([number, string]),
  status: string
});

export const PAGINATION_CTRL_PROP_TYPE = shape({
  pageSize: number.isRequired,
  pageIndex: number.isRequired,
  canPreviousPage: bool.isRequired,
  canNextPage: bool.isRequired,
  pageCount: number.isRequired,
  goToPage: func.isRequired,
  nextPage: func.isRequired,
  previousPage: func.isRequired
});

export const MEMBER_DETAILS_PROP_TYPE = shape({
  id: number.isRequired,
  first_name: string.isRequired,
  last_name: string.isRequired,
  contact_number: string.isRequired,
  email: string,
  home_town: string.isRequired,
  role: string.isRequired,
  profile_image: string
});

export const POST_PROP_TYPE = shape({
  id: number.isRequired,
  title: string.isRequired,
  description: string.isRequired,
  member: MEMBER_DETAILS_PROP_TYPE,
  date: string.isRequired,
  status: string.isRequired,
  location: string,
  url: string,
  price: number.isRequired,
  discount_price: number.isRequired
});

export const COMMENT_PROP_TYPE = shape({
  id: number.isRequired,
  comment: string.isRequired,
  member: MEMBER_DETAILS_PROP_TYPE,
  post_id: number.isRequired,
  member_id: number.isRequired
});

export const REPORTED_ITEM_PROP_TYPE = shape({
  id: number.isRequired,
  type: string.isRequired,
  comments: string,
  reason: string.isRequired,
  post: POST_PROP_TYPE,
  comment: COMMENT_PROP_TYPE,
  reporter: MEMBER_DETAILS_PROP_TYPE,
  created_at: string
});

export const REPORTED_POST_PROP_TYPES = arrayOf(REPORTED_ITEM_PROP_TYPE);

export const MODERATED_MEMBER_PROP_TYPE = shape({
  id: number.isRequired,
  userName: string.isRequired,
  actionTaken: oneOf(BAN_STATUSES),
  dateOfAction: instanceOf(Date),
  previousOffences: number.isRequired,
  status: string.isRequired,
  reasonForReport: string.isRequired,
  actions: string.isRequired
});

export const MODERATED_MEMBER_PROP_TYPES = arrayOf(MODERATED_MEMBER_PROP_TYPE);

export const MODERATED_MEMBER_DETAIL_PROP_TYPE = shape({
  userName: string.isRequired,
  status: oneOf(BAN_STATUSES),
  dateJoined: instanceOf(Date).isRequired,
  location: string.isRequired,
  previousOffences: arrayOf(shape({
    reasonForReport: string.isRequired,
    dateOfAction: instanceOf(Date).isRequired,
    actionTaken: oneOf(BAN_STATUSES)
  })),
  imgSrc: string.isRequired
});
