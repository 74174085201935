/**
 * @module PopperMenu Component
 * @description
 * Utilised by `Popper` to display a menu.
 */
import React from 'react';
import { arrayOf, bool, func, instanceOf, node, oneOfType, shape, string } from 'prop-types';

import { getClassNames } from '../../tools/helpers';

import './popper-menu.scss';

const renderMenuItems = (menuItems, closePopper, notRestricted) =>
  menuItems.map(({ Icon, label, onClick, selected, subLabel, hover = true }, index) => {
    const itemClassNames = getClassNames('popper-menu__item', { selected, hover, notRestricted });

    return (
      <li key={index} className={itemClassNames}>
        <div
          className="popper-menu__item-content"
          onClick={() => {
            closePopper(false);
            onClick();
          }}
        >
          {Icon}
          <div className="popper-menu__item-labels">
            <label>{label}</label>
            <small>{subLabel}</small>
          </div>
        </div>
      </li>
    );
  });

const PopperMenu = ({ menuItems, closePopper, showAbout, small, notRestricted }) => (
  <ul className={getClassNames('popper-menu', { small })}>
    {renderMenuItems(menuItems, closePopper, notRestricted)}
    {showAbout && (
      <p className="popper-menu__about">
        <a target="_blank" rel="noreferrer" href="https://smartbiii.com/#About-Section">
          About the app
        </a>
      </p>
    )}
  </ul>
);

PopperMenu.defaultProps = {
  minimal: false,
  select: false,
  showAbout: false
};

PopperMenu.propTypes = {
  menuItems: arrayOf(
    shape({
      Icon: oneOfType([
        func,
        shape({
          current: instanceOf(Element)
        })
      ]),
      label: oneOfType([string, node]).isRequired,
      subLabel: oneOfType([string, node]),
      onClick: func
    })
  ).isRequired,
  minimal: bool,
  select: bool,
  showAbout: bool
};

export default PopperMenu;
