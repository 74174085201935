import { func } from 'prop-types';
import React, { useState } from 'react';

import { MODERATED_MEMBER_DETAIL_PROP_TYPE } from '../../../../../tools/prop.types';
import { MODERATED_DETAIL_HEADERS } from '../../member-moderation.helper';
import { noOp } from '../../../../../tools/helpers';

import Avatar from '../../../../avatar/Avatar';
import Button from '../../../../button/Button';
import Card from '../../../../card/Card';
import ConfirmModerationStatus from '../confirm/ConfirmModerationStatus';
import EditModerationStatus from '../edit/EditModerationStatus';
import FormRow from '../../../../form/formRow/FormRow';
import Input from '../../../../input/Input';
import Modal from '../../../../modal/Modal';
import ModalLayout from '../../../../modal/ModalLayout';
import Table from '../../../../table/Table';

import './moderated-member-detail.scss';

const renderEditModal = (item, onSubmit, onCloseModal) => (
  <Modal>
    <ModalLayout onClose={ onCloseModal } title="EDIT USER STATUS">
      <EditModerationStatus currentStatus={ item.status }
                            onSubmit={ onSubmit }
                            onCancel={ onCloseModal } />
    </ModalLayout>
  </Modal>
);

const renderConfirmModal = (onConfirm, onCancel) => (
  <Modal>
    <ModalLayout onClose={ onCancel } title="CONFIRM STATUS CHANGE">
      <ConfirmModerationStatus onConfirm={ onConfirm } onCancel={ onCancel } />
    </ModalLayout>
  </Modal>
);

const AccountStatus = ({ currentStatus, onEditStatus }) => (
  <div className="account-status">
    <FormRow>
      <Input value={ currentStatus } label="Current Account Status" disabled placeholder="" />
    </FormRow>
    <FormRow>
      <Button label="Edit User Status" onClick={ onEditStatus } />
    </FormRow>
  </div>
);

const MemberBasics = ({ item: { userName, imgSrc, dateJoined, location } }) => (
  <Card>
    <div className="member-basics">
      <div className="member-basics__img">
        <Avatar imageSource={ imgSrc } />
      </div>
      <div className="member-basics__name">
        <label>{ userName }</label>
      </div>
      <div className="member-basics__date">
        <label>Joined on: { dateJoined.toLocaleDateString() }</label>
      </div>
      <div className="member-basics__location">
        <label>{ location }</label>
      </div>
    </div>
  </Card>
);

const formatRows = (items) => items.map((item) => ({
  ...item,
  dateOfAction: item.dateOfAction.toLocaleDateString()
}));


const renderTable = (previousOffences) => (
  <Table
    cols={ MODERATED_DETAIL_HEADERS }
    rowData={ formatRows(previousOffences) }
    pageLength={ 8 }
  />
);

const ModeratedMemberDetail = ({ item, confirmStatusUpdate }) => {
  const [showEditStatusModal, setEditStatusModalToShow] = useState(false);
  const [showConfirmStatusModal, setConfirmStatusModalToShow] = useState(false);

  const onUpdateStatus = () => {
    setConfirmStatusModalToShow(true);
    setEditStatusModalToShow(false);
  };

  const onSubmit = ({ action, reason }) => {
    confirmStatusUpdate({ action, reason });
    onCloseModal();
  };

  const viewDetails = () => {
    setEditStatusModalToShow(true);
  };

  const onCloseModal = () => {
    setEditStatusModalToShow(false);
    setConfirmStatusModalToShow(false);
  };

  return (
    <div className="moderated-member-details">
      <div className="moderated-member-details__aside">
        <MemberBasics item={ item } />
        <AccountStatus currentStatus={ item.status } onEditStatus={ viewDetails } />
      </div>
      <div className="moderated-member-details__table">
        <h5>Report Details</h5>
        { renderTable(item.previousOffences) }
      </div>
      { showEditStatusModal && renderEditModal(item, onUpdateStatus, onCloseModal) }
      { showConfirmStatusModal && renderConfirmModal(onSubmit, onCloseModal) }
    </div>
  );
};

ModeratedMemberDetail.defaultProps = {
  item: {},
  confirmStatusUpdate: noOp
};

ModeratedMemberDetail.propTypes = {
  item: MODERATED_MEMBER_DETAIL_PROP_TYPE,
  confirmStatusUpdate: func
};

export default ModeratedMemberDetail;
