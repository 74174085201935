import React, { createRef } from 'react';

import { arrayOf, func, shape, string } from 'prop-types';

import { noOp } from '../../../tools/helpers';

import Form from '../../form/Form';
import FormRow from '../../form/formRow/FormRow';
import Input from '../../input/Input';
import FormAction from '../../form/formAction/FormAction';

import './forgot-password.scss';

const renderFields = (emailRef, errors) => (
  <FormRow>
    <Input
      type="email"
      placeholder="Enter your email"
      label="Email"
      inputRef={ emailRef }
      error={ errors?.email }
    />
  </FormRow>
);

const renderForm = (emailRef, onSubmit, loading, errors) => (
  <Form>
    { renderFields(emailRef, errors) }
    <FormAction loading={ loading } label="Request OTP" onClick={ onSubmit } />
  </Form>
);

const ForgotPassword = ({ onValidateEmail, errors, loading }) => {
  const emailRef = createRef();

  const onSubmit = () => {
    onValidateEmail(emailRef.current.value);
  };

  return (
    <div className="forgot-password">
      { renderForm(emailRef, onSubmit, loading, errors) }
    </div>
  );
};

ForgotPassword.defaultProps = {
  onValidateEmail: noOp
};

ForgotPassword.propTypes = {
  errors: shape({
    email: arrayOf(string)
  }),
  onValidateEmail: func
};

export default ForgotPassword;
