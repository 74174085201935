import React, { createRef } from 'react';
import { arrayOf, func, string } from 'prop-types';

import { getClassNames, isEmpty, noOp } from '../../../tools/helpers';

import { ReactComponent as CancelIcon } from '../../../icons/cancel-icon.svg';

import Input from '../../input/Input';
import Button from '../../button/Button';

import './create-tag.scss';

const Tag = ({ tag, onRemove, error }) => (
  <div className={ getClassNames('tag', { error }) }>
    { tag }
    <CancelIcon onClick={ () => onRemove(tag) } />
  </div>
);

const renderTags = (createdTags, onRemove) => createdTags.map((tag) => {
    return <Tag key={ tag } tag={ tag } onRemove={ onRemove } />;
  }
);

const CreateTag = ({ createdTags, setTags, saveTags }) => {
  const tagRef = createRef();

  const addTag = (createdTag) => {
    setTags([...createdTags.filter((item) => item !== createdTag), createdTag]);
    tagRef.current.value = '';
    tagRef.current.focus();
  };

  const onKeyUp = (e) => {
    const createdTag = tagRef.current.value;
    if (e.code === 'Enter' && createdTag !== '') {
      addTag(createdTag);
    } else if (!e.code) {
      addTag(createdTag);
    }
  };

  const onRemove = (value) => {
    const updatedTags = createdTags.filter((createdTag) => createdTag !== value);
    setTags(updatedTags);
  };

  return (
    <div className="create-tag">
      <div className="create-tag__subtitle">
        <p>Alpha numeric characters only (not starting with a number) and a maximum length of 32 characters</p>
      </div>
      <div className="create-tag__search">
        <Input
          inputRef={ tagRef }
          label="Tag Name"
          placeholder="Enter a tag"
          onKeyUpCapture={ (e) => onKeyUp(e) }
          action="Add"
          onActionClick={ onKeyUp }
        />
      </div>
      <div className="create-tag__tags">
        { !isEmpty(createdTags) && renderTags(createdTags, onRemove) }
      </div>
      <div className="create-tag__action">
        <Button
          onClick={ saveTags }
          disabled={ isEmpty(createdTags) }
          label="Create Tag(s)"
        />
      </div>
    </div>
  );
};

CreateTag.propTypes = {
  setTags: noOp,
  createdTags: []
};

CreateTag.propTypes = {
  createdTags: arrayOf(string),
  setTags: func
};

export default CreateTag;
