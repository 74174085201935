import React from 'react';
import { useHistory } from 'react-router-dom';
import { arrayOf, number, shape, string } from 'prop-types';

import { howLongAgo } from '../../../tools/date.util';
import { noOp } from '../../../tools/helpers';

import Avatar from '../../avatar/Avatar';

import './activity.scss';

const mapLabel = (activity, history) => {
  switch (activity.action) {
    case 0:
      return [
        undefined,
        undefined,
        noOp,
        <label>
          You liked a <span>post</span>
        </label>
      ];
    case 1:
      return [
        undefined,
        undefined,
        noOp,
        <label>
          You shared a <span>post</span>
        </label>
      ];
    case 2:
      return [
        undefined,
        undefined,
        noOp,
        <label>
          You commented on a <span>post</span>
        </label>
      ];
    case 3:
      const navigateToFollowed = () => history.push(`/member/${activity.followed?.id}/posts`);
      return [
        activity.followed?.first_name,
        activity.followed?.profile_image,
        navigateToFollowed,
        <label onClick={navigateToFollowed}>
          You followed{' '}
          <span>
            {activity.followed?.first_name} {activity.followed?.last_name}
          </span>
        </label>
      ];
    case 4:
      const navigateToFollower = () => history.push(`/member/${activity.follower?.id}/posts`);
      return [
        activity.follower?.first_name,
        activity.follower?.profile_image,
        navigateToFollower,
        <label onClick={navigateToFollower}>
          You were followed by{' '}
          <span>
            {activity.follower?.first_name} {activity.follower?.last_name}
          </span>
        </label>
      ];
    case 5:
      const navigateToConnection = () => history.push(`/member/${activity.connection?.id}/posts`);
      return [
        activity.connection?.first_name,
        activity.connection?.profile_image,
        navigateToConnection,
        <label onClick={navigateToConnection}>
          You connected with{' '}
          <span>
            {activity.connection?.first_name} {activity.connection?.last_name}
          </span>
        </label>
      ];
    case 6:
      return [
        undefined,
        undefined,
        noOp,
        <label>
          A <span>post</span> was shared with you
        </label>
      ];
    case 7:
      const navigateToInvitedList = () =>
        history.push(`/member/${activity.member?.id}/lists/${activity.collaborator?.list?.id}`);
      return [
        activity.collaborator?.list?.title,
        activity.collaborator?.list?.image,
        navigateToInvitedList,
        <label onClick={navigateToInvitedList}>
          You were invited to the <span>{activity.collaborator?.list?.title}</span> list
        </label>
      ];
    case 8:
      const navigateToDisconnection = () =>
        history.push(`/member/${activity.connection?.id}/posts`);
      return [
        activity.connection?.first_name,
        activity.connection?.profile_image,
        navigateToDisconnection,
        <label onClick={navigateToDisconnection}>
          You disconnected from{' '}
          <span>
            {activity.connection?.first_name} {activity.connection?.last_name}
          </span>
        </label>
      ];
    case 9:
      const navigateToUnfollowed = () => history.push(`/member/${activity.followed?.id}/posts`);
      return [
        activity.followed?.title,
        activity.followed?.image,
        navigateToUnfollowed,
        <label onClick={navigateToUnfollowed}>
          You unfollowed{' '}
          <span>
            {activity.followed?.first_name} {activity.followed?.last_name}
          </span>
        </label>
      ];
    case 10:
      const navigateToUnfollower = () => history.push(`/member/${activity.follower?.id}/posts`);
      return [
        activity.follower?.title,
        activity.follower?.image,
        navigateToUnfollower,
        <label onClick={navigateToUnfollower}>
          You were unfollowed by{' '}
          <span>
            {activity.follower?.first_name} {activity.follower?.last_name}
          </span>
        </label>
      ];
    case 11:
      const navigateToPost = () =>
        history.push(`/member/${activity.member?.id}/posts?postId=${activity.post.id}`);
      return [
        activity.post?.title,
        activity.post?.image,
        navigateToPost,
        <label onClick={navigateToPost}>
          You created the <span>{activity.post?.title}</span> post
        </label>
      ];
    case 12:
      const navigateToList = () =>
        history.push(`/member/${activity.member?.id}/lists/${activity.list?.id}`);
      return [
        activity.list?.title,
        activity.list?.image,
        navigateToList,
        <label onClick={navigateToList}>
          You created the <span>{activity.list?.title}</span> list
        </label>
      ];
    case 13:
      const navigateToListItem = () =>
        history.push(`/member/${activity.member?.id}/lists/${activity.list?.id}`);
      return [
        activity.list_item?.title,
        activity.list_item?.image,
        navigateToListItem,
        <label onClick={navigateToListItem}>
          You created the <span>{activity.list_item?.title}</span> list item
        </label>
      ];
    default:
      throw Error(`Action is not recognized: ${activity.action}`);
  }
};

const renderActivities = (activities, history) =>
  activities.map((activity) => {
    const [title, image, onClick, label] = mapLabel(activity, history);

    return (
      <div className="activity__content" key={activity.id}>
        <Avatar onClick={onClick} title={title} noTitle imageSource={image} />
        <div className="activity__label">
          {label}
          <small className="activity__time">{howLongAgo(activity.date)}</small>
        </div>
      </div>
    );
  });

const renderEmpty = () => (
  <div className="activity__content">
    <p className="activity__content--empty">You have no activity!</p>
  </div>
);

const Activity = ({ title, activities }) => {
  const history = useHistory();
  return (
    <div className="activity">
      <div className="activity__header">
        <label>{title}</label>
      </div>
      {activities.length > 0 && renderActivities(activities, history)}
      {!activities.length && renderEmpty()}
    </div>
  );
};

Activity.defaultProps = {
  title: '',
  activities: []
};

Activity.propTypes = {
  title: string,
  activities: arrayOf(
    shape({
      id: number,
      action: number
    })
  )
};

export default Activity;
