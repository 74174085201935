import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { useHistory } from 'react-router-dom';

import { sidebarItems } from '../dashboard/dashboard.helpers';
import { isMobile } from '../../tools/helpers';
import { loadProfile } from '../../actions/profile.actions';
import { performLogout } from '../../actions/auth.actions';
import {
  loadNotifications,
  processNotification,
  readAllNotification,
  readNotification
} from '../../actions/member.actions';

import DashboardContainer from '../dashboard/Dashboard.container';
import MembersContainer from '../members/Members.container';
import MemberProfileContainer from '../members/profile/MemberProfile.container';
import AdminDetailsContainer from './personal-details/AdminDetails.container';
import AdminLayout from '../../components/layout/admin/AdminLayout';
import Sidebar from '../../components/sidebar/sidebar/Sidebar';
import AccountDetailsContainer from './account-details/AccountDetails.container';
import PostCategoriesContainer from './post-management/PostCategories.container';
import PostCuratorsContainer from './post-curators/PostCurators.container';
import ReportedPostsContainer from './reported-posts/ReportedPosts.container';
import EmptyCategoriesContainer from './post-management/EmptyCategories.container';
import TagsContainer from './tags/Tags.container';
import ReputationContainer from '../members/reputation/Reputation.container';
import ReputationLevelsContainer from '../members/reputation/ReputationLevels.container';
import ReputationBadgesContainer from '../members/reputation/ReputationBadges.container';
import ReputationPointsMatrixContainer from '../members/reputation/ReputationPointsMatrix.container';
import MemberModerationContainer from '../members/moderation/MemberModeration.container';
import ModeratedMembersContainer from '../members/moderation/ModeratedMembers.container';
import ModeratedMemberDetailContainer from '../members/moderation/ModeratedMemberDetail.container';
import PostsContainer from '../posts/Posts.container';
import NotificationSettingsContainer from './notifications/NotificationsSettings.container';


const renderSidebar = (expanded, setExpanded) => (
  <Sidebar
    expanded={ expanded }
    onExpand={ () => setExpanded(!expanded) }
    items={ sidebarItems }
  />
);

const AdminContainer = ({   
  dispatch,
  profile,
  activities,
  notifications,
  connections,
  appTitle,
  count,
  notificationNextPage,
  unreadCount }) => {
    const history = useHistory();
    const [expanded, setExpanded] = useState();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(loadProfile(() => setLoading(false)));

    if (!isMobile()) {
      setTimeout(() => {
        setExpanded(false);
      }, 3000);
    }

    return () => {};
  }, [dispatch]);

  const onMarkAsRead = (notification) => {
    dispatch(readNotification(profile.id, notification.id));
  };

  const onMarkAllAsRead = () => {
    dispatch(readAllNotification(profile.id));
  };

  const onMarkAsProcessed = (notification) => {
    dispatch(processNotification(profile.id, notification.id));
  };

  const onLogOut = () => {
    dispatch(performLogout());
    history.push('/');
  };

  const notificationLoadMore = () => {
    if (notificationNextPage !== null) {
      dispatch(loadNotifications(profile?.id, notificationNextPage));
    }
  };

  return (
    <Switch>
      <AdminLayout
        onMarkAsProcessed={onMarkAsProcessed}
        expanded={expanded}
        setExpanded={setExpanded}
        profile={profile}
        activities={activities}
        connections={connections}
        title={appTitle}
        notifications={notifications}
        notificationLoadMore={notificationLoadMore}
        count={count}
        unreadCount={unreadCount}
        loading={loading}
        markAllAsRead={onMarkAllAsRead}
        markAsRead={onMarkAsRead}
        sideContent={renderSidebar(expanded, setExpanded, profile)}
        onLogOut={onLogOut}
      >
        <Route exact path="/admin/dashboard" component={ DashboardContainer } />

        <Route exact path="/admin/members" component={ MembersContainer } />
        <Route exact path="/admin/members/:id/profile" component={ MemberProfileContainer } />
        <Route exact path="/admin/reputation" component={ ReputationContainer } />
        <Route exact path="/admin/reputation/levels" component={ ReputationLevelsContainer } />
        <Route exact path="/admin/reputation/badges" component={ ReputationBadgesContainer } />
        <Route exact path="/admin/reputation/points-matrix" component={ ReputationPointsMatrixContainer } />
        <Route exact path="/admin/moderation" component={ MemberModerationContainer } />
        <Route exact path="/admin/moderation/moderated" component={ ModeratedMembersContainer } />
        <Route exact path="/admin/moderation/moderated/:id" component={ ModeratedMemberDetailContainer } />
        <Route
          exact
          path="/admin/:id/notification-settings"
          component={NotificationSettingsContainer}
        />

        <Route exact path="/admin/posts" component={ PostsContainer } />

        <Route exact path="/admin/reports" component={ DashboardContainer } />
        <Route exact path="/admin/settings" component={ DashboardContainer } />

        <Route exact path="/admin/personal-details" component={ AdminDetailsContainer } />
        <Route exact path="/admin/account-details" component={ AccountDetailsContainer } />
        <Route exact path="/admin/categories" component={ PostCategoriesContainer } />
        <Route exact path="/admin/empty-categories" component={ EmptyCategoriesContainer } />
        <Route exact path="/admin/curators" component={ PostCuratorsContainer } />
        <Route exact path="/admin/reports" component={ ReportedPostsContainer } />
        <Route exact path="/admin/tags" component={ TagsContainer } />
      </AdminLayout>
    </Switch>
  );
};

const mapStateToProps = ({ profile, member }) => ({
  profile: profile.profile,
  notifications: member.notifications,
  notificationNextPage: member.notificationNextPage,
  count: member.notificationCount,
  unreadCount: member.unreadCount
});

export default connect(mapStateToProps)(AdminContainer);
