import { func, string } from 'prop-types';
import React from 'react';

import { noOp } from '../../tools/helpers';

import Button from '../button/Button';
import { ReactComponent as NotFoundImg } from '../../icons/not-found-icon.svg';

import './not-found.scss';

const NotFound = ({ message, actionText, onActionClick }) => (
  <div className="not-found">
    <NotFoundImg />
    <p>{message}</p>
    {actionText && (
      <div className="not-found__action">
        <Button label={actionText} onClick={onActionClick} />
      </div>
    )}
  </div>
);

NotFound.defaultProps = {
  actionText: undefined,
  onActionClick: noOp
};

NotFound.propTypes = {
  message: string.isRequired,
  actionText: string,
  onActionClick: func
};

export default NotFound;
