import React from 'react';
import { arrayOf, func, instanceOf, number, oneOfType, shape, string } from 'prop-types';

import { getClassNames, isUndefined, noOp } from '../../tools/helpers';
import { ReactComponent as IconError } from '../../icons/error-small-icon.svg';

import './text-area.scss';

const renderTextArea = (textAreaRef, name, placeholder, value, onChange, rows) => (
  <textarea
    ref={ textAreaRef }
    name={ name }
    placeholder={ placeholder }
    onChange={ onChange }
    defaultValue={ value }
    rows={ rows }
  />
);

const renderErrors = (errors) => errors && errors.map((error) => (
  <div className="text-area__error" key={ error }>
    <IconError />
    <small className="text-area__error__error-msg" key={ error }>{ error }</small>
  </div>
));

const renderLabel = (label) => !isUndefined(label) && (
  <label className="text-area__label">{ label }</label>
);

const TextArea = ({ textAreaRef, name, placeholder, onChange, value, error, rows, label }) => (
  <div className={ getClassNames('text-area', { error }) }>
    { renderLabel(label) }
    <div className="text-area__text-area-wrapper">
      { renderTextArea(textAreaRef, name, placeholder, value, onChange, rows) }
    </div>
    { renderErrors(error) }
  </div>
);

TextArea.defaultProps = {
  textAreaRef: null,
  name: '',
  onChange: noOp,
  value: '',
  error: undefined,
  rows: 5,
  label: undefined
};

TextArea.propTypes = {
  placeholder: string.isRequired,
  textAreaRef: oneOfType([
    func, // either a function
    shape({ // or an object shaped like this
      current: instanceOf(Element)
    })
  ]),
  name: string,
  label: string,
  onChange: func,
  value: string,
  error: arrayOf(string),
  rows: number
};

export default TextArea;
