import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { requestCompleteProfile } from '../../../actions/auth.actions';

import { ReactComponent as AdminLogo } from '../../../icons/admin-logo.svg';

import AuthLayout from '../../../components/layout/auth/AuthLayout';
import CompleteProfile from '../../../components/auth/complete-profile/CompleteProfile';

const CompleteProfileContainer = ({ dispatch, errors, profileId }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const completeProfile = (profile) => {
    setLoading(true);
    dispatch(requestCompleteProfile(profileId, profile, history, () => setLoading(false)));
  };

  return (
    <AuthLayout title="Complete profile" subtitle="Complete your details to continue" logo={ <AdminLogo /> }>
      <CompleteProfile errors={ errors } onCompleteProfile={ completeProfile } loading={ loading } />
    </AuthLayout>
  );
};

const mapStateToProps = ({ auth }) => ({
  errors: auth.completeErrors,
  profileId: auth.profileId
});

export default connect(mapStateToProps)(CompleteProfileContainer);
