import React from 'react';
import { arrayOf, func, node, oneOfType, string } from 'prop-types';

import { getClassNames } from '../../tools/helpers';

import { ReactComponent as IconX } from './../../icons/x-icon.svg';

const ModalLayout = ({ title, onClose, children, large }) => (
  <div className={ getClassNames('modal-layout', { large }) }>
    <div className="modal-layout__header">
      <h1 className="modal-layout__header-text">{ title }</h1>
      <div className="modal-layout__close">
        <IconX onClick={ onClose } />
      </div>
    </div>
    <div className="modal-layout__content">
      { children }
    </div>
  </div>
);

ModalLayout.propTypes = {
  title: string.isRequired,
  onClose: func.isRequired,
  children: oneOfType([arrayOf(node), node]).isRequired
};

export default ModalLayout;
