import { API_HOST, getHttpPutOptions, getHttpPostOptions, getHttpGetOptions } from './index';

const getVerifyEmailEndpoint = (email) => `${ API_HOST }/members/${ email }/validate`;
export const getVerifyEmailRequest = (email) => [
  getVerifyEmailEndpoint(email),
  getHttpGetOptions()
];

const getLoginEndpoint = () => `${ API_HOST }/auth/admin/login`;
export const getLoginRequest = (email, password) => [
  getLoginEndpoint(),
  getHttpPostOptions({ username: email, password })
];

const getCompleteProfileEndpoint = (profileId) => `${ API_HOST }/auth/register/${ profileId }/complete`;
export const getCompleteProfileRequest = (profile, profileId) => [
  getCompleteProfileEndpoint(profileId),
  getHttpPutOptions(profile)
];

const getValidateEmailEndpoint = (email) => `${ API_HOST }/auth/password/${ email }/validate`;
export const getValidateEmailRequest = (email) => [
  getValidateEmailEndpoint(email),
  getHttpGetOptions()
];

const getValidateOtpEndpoint = (token) => `${ API_HOST }/auth/password/${ token }/otp`;
export const getValidateOtpRequest = (token, otp) => [
  getValidateOtpEndpoint(token),
  getHttpPutOptions({ otp })
];

const getResendOtpEndpoint = (token) => `${ API_HOST }/auth/password/${ token }/resend`;
export const getResendOtpRequest = (token) => [
  getResendOtpEndpoint(token),
  getHttpGetOptions()
];

const getResetPasswordEndpoint = (token) => `${ API_HOST }/auth/password/${ token }/reset`;
export const getResetPasswordRequest = (token, password, password_confirm) => [
  getResetPasswordEndpoint(token),
  getHttpPutOptions({ reset_token: token, password, password_confirm })
];
