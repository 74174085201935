import React, { createRef } from 'react';
import { Link } from 'react-router-dom';
import { func } from 'prop-types';

import { noOp } from '../../../tools/helpers';

import Form from '../../form/Form';
import FormRow from '../../form/formRow/FormRow';
import Input from '../../input/Input';
import FormAction from '../../form/formAction/FormAction';

import './verify-account.scss';

const renderFields = (emailRef, errors) => (
  <FormRow>
    <Input
      type="email"
      placeholder="Enter your email"
      label="Email"
      inputRef={ emailRef }
      error={ errors?.email }
    />
  </FormRow>
);

const renderForm = (emailRef, onSubmit, loading, errors) => (
  <Form>
    { renderFields(emailRef, errors) }
    <FormAction loading={ loading } label="Continue" onClick={ onSubmit } />
  </Form>
);

const VerifyAccount = ({ onVerifyEmail, errors, loading }) => {
  const emailRef = createRef();

  const onSubmit = async () => {
    onVerifyEmail(emailRef.current.value);
  };

  return (
    <div className="verify-account">
      { renderForm(emailRef, onSubmit, loading, errors) }
      <div className="verify-account__signup">
        <p>Already have an account? <Link to="/">Sign in</Link></p>
      </div>
    </div>
  );
};

VerifyAccount.defaultProps = {
  onVerifyEmail: noOp
};

VerifyAccount.propTypes = {
  onVerifyEmail: func
};

export default VerifyAccount;
