import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import authSaga from './sagas/auth.saga';
import membersSaga from './sagas/members.saga';
import moderationSaga from './sagas/moderation.saga';
import profileSaga from './sagas/profile.saga';
import notificationSaga from './sagas/notification.saga';
import postSaga from './sagas/post.saga';
import reputationSaga from './sagas/reputation.saga';
import rootReducer from './reducers';

import App from './App';
import reportWebVitals from './reportWebVitals';

import './stylesheets/app.scss';

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(authSaga);
sagaMiddleware.run(membersSaga);
sagaMiddleware.run(profileSaga);
sagaMiddleware.run(postSaga);
sagaMiddleware.run(reputationSaga);
sagaMiddleware.run(moderationSaga);
sagaMiddleware.run(notificationSaga);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={ store }>
      <BrowserRouter basename={ process.env.PUBLIC_URL }>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
