import { SET_CATEGORIES, SET_CURATORS, SET_EMPTY_CATEGORIES, SET_POSTS, SET_TAGS } from '../actions/post.actions';

export const initialState = {
  posts: [],
  postCount: 0,
  nextPage: 1,
  tags: [],
  categories: [],
  emptyCategories: [],
  curators: []
};

export function postReducer(state = initialState, action) {
  switch (action.type) {
    case SET_POSTS:
      const { posts, count, nextPage, initial } = action;
      return {
        ...state,
        posts: initial ? posts : [...state.posts, ...posts],
        nextPage,
        postCount: count
      };
    case SET_CATEGORIES:
      const { categories } = action;
      return {
        ...state,
        categories
      };
    case SET_EMPTY_CATEGORIES:
      const { emptyCategories } = action;
      return {
        ...state,
        emptyCategories
      };
    case SET_TAGS:
      const { tags } = action;
      return {
        ...state,
        tags
      };
    case SET_CURATORS:
      const { curators } = action;
      return {
        ...state,
        curators
      };
    default:
      return state;
  }
}
