import React, { createRef, useEffect, useState } from 'react';
import { arrayOf, func, number, shape, string } from 'prop-types';

import { delay, noOp } from '../../../tools/helpers';

import { ReactComponent as EditIcon } from '../../../icons/edit-icon.svg';
import Form from '../../form/Form';
import FormRow from '../../form/formRow/FormRow';
import Input from '../../input/Input';
import FormAction from '../../form/formAction/FormAction';

import './admin-details.scss';

const renderForm = (firstNameRef, lastNameRef, cellRef, loading, onSubmit, errors) => (
  <Form>
    <FormRow>
      <Input
        label="First Name"
        placeholder="First Name"
        medium
        inputRef={ firstNameRef }
        icon={ EditIcon }
        error={ errors?.first_name }
      />
      <Input
        label="Last Name"
        placeholder="Last Name"
        medium
        inputRef={ lastNameRef }
        icon={ EditIcon }
        error={ errors?.last_name }
      />
    </FormRow>
    <FormRow half>
      <Input
        label="Cellphone Number"
        medium
        placeholder="Cellphone Number"
        inputRef={ cellRef }
        icon={ EditIcon }
        error={ errors?.contact_number }
      />
    </FormRow>
    <FormAction label="Save Changes" onClick={ onSubmit } loading={ loading } />
  </Form>
);

const AdminDetails = ({ profile, errors, onSave }) => {
  const firstNameRef = createRef();
  const lastNameRef = createRef();
  const cellRef = createRef();

  useEffect(() => {
    if (!!firstNameRef.current && profile) {
      firstNameRef.current.value = profile.first_name ?? '';
      lastNameRef.current.value = profile.last_name ?? '';
      cellRef.current.value = profile.contact_number ?? '';
    }

    return () => {
    };
  });

  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);

    onSave({
      first_name: firstNameRef.current.value,
      last_name: lastNameRef.current.value,
      contact_number: cellRef.current.value,
      email: profile.email
    });

    await delay(1000);
    setLoading(false);
  };

  return (
    <div className="admin-details">
      <div className="admin-details__personal-details">
        <h6 className="admin-details__title">Personal Details</h6>
        { renderForm(firstNameRef, lastNameRef, cellRef, loading, onSubmit, errors) }
      </div>
    </div>
  );
};

AdminDetails.defaultProps = {
  profile: undefined,
  onSave: noOp,
  errors: undefined
};

AdminDetails.propTypes = {
  profile: shape({
    id: number.isRequired
  }),
  onSave: func,
  errors: shape({
    first_name: arrayOf(string),
    last_name: arrayOf(string),
    contact_number: arrayOf(string)
  })
};

export default AdminDetails;

