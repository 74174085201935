import { SET_REPORTED_POSTS } from '../actions/moderation.actions';

export const initialState = {
  reportedPosts: []
};

export function moderationReducer(state = initialState, action) {
  switch (action.type) {
    case SET_REPORTED_POSTS:
      const { reportedPosts } = action;
      return {
        ...state,
        reportedPosts
      };
    default:
      return state;
  }
}
