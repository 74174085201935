export const RETRIEVE_REPORTED_POSTS = '[REPORT_STORE] Retrieve reported posts';
export const SET_REPORTED_POSTS = '[REPORT_STORE] Set reported posts';

export const retrieveReportedPosts = () => ({
  type: RETRIEVE_REPORTED_POSTS
});

export const REMOVE_REPORT = '[REPORT_STORE] Remove report';
export const removeReport = (id) => ({
  type: REMOVE_REPORT,
  id
});

export const ACTION_REPORT = '[REPORT_STORE] Action report';
export const actionReport = (id, report) => ({
  type: ACTION_REPORT,
  id,
  report
});
