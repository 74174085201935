import React, { createRef, useEffect, useState } from 'react';

import { delay } from '../../../tools/helpers';
import { ReactComponent as Duck } from '../../../icons/duck.svg';
import { ReactComponent as Chicken } from '../../../icons/chicken.svg';

import Button from '../../button/Button';
import Card from '../../card/Card';
import Avatar from '../../avatar/Avatar';
import Form from '../../form/Form';
import FormRow from '../../form/formRow/FormRow';
import Input from '../../input/Input';
import FormAction from '../../form/formAction/FormAction';

import './member-profile.scss';

const ProfileAction = ({ title, message, action, loading, onClick }) => (
  <div className="profile-action">
    <div className="profile-action__detail">
      <h6 className="profile-action__title">{ title }</h6>
      <p className="profile-action__message">{ message }</p>
    </div>
    <div className="profile-action__action">
      <Button label={ action } loading={ loading } onClick={ onClick } />
    </div>
  </div>
);

const mapStatus = (active) => active ? 'Active' : 'Not yet verified';

const renderForm = (firstNameRef, lastNameRef, emailRef, cellRef, hometownRef, statusRef, onSave, errors, loading) => (
  <Form>
    <FormRow>
      <Input
        label="First Name"
        placeholder="First Name"
        inputRef={ firstNameRef }
        error={ errors?.first_name }
      />
      <Input
        label="Last Name"
        placeholder="Last Name"
        inputRef={ lastNameRef }
        error={ errors?.last_name }
      />
    </FormRow>
    <FormRow>
      <Input
        label="Email Address"
        placeholder="Email Address"
        type="email"
        inputRef={ emailRef }
        error={ errors?.email }
      />
      <Input
        label="Cellphone Number"
        placeholder="Cellphone Number"
        inputRef={ cellRef }
        error={ errors?.contact_number }
      />
    </FormRow>
    <FormRow>
      <Input
        label="Hometown"
        placeholder="Hometown"
        inputRef={ hometownRef }
        error={ errors?.home_town }
      />
      <Input
        placeholder="Status"
        label="Status"
        inputRef={ statusRef }
        readOnly
      />
    </FormRow>
    <FormAction label="Save" onClick={ onSave } loading={ loading } />
  </Form>
);

const renderActions = (onResetPassword, passwordLoading, onDelete) => (
  <div className="member-profile__actions">
    <ProfileAction
      title="Initiate password Reset"
      message="By initiating password reset, you force the user to create a new password for their account"
      action="Reset Password"
      loading={ passwordLoading }
      onClick={ onResetPassword }
    />
    <ProfileAction
      title="Permanently Delete User"
      message="By permanently deleting the user all their data and information will be lost"
      action="Delete User"
      onClick={ onDelete }
    />
  </div>
);

const MemberProfile = ({ member, errors, onSave, onResetPassword, onDelete }) => {
  const firstNameRef = createRef();
  const lastNameRef = createRef();
  const emailRef = createRef();
  const cellRef = createRef();
  const hometownRef = createRef();
  const statusRef = createRef();

  const [loading, setLoading] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false);

  useEffect(() => {
    if (!!firstNameRef.current && member) {
      firstNameRef.current.value = member.first_name ?? '';
      lastNameRef.current.value = member.last_name ?? '';
      emailRef.current.value = member.email ?? '';
      cellRef.current.value = member.contact_number ?? '';
      hometownRef.current.value = member.home_town ?? '';
      statusRef.current.value = mapStatus(member.is_active);
    }

    return () => {
    };
  });

  const saveMember = async () => {
    setLoading(true);

    onSave({
      id: member.id,
      first_name: firstNameRef.current.value,
      last_name: lastNameRef.current.value,
      email: emailRef.current.value,
      contact_number: cellRef.current.value,
      home_town: hometownRef.current.value
    });

    await delay(1000);

    setLoading(false);
  };

  const resetPassword = async () => {
    setPasswordLoading(true);
    onResetPassword(member);

    await delay(1000);

    setPasswordLoading(false);
  };

  return (
    <div className="member-profile">
      <div className="member-profile__basic-details">
        <Card>
          <Avatar large imageSource={ member?.profile_image } />
          <h5 className="member-profile__name">{ member?.first_name } { member?.last_name }</h5>
          <p className="member-profile__date">Joined on: { member?.date_joined }</p>
          <p className="member-profile__town">{ member?.home_town }</p>
        </Card>
        <div className="member-profile__recent">
          <h6 className="member-profile__title">Recent Trophies</h6>
          <div className="member-profile__badges">
            <Card subtitle="Duck">
              <Duck />
            </Card>
            <Card subtitle="Chicken">
              <Chicken />
            </Card>
          </div>
          <p style={ { marginTop: 15, color: '#ed6f38' } }>See All</p>
        </div>
      </div>
      <div className="member-profile__personal-details">
        <h6 className="member-profile__title">Personal Details</h6>
        { renderForm(firstNameRef, lastNameRef, emailRef, cellRef, hometownRef, statusRef, saveMember, errors, loading) }
        { renderActions(resetPassword, passwordLoading, onDelete) }
      </div>
    </div>
  );
};

export default MemberProfile;
