import React from 'react';
import { connect } from 'react-redux';
import { func } from 'prop-types';
import { removeMember, retrieveMember, updateMember } from '../../../actions/member.actions';
import { validateEmail } from '../../../actions/auth.actions';
import { MEMBER_PROP_TYPE } from '../../../tools/prop.types';

import withAuthentication from '../../auth/withAuthentication';
import PageLayout from '../../../components/layout/page/PageLayout';
import MemberProfile from '../../../components/members/profile/MemberProfile';
import Modal from '../../../components/modal/Modal';
import ModalLayout from '../../../components/modal/ModalLayout';
import DeleteMember from '../../../components/members/delete-member/DeleteMember';
import Loader from '../../../components/loader/Loader';

class MemberProfileContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDeactivateModal: false,
      loading: true,
      member: {}
    };
  }

  componentDidMount() {
    const { dispatch, match: { params: { id } } } = this.props;
    dispatch(retrieveMember(id, this.stopLoading));
  }

  onUpdate = (member) => {
    const { dispatch } = this.props;

    dispatch(updateMember(member.id, member, this.stopLoading));
  };

  stopLoading = () => {
    this.setState({ loading: false });
  };

  onDeleteMember = (member) => {
    this.setState({ showDeleteModal: true, member });
  };

  onResetPassword = (member) => {
    const { dispatch } = this.props;

    dispatch(validateEmail(member.email));
  };

  onCloseDeleteModal = () => {
    this.setState({ showDeleteModal: false, member: undefined });
  };

  deleteMember = () => {
    this.onCloseDeleteModal();

    const { dispatch, member, history } = this.props;
    dispatch(removeMember(member.id, history));
  };

  renderDeleteModal = () => this.state.showDeleteModal && (
    <Modal>
      <ModalLayout onClose={ this.onCloseDeleteModal } title="Permanently delete user">
        <DeleteMember onDelete={ this.deleteMember } onClose={ this.onCloseDeleteModal } />
      </ModalLayout>
    </Modal>
  );

  render() {
    const { member, errors } = this.props;
    const { loading } = this.state;

    return (
      <PageLayout back title="Member Profile">
        { loading && <Loader orange /> }
        { !loading && <MemberProfile
          member={ member }
          errors={ errors }
          onSave={ this.onUpdate }
          onResetPassword={ this.onResetPassword }
          onDelete={ this.onDeleteMember }
        /> }
        { this.renderDeleteModal() }
      </PageLayout>
    );
  }
}

MemberProfileContainer.propTypes = {
  member: MEMBER_PROP_TYPE,
  dispatch: func.isRequired
};

const mapStateToProps = ({ member }) => ({
  member: member.member,
  errors: member.memberErrors
});

export default connect(mapStateToProps)(withAuthentication(MemberProfileContainer));
