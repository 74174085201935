/**
 * @module Checkbox Component
 * @description
 * Relies on a native `input[type='checkbox']` to enable checkbox functionality.
 * Can be contained by other elements, in which case, it will inherit font styles
 *  and expand into its container.
 */
import React, { useState } from 'react';
import { bool, func, string } from 'prop-types';
import { Link } from 'react-router-dom';

import { getClassNames, noOp } from '../../tools/helpers';

import { ReactComponent as IconCheck } from '../../icons/icon-check-small.svg';

import './checkbox.scss';

const renderIndicatorIcon = (isChecked) => isChecked && <IconCheck />;

const renderLink = (link, linkText) => link && <Link className="checkbox__label-link" to={ link }>{ linkText ?? link }</Link>;

const renderLabel = (label, link, linkText) => <p className="checkbox__label">{ label } { renderLink(link, linkText) }</p>;

const Checkbox = ({
                    name, label, checked, onChange, link, linkText
                  }) => {
  const [isChecked, setChecked] = useState(checked || false);
  const handleChange = (event) => {
    setChecked(!isChecked);
    onChange(event);
  };

  return (
    <label className={ getClassNames('checkbox', { checked: isChecked }) }>
      <input
        name={ name }
        type="checkbox"
        checked={ checked }
        className="checkbox__native"
        onChange={ handleChange }
      />
      <div className={ getClassNames('checkbox__indicator', { checked: isChecked }) }>
        { renderIndicatorIcon(isChecked) }
      </div>
      { renderLabel(label, link, linkText) }
    </label>
  );
};

Checkbox.defaultProps = {
  name: '',
  label: undefined,
  checked: false,
  onChange: noOp,
  link: undefined,
  linkText: undefined
};

Checkbox.propTypes = {
  name: string,
  label: string,
  checked: bool,
  onChange: func,
  link: string,
  linkText: string
};

export default Checkbox;
