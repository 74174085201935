import { arrayOf, node, number, shape, string } from 'prop-types';
import React, { useState } from 'react';

import { getPointWithSign } from '../rep.helper';
import { noOp } from '../../../../tools/helpers';

import Modal from '../../../modal/Modal';
import ModalLayout from '../../../modal/ModalLayout';
import ReputationBadgeEdit from './edit/ReputationBadgeEdit';
import { ReactComponent as IconPoint } from '../../../../icons/reputation/badges/icon-point-white.svg';
import { ReactComponent as IconPencil } from '../../../../icons/pencil-icon.svg';

import './reputation-badges.scss';

const Badge = ({ badge, onBadgeEdit }) => {
  const { name, icon, description, points } = badge;

  return (
    <div className="badge">
      <div className="badge__edit" onClick={ () => onBadgeEdit(badge) }>
        <IconPencil />
      </div>
      <div className="badge__points">
        <IconPoint />
        { <label>{ getPointWithSign(points) }</label> }
      </div>
      <div className="badge__icon">
        { icon }
      </div>
      <h5 className="badge__name">{ name }</h5>
      <div className="badge__description">
        <label>{ description }</label>
      </div>
    </div>
  );
};

const ReputationBadges = ({ badges, updateBadge }) => {
  const [showModal, setModalToShow] = useState(false);
  const [badgeToEdit, setBadgeToEdit] = useState();

  const onUpdateBadge = (badge) => {
    updateBadge(badge);
    setModalToShow(false);
  };

  const editBadge = (badge) => {
    setModalToShow(true);
    setBadgeToEdit(badge);
  };

  const onCloseModal = () => {
    setModalToShow(false);
    setBadgeToEdit(undefined);
  };

  return (
    <div className="reputation-badges">
      { badges.map(badge => <Badge key={ badge.name } badge={ badge } onBadgeEdit={ editBadge } />) }

      { showModal && <Modal>
        <ModalLayout onClose={ onCloseModal } title="EDIT BADGE">
          <ReputationBadgeEdit badge={ badgeToEdit }
                               saveChanges={ onUpdateBadge }
                               onCancel={ onCloseModal } />
        </ModalLayout>
      </Modal> }
    </div>
  );
};

ReputationBadges.defaultProps = {
  badges: [],
  updateBadge: noOp
};

ReputationBadges.propTypes = {
  badges: arrayOf(shape({
    name: string.isRequired,
    description: string.isRequired,
    points: number.isRequired,
    icon: node.isRequired
  }))
};

export default ReputationBadges;
