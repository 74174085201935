import React from 'react';

import Button from '../../button/Button';

import './delete-category.scss';

const DeleteCategory = ({ removeCategory, onClose }) => (
  <div className="delete-category">
    <div className="delete-category__header">
      <p>Are you sure you want to delete this category?</p>
      <p>All the associated data will be lost</p>
    </div>
    <div className="delete-category__action">
      <Button label="Yes, Delete Category" onClick={ removeCategory } />
      <Button blank label="No, keep category" onClick={ onClose } />
    </div>
  </div>
);

export default DeleteCategory;
