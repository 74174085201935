/**
 * @module Loader Component
 * @description
 * To be displayed when waiting for a response from an asynchronous call.
 */
import React from 'react';
import { bool } from 'prop-types';

import { getClassNames } from '../../tools/helpers';

import './loader.scss';

const Loader = ({ orange }) => (
  <div className={ getClassNames('loader', { orange }) }>
    <div className="loader-ellipsis">
      <div />
      <div />
      <div />
    </div>
  </div>
);

Loader.defaultProps = {
  orange: false
};

Loader.propTypes = {
  orange: bool
};

export default Loader;
