import {
  REMOVE_INVITE_ERROR,
  RESET_INVITE_ERRORS,
  SET_INVITE_ERRORS,
  SET_MEMBER,
  SET_MEMBER_ERRORS,
  SET_MEMBERS,
  SET_NOTIFICATIONS,
  SET_UNREAD_NOTIFICATION
} from '../actions/member.actions';

export const initialState = {
  members: [],
  member: undefined,
  memberErrors: undefined,
  inviteErrors: {}
};

export function memberReducer(state = initialState, action) {
  switch (action.type) {
    case SET_MEMBERS:
      const { members } = action;
      return {
        ...state,
        members
      };
    case SET_MEMBER:
      const { member } = action;
      return {
        ...state,
        member
      };
    case SET_MEMBER_ERRORS:
      const { memberErrors } = action;
      return {
        ...state,
        memberErrors
      };
      case SET_NOTIFICATIONS: {
        const { notifications, count, nextPage, initial } = action;
        return {
          ...state,
          notifications: initial ? notifications : [...state.notifications, ...notifications],
          notificationNextPage: nextPage,
          notificationCount: count
        };
      }
      case SET_UNREAD_NOTIFICATION: {
        const { unreadCount } = action;
        return {
          ...state,
          unreadCount
        };
      }
    case SET_INVITE_ERRORS:
      const { email } = action;
      return {
        ...state,
        inviteErrors: {
          [email]: true
        }
      };
    case REMOVE_INVITE_ERROR:
      const { tag } = action;
      const inviteErrorEntries = Object.entries(state.inviteErrors)
        .filter(([key]) => key !== tag);
      return {
        ...state,
        inviteErrors: Object.fromEntries(inviteErrorEntries)
      };
    case RESET_INVITE_ERRORS:
      return {
        ...state,
        inviteErrors: {}
      };
    default:
      return state;
  }
}
