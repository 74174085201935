import axios from 'axios';
import { all, call, put } from '@redux-saga/core/effects';
import { takeLatest } from 'redux-saga/effects';

import { SNACK_CRITICAL, SNACK_SUCCESS } from '../tools/helpers';
import { addSystemNotice } from '../actions/system.actions';
import {
  getRetrieveNotificationSettingsRequest,
  getUpdateNotificationSettingsRequest
} from '../tools/api/notification.endpoints';
import {
  LOAD_NOTIFICATION_SETTINGS,
  loadNotificationSettings,
  SET_NOTIFICATION_SETTINGS,
  UPDATE_NOTIFICATION_SETTINGS
} from '../actions/notifications.actions';

export function* performUpdateNotificationSettings({ id, settingsId, notificationSettings }) {
  try {
    const [endpoint, requestOptions] = getUpdateNotificationSettingsRequest(
      id,
      settingsId,
      notificationSettings
    );

    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('Successfully updated notification settings', SNACK_SUCCESS));

    yield call(loadNotificationSettings, id);
  } catch (error) {
    yield put(
      addSystemNotice('We were not able to update your notification settings', SNACK_CRITICAL)
    );
  }
}

export function* watchForUpdateNotificationSettings() {
  yield takeLatest(UPDATE_NOTIFICATION_SETTINGS, performUpdateNotificationSettings);
}

export function* performLoadNotificationSettings({ id, onSuccess }) {
  try {
    const [endpoint, requestOptions] = getRetrieveNotificationSettingsRequest(id);
    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({
      type: SET_NOTIFICATION_SETTINGS,
      notificationSettings: data.notification_settings
    });

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch ({ response }) {
    if (response.status !== 401) {
      yield put(
        addSystemNotice('We were not able to load your notification settings', SNACK_CRITICAL)
      );
    }
  }
}

export function* watchForLoadNotificationSettings() {
  yield takeLatest(LOAD_NOTIFICATION_SETTINGS, performLoadNotificationSettings);
}

export default function* notificationSaga() {
  yield all([watchForUpdateNotificationSettings(), watchForLoadNotificationSettings()]);
}
