import React from 'react';
import { connect } from 'react-redux';

import { loadCurators } from '../../../actions/post.actions';

import withAuthentication from '../../auth/withAuthentication';
import PageLayout from '../../../components/layout/page/PageLayout';
import PostCurators from '../../../components/admin/post-curators/PostCurators';

class PostCuratorsContainer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true
    };
  }

  componentDidMount = () => {
    this.fetchCurators();
  };

  fetchCurators = () => {
    const { dispatch } = this.props;
    dispatch(loadCurators(this.stopLoading));
  };

  stopLoading = () => {
    this.setState({ loading: false });
  };

  render() {
    const { curators } = this.props;
    const { loading } = this.state;

    return (
      <PageLayout title="Post Curators">
        <PostCurators curators={ curators } loading={ loading } />
      </PageLayout>
    );
  }
}

const mapStateToProps = ({ post }) => ({
  curators: post.curators
});

export default connect(mapStateToProps)(withAuthentication(PostCuratorsContainer));
