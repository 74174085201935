import React, { Component } from 'react';
import { connect } from 'react-redux';
import { arrayOf, func } from 'prop-types';

import { isEmpty } from '../../tools/helpers';
import { MEMBER_PROP_TYPE } from '../../tools/prop.types';
import {
  activateMember,
  checkIfEmailExists,
  deactivateMember,
  inviteAdmins,
  removeInviteError,
  resetInviteErrors,
  retrieveMembers,
  updateMember
} from '../../actions/member.actions';


import withAuthentication from '../auth/withAuthentication';
import PageLayout from '../../components/layout/page/PageLayout';
import MemberList from '../../components/members/MemberList';
import Modal from '../../components/modal/Modal';
import ModalLayout from '../../components/modal/ModalLayout';
import InviteAdmin from '../../components/members/invite-admin/InviteAdmin';
import DeactivateMember from '../../components/members/deactivate-member/DeactivateMember';
import ActivateMember from '../../components/members/activate-member/ActivateMember';

class MembersContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: undefined,
      ordering: undefined,
      member: undefined,
      emails: [],
      showModal: false,
      showDeactivateModal: false,
      showActivateModal: false,
      loading: true
    };
  }

  componentDidMount() {
    this.findMembers();
  }

  stopLoading = () => {
    this.setState({ loading: false });
  };

  findMembers() {
    const { search, ordering } = this.state;
    const { dispatch } = this.props;

    dispatch(retrieveMembers({ search, ordering, stopLoading: this.stopLoading }));
  }

  onSearch = ({ target: { value } }) => {
    this.setState({ search: value }, () => {
      this.findMembers();
    });
  };

  onSort = ({ target: { value } }) => {
    this.setState({ ordering: value }, () => {
      this.findMembers();
    });
  };

  onOpenModal = () => {
    this.props.dispatch(resetInviteErrors());
    this.setState({ showModal: true, emails: [] });
  };

  onCloseModal = () => {
    this.setState({ showModal: false });
  };

  onInviteAdmins = (emails) => {
    this.onCloseModal();

    const { dispatch, inviteErrors } = this.props;
    const emailsToInvite = emails.filter((item) => !Object.keys(inviteErrors).includes(item));
    if (isEmpty(emailsToInvite)) {
      return;
    }

    dispatch(inviteAdmins(emailsToInvite, this.stopLoading));
  };

  setEmails = (emails) => {
    this.setState({ emails });
  };

  removeTag = (tag) => {
    const { dispatch } = this.props;
    dispatch(removeInviteError(tag));
  };

  renderModal = (onInvite, onValidate) => this.state.showModal && (
    <Modal>
      <ModalLayout onClose={ this.onCloseModal } title="Invite a new admin">
        <InviteAdmin
          errors={ this.props.inviteErrors }
          removeTag={ this.removeTag }
          setEmails={ this.setEmails }
          emails={ this.state.emails }
          onInvite={ onInvite }
          onValidate={ onValidate }
        />
      </ModalLayout>
    </Modal>
  );

  onOpenDeactivateMember = (member) => {
    this.setState({ showDeactivateModal: true, member });
  };

  onCloseDeactivateModal = () => {
    this.setState({ showDeactivateModal: false, member: undefined });
  };

  onDeactivateMember = () => {
    const { member } = this.state;
    const { dispatch } = this.props;
    dispatch(deactivateMember(member.id, this.stopLoading));

    this.onCloseDeactivateModal();
  };

  renderDeactivateModal = () => this.state.showDeactivateModal && (
    <Modal>
      <ModalLayout onClose={ this.onCloseDeactivateModal } title="Deactivate user">
        <DeactivateMember onDeactivate={ this.onDeactivateMember } onClose={ this.onCloseDeactivateModal } />
      </ModalLayout>
    </Modal>
  );

  onOpenActivateMember = (member) => {
    this.setState({ showActivateModal: true, member });
  };

  onCloseActivateModal = () => {
    this.setState({ showActivateModal: false, member: undefined });
  };

  onActivateMember = () => {
    const { member } = this.state;
    const { dispatch } = this.props;
    dispatch(activateMember(member.id, this.stopLoading));

    this.onCloseActivateModal();
  };

  renderActivateModal = () => this.state.showActivateModal && (
    <Modal>
      <ModalLayout onClose={ this.onCloseActivateModal } title="Activate user">
        <ActivateMember onActivate={ this.onActivateMember } onClose={ this.onCloseActivateModal } />
      </ModalLayout>
    </Modal>
  );

  onEditProfile = (member) => {
    const { history } = this.props;
    history.push(`/admin/members/${ member.id }/profile`);
  };

  onEditRole = (role, member) => {
    const { dispatch } = this.props;
    const updatedMember = {
      ...member,
      role
    };

    dispatch(updateMember(member.id, updatedMember, this.stopLoading));
  };

  validateEmail = (email) => {
    const { dispatch } = this.props;
    dispatch(checkIfEmailExists(email));
  };

  render() {
    const { members, profile } = this.props;
    const { loading } = this.state;

    return (
      <PageLayout title="Member Management">
        <MemberList
          profile={ profile }
          members={ members }
          onSearch={ this.onSearch }
          onSort={ this.onSort }
          onInviteAdmin={ this.onOpenModal }
          onDeactivateMember={ this.onOpenDeactivateMember }
          onActivateMember={ this.onOpenActivateMember }
          onEditProfile={ this.onEditProfile }
          onEditRole={ this.onEditRole }
          loading={ loading }
        />
        { this.renderModal(this.onInviteAdmins, this.validateEmail) }
        { this.renderDeactivateModal() }
        { this.renderActivateModal() }
      </PageLayout>
    );
  }
}

MembersContainer.defaultProps = {
  members: []
};

MembersContainer.propTypes = {
  dispatch: func.isRequired,
  members: arrayOf(MEMBER_PROP_TYPE)
};

const mapStateToProps = ({ member, profile }) => ({
  members: member.members,
  profile: profile.profile,
  inviteErrors: member.inviteErrors
});

export default connect(mapStateToProps)(withAuthentication(MembersContainer));
