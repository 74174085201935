export const UPDATE_NOTIFICATION_SETTINGS = '[NOTIFICATION_STORE] Update Notification Settings';
export const updateNotificationSettings = (notificationSettings, id, settingsId) => ({
  type: UPDATE_NOTIFICATION_SETTINGS,
  notificationSettings,
  id,
  settingsId
});

export const LOAD_NOTIFICATION_SETTINGS = '[NOTIFICATION_STORE] Load notification settings';
export const SET_NOTIFICATION_SETTINGS = '[NOTIFICATION_STORE] Set notification settings';
export const loadNotificationSettings = (id, onSuccess) => ({
  type: LOAD_NOTIFICATION_SETTINGS,
  id,
  onSuccess
});
