import React from 'react';
import { sortItems, tableColumns, tableModifiers } from './post-categories.helpers';
import { arrayOf, func, number, shape, string } from 'prop-types';

import { noOp } from '../../../tools/helpers';

import { ReactComponent as PencilIcon } from '../../../icons/pencil-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../icons/delete-icon.svg';
import Table from '../../table/Table';
import ListAction from '../../members/list-action/ListAction';

import './post-categories.scss';

const renderActions = (item, onDelete) => (item.related_posts ?? 0) <= 0 && (
  <DeleteIcon onClick={ () => onDelete(item) } />
);

const renderName = (item, onEdit) => (
  <div className="post-categories__name">
    <span>{ item.name }</span>
    <PencilIcon onClick={ () => onEdit(item) } />
  </div>
);

const formatRows = (items, onEdit, onDelete) => items
  .map((item) => ({
    ...item,
    name: renderName(item, onEdit),
    actions:
      <div className="post-categories__actions">
        { renderActions(item, onDelete) }
      </div>
  }));

const PostCategories = ({ categories, onClickAction, onEdit, onDelete, loading }) => (
  <div className="post-categories">
    <div className="post-categories__header">
      <ListAction
        buttonLabel="Add new category"
        onActionClick={ onClickAction }
        inputPlaceholder="search categories..."
        itemsToSort={ sortItems }
      />
    </div>
    <Table
      cols={ tableColumns }
      headerModifiers={ tableModifiers }
      columnModifiers={ tableModifiers }
      rowData={ formatRows(categories, onEdit, onDelete) }
      loading={ loading }
    />
  </div>
);

PostCategories.defaultProps = {
  categories: [],
  onClickAction: noOp,
  onEdit: noOp,
  onDelete: noOp
};

PostCategories.propTypes = {
  categories: arrayOf(shape({
    name: string,
    date: string,
    relatedPosts: number
  })),
  onClickAction: func,
  onEdit: func,
  onDelete: func
};

export default PostCategories;
