import axios from 'axios';
import { all, call, debounce, put } from '@redux-saga/core/effects';
import { takeLatest } from 'redux-saga/effects';

import { addSystemNotice } from '../actions/system.actions';
import { loadProfile } from '../actions/profile.actions';
import {
  ACTIVATE_MEMBER,
  CHANGE_PASSWORD,
  LOAD_ACTIVITY,
  LOAD_NOTIFICATIONS,
  LOAD_UNREAD_NOTIFICATION,
  loadNotifications,
  loadUnreadNotifications,
  PROCESS_NOTIFICATION,
  READ_ALL_NOTIFICATION,
  READ_NOTIFICATION,
  SET_ACTIVITIES,
  CHECK_IF_EMAIL_EXISTS,
  DEACTIVATE_MEMBER,
  DELETE_MEMBER,
  INVITE_ADMINS,
  RETRIEVE_MEMBER,
  RETRIEVE_MEMBERS,
  retrieveMember,
  retrieveMembers,
  SET_INVITE_ERRORS,
  SET_MEMBER,
  SET_MEMBER_ERRORS,
  SET_MEMBERS,
  SET_NOTIFICATIONS,
  SET_UNREAD_NOTIFICATION,
  UPDATE_ADMIN,
  UPDATE_MEMBER,
  VERIFY_NEW_EMAIL
} from '../actions/member.actions';

import {
  getActivitiesRequest,
  getActivateMemberRequest,
  getChangePasswordRequest,
  getNotificationsRequest,
  getProcessNotificationRequest,
  getReadAllNotificationRequest,
  getReadNotificationRequest,
  getCheckEmailRequest,
  getDeactivateMemberRequest,
  getInviteAdminRequest,
  getRemoveMemberRequest,
  getRetrieveMemberRequest,
  getUnreadNotificationsRequest,
  getRetrieveMembersRequest,
  getUpdateAdminRequest,
  getUpdateMemberRequest,
  getVerifyNewEmailRequest
} from '../tools/api/members.endpoints';
import { isUndefined, SNACK_CRITICAL, SNACK_SUCCESS } from '../tools/helpers';
import { takeEvery } from 'redux-saga/effects';

export function* performRetrieveMembers({ search, ordering, stopLoading }) {
  try {
    const [endpoint, requestOptions] = getRetrieveMembersRequest(search, ordering);

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_MEMBERS, members: data });

    if (!isUndefined(stopLoading)) {
      yield call(stopLoading);
    }
  } catch ({ response }) {
    yield put(addSystemNotice('We were not able to retrieve members', SNACK_CRITICAL));
  }
}

export function* watchForRetrieveMembersRequest() {
  yield debounce(500, RETRIEVE_MEMBERS, performRetrieveMembers);
}

export function* performRetrieveMember({ id, stopLoading }) {
  try {
    const [endpoint, requestOptions] = getRetrieveMemberRequest(id);

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_MEMBER, member: data });

    if (!isUndefined(stopLoading)) {
      stopLoading();
    }
  } catch ({ response }) {
    yield put(addSystemNotice('We were not able to retrieve the members profile', SNACK_CRITICAL));
  }
}

export function* watchForRetrieveMemberRequest() {
  yield takeLatest(RETRIEVE_MEMBER, performRetrieveMember);
}

export function* performUpdateMember({ id, member, stopLoading }) {
  try {
    const [endpoint, requestOptions] = getUpdateMemberRequest(id, member);

    yield call(axios, endpoint, requestOptions);
    yield put(addSystemNotice('Member profile updated successfully', SNACK_SUCCESS));
    yield put({ type: SET_MEMBER_ERRORS, memberErrors: undefined });

    yield put(retrieveMember(id, stopLoading));
    yield put(retrieveMembers({ stopLoading }));
  } catch ({ response }) {
    yield put({ type: SET_MEMBER_ERRORS, memberErrors: response?.data });
    yield put(addSystemNotice('We were not able to update this member profile', SNACK_CRITICAL));
  }
}

export function* watchForUpdateMemberRequest() {
  yield takeLatest(UPDATE_MEMBER, performUpdateMember);
}

export function* performUpdateAdmin({ id, admin }) {
  try {
    const [endpoint, requestOptions] = getUpdateAdminRequest(id, admin);

    yield call(axios, endpoint, requestOptions);
    yield put(addSystemNotice('Profile updated successfully', SNACK_SUCCESS));
    yield put({ type: SET_MEMBER_ERRORS, memberErrors: undefined });

    yield put(loadProfile());
  } catch ({ response }) {
    yield put({ type: SET_MEMBER_ERRORS, memberErrors: response?.data });
    yield put(addSystemNotice('We were not able to update your profile', SNACK_CRITICAL));
  }
}

export function* watchForUpdateAdminRequest() {
  yield takeLatest(UPDATE_ADMIN, performUpdateAdmin);
}

export function* performVerifyNewEmail({ id, email }) {
  try {
    const [endpoint, requestOptions] = getVerifyNewEmailRequest(id, email);

    yield call(axios, endpoint, requestOptions);
    yield put({ type: SET_MEMBER_ERRORS, memberErrors: undefined });

    yield put(addSystemNotice('Success. An email will be sent to verify your new email address', SNACK_SUCCESS));

    yield put(loadProfile());
  } catch ({ response }) {
    yield put({ type: SET_MEMBER_ERRORS, memberErrors: response?.data });
    yield put(addSystemNotice('We were not able to verify your new email', SNACK_CRITICAL));
  }
}

export function* watchForVerifyNewEmailRequest() {
  yield takeLatest(VERIFY_NEW_EMAIL, performVerifyNewEmail);
}

export function* performChangePassword({ id, current_password, password, password_confirm }) {
  try {
    const [endpoint, requestOptions] = getChangePasswordRequest(id, current_password, password, password_confirm);

    yield call(axios, endpoint, requestOptions);
    yield put({ type: SET_MEMBER_ERRORS, memberErrors: undefined });

    yield put(addSystemNotice('Successfully updated password', SNACK_SUCCESS));

  } catch ({ response }) {
    yield put({ type: SET_MEMBER_ERRORS, memberErrors: response?.data });
    yield put(addSystemNotice('We were not able to update your password', SNACK_CRITICAL));
  }
}

export function* watchForChangePasswordRequest() {
  yield takeLatest(CHANGE_PASSWORD, performChangePassword);
}

export function* performRemoveMember({ id, history }) {
  try {
    const [endpoint, requestOptions] = getRemoveMemberRequest(id);

    yield call(axios, endpoint, requestOptions);
    yield put(addSystemNotice('Member profile deleted successfully', SNACK_SUCCESS));

    yield call(history.push, '/admin/members');
  } catch ({ response }) {
    yield put(addSystemNotice('We were not able to delete this member profile', SNACK_CRITICAL));
  }
}

export function* watchForRemoveMemberRequest() {
  yield takeLatest(DELETE_MEMBER, performRemoveMember);
}

export function* performActivateMember({ id, stopLoading }) {
  try {
    const [endpoint, requestOptions] = getActivateMemberRequest(id);

    yield call(axios, endpoint, requestOptions);
    yield put(addSystemNotice('Member profile activated successfully', SNACK_SUCCESS));

    yield put(retrieveMembers({ stopLoading }));
  } catch ({ response }) {
    yield put(addSystemNotice('We were not able to activate this member profile', SNACK_CRITICAL));
  }
}

export function* watchForActivateMemberRequest() {
  yield takeLatest(ACTIVATE_MEMBER, performActivateMember);
}

export function* performDeactivateMember({ id, stopLoading }) {
  try {
    const [endpoint, requestOptions] = getDeactivateMemberRequest(id);

    yield call(axios, endpoint, requestOptions);
    yield put(addSystemNotice('Member profile deactivated successfully', SNACK_SUCCESS));

    yield put(retrieveMembers({ stopLoading }));
  } catch ({ response }) {
    yield put(addSystemNotice('We were not able to deactivate this member profile', SNACK_CRITICAL));
  }
}

export function* watchForDeactivateMemberRequest() {
  yield takeLatest(DEACTIVATE_MEMBER, performDeactivateMember);
}

export function* performInviteAdmins({ emails, stopLoading }) {
  try {
    for (let email of emails) {
      const [endpoint, requestOptions] = getInviteAdminRequest(email);
      yield call(axios, endpoint, requestOptions);
    }

    yield put(addSystemNotice('The members specified have been invited to the Admin portal.', SNACK_SUCCESS));
    yield put(retrieveMembers({ stopLoading }));
  } catch (error) {
    yield put(addSystemNotice('We were not able to invite the admin"s specified.', SNACK_CRITICAL));
  }
}

export function* watchForInviteAdminsRequest() {
  yield takeLatest(INVITE_ADMINS, performInviteAdmins);
}

export function* performCheckEmailExists({ email }) {
  try {
    const [endpoint, requestOptions] = getCheckEmailRequest(email);
    yield call(axios, endpoint, requestOptions);

    // email exists and is an admin
    yield put({ type: SET_INVITE_ERRORS, email });
  } catch ({ response }) {
    // swallow as the email does not exist, and no error should be stored
  }
}

export function* watchForCheckEmailRequest() {
  yield takeLatest(CHECK_IF_EMAIL_EXISTS, performCheckEmailExists);
}

export function* performLoadActivity({ id }) {
  try {
    const [endpoint, requestOptions] = getActivitiesRequest(id);

    const { data } = yield call(axios, endpoint, requestOptions);
    yield put({ type: SET_ACTIVITIES, activities: data.activities });
  } catch ({ response }) {
    if (response.status !== 401) {
      yield put({ type: SET_ACTIVITIES, activities: [] });
      yield put(addSystemNotice('We were not able to retrieve your activity', SNACK_CRITICAL));
    }
  }
}

export function* watchForLoadActivityRequest() {
  yield takeLatest(LOAD_ACTIVITY, performLoadActivity);
}

export function* performLoadNotifications({ id, nextPage }) {
  try {
    const [endpoint, requestOptions] = getNotificationsRequest(id, nextPage);

    const { data } = yield call(axios, endpoint, requestOptions);

    let next = data.next;
    if (next !== null) {
      next = next.substring(next.indexOf('page=') + 5);
    }

    yield put({
      type: SET_NOTIFICATIONS,
      notifications: data.results,
      count: data.count,
      nextPage: next,
      initial: !nextPage
    });
  } catch ({ response }) {
    if (response.status !== 401) {
      yield put({ type: SET_NOTIFICATIONS, notifications: [] });
      yield put(addSystemNotice('We were not able to retrieve your notification', SNACK_CRITICAL));
    }
  }
}

export function* watchForLoadNotificationsRequest() {
  yield takeLatest(LOAD_NOTIFICATIONS, performLoadNotifications);
}

export function* performReadNotificationRequest({ id, notificationId }) {
  try {
    const [endpoint, requestOptions] = getReadNotificationRequest(id, notificationId);
    yield call(axios, endpoint, requestOptions);

    yield put(loadNotifications(id));
  } catch (error) {
    yield put(
      addSystemNotice('We were not able to mark your notification as read', SNACK_CRITICAL)
    );
  }
}

export function* watchForReadNotificationRequest() {
  yield takeEvery(READ_NOTIFICATION, performReadNotificationRequest);
}

export function* performReadAllNotificationRequest({ id }) {
  try {
    const [endpoint, requestOptions] = getReadAllNotificationRequest(id);
    yield call(axios, endpoint, requestOptions);

    yield put(loadUnreadNotifications(id));
    yield put(loadNotifications(id));
  } catch (error) {
    yield put(
      addSystemNotice('We were not able to mark all your notifications as read', SNACK_CRITICAL)
    );
  }
}

export function* watchForReadAllNotificationRequest() {
  yield takeEvery(READ_ALL_NOTIFICATION, performReadAllNotificationRequest);
}

export function* performProcessNotificationRequest({ id, notificationId }) {
  try {
    const [endpoint, requestOptions] = getProcessNotificationRequest(id, notificationId);
    yield call(axios, endpoint, requestOptions);

    yield put(loadNotifications(id));
  } catch (error) {
    yield put(
      addSystemNotice('We were not able to mark your notification as processed', SNACK_CRITICAL)
    );
  }
}

export function* watchForProcessNotificationRequest() {
  yield takeLatest(PROCESS_NOTIFICATION, performProcessNotificationRequest);
}

export function* performLoadUnreadNotificationsRequest({ id }) {
  try {
    const [endpoint, requestOptions] = getUnreadNotificationsRequest(id);
    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_UNREAD_NOTIFICATION, unreadCount: data.count });
  } catch (error) {
    yield put(
      addSystemNotice('We were not able to load your unread notifications', SNACK_CRITICAL)
    );
  }
}
export function* watchForLoadUnreadNotificationsRequest() {
  yield takeLatest(LOAD_UNREAD_NOTIFICATION, performLoadUnreadNotificationsRequest);
}

export default function* membersSaga() {
  yield all([
    watchForRetrieveMembersRequest(),
    watchForRetrieveMemberRequest(),
    watchForUpdateMemberRequest(),
    watchForRemoveMemberRequest(),
    watchForActivateMemberRequest(),
    watchForDeactivateMemberRequest(),
    watchForInviteAdminsRequest(),
    watchForCheckEmailRequest(),
    watchForUpdateAdminRequest(),
    watchForVerifyNewEmailRequest(),
    watchForLoadNotificationsRequest(),
    watchForReadNotificationRequest(),
    watchForProcessNotificationRequest(),
    watchForLoadUnreadNotificationsRequest(),
    watchForReadAllNotificationRequest(),
    watchForChangePasswordRequest()
  ]);
}
