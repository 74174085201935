import { API_HOST, getHttpDeleteOptions, getHttpGetOptions, getHttpPutOptions } from './index';

import { getAuthHeaders } from '../auth.util';

const getReportsEndpoint = `${ API_HOST }/reports/`;
export const getRetrieveReportedPostsRequest = () => [
  getReportsEndpoint,
  getHttpGetOptions(getAuthHeaders())
];

const getReportEndpoint = (id) => `${ API_HOST }/reports/${ id }/`;
export const getRemoveReportRequest = (id) => [
  getReportEndpoint(id),
  getHttpDeleteOptions(getAuthHeaders())
];

export const getActionReportRequest = (id, report) => [
  getReportEndpoint(id),
  getHttpPutOptions(report, getAuthHeaders())
];
