import React from 'react';

import Button from '../../button/Button';

import './deactivate-member.scss';

const DeactivateMember = ({ onClose, onDeactivate }) => (
  <div className="deactivate-member">
    <div className="deactivate-member__header">
      <p>Are you sure you want to deactivate this user? All their data will be lost</p>
    </div>
    <div className="deactivate-member__action">
      <Button label="Yes, deactivate user" onClick={ onDeactivate } />
      <Button blank label="No, keep user activated" onClick={ onClose } />
    </div>
  </div>
);

export default DeactivateMember;
