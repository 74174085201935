import React from 'react';
import { arrayOf, func, number, shape, string } from 'prop-types';

import { sortItems, tableColumns, tableModifiers } from './tags.helpers';
import { noOp } from '../../../tools/helpers';

import { ReactComponent as PencilIcon } from '../../../icons/pencil-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../icons/delete-icon.svg';
import Table from '../../table/Table';
import ListAction from '../../members/list-action/ListAction';

import './tags.scss';

const renderActions = (item, onDelete) => (item.related_posts ?? 0) <= 0 && (
  <DeleteIcon onClick={ () => onDelete(item) } />
);

const renderName = (item, onEdit) => (
  <div className="tags__name">
    <span>{ item.name }</span>
    <PencilIcon onClick={ () => onEdit(item) } />
  </div>
);

const formatRows = (items, onDelete, onEdit) => items
  .map((item) => ({
    ...item,
    name: renderName(item, onEdit),
    actions:
      <div className="tags__actions">
        { renderActions(item, onDelete) }
      </div>
  }));

const Tags = ({ tags, onClickAction, onDelete, onEdit, loading }) => (
  <div className="tags">
    <div className="tags__header">
      <ListAction
        buttonLabel="Create New Tags"
        inputPlaceholder="search tags..."
        itemsToSort={ sortItems }
        onActionClick={ onClickAction }
      />
    </div>
    <Table
      cols={ tableColumns }
      headerModifiers={ tableModifiers }
      columnModifiers={ tableModifiers }
      rowData={ formatRows(tags, onDelete, onEdit) }
      loading={ loading }
    />
  </div>
);

Tags.defaultProps = {
  tags: [],
  onClickAction: noOp,
  onDelete: noOp,
  onEdit: noOp
};

Tags.propTypes = {
  tags: arrayOf(shape({
    tagName: string,
    formatName: string,
    relatedCategories: string,
    relatedPosts: number
  })),
  onClickAction: func,
  onDelete: func,
  onEdit: func
};

export default Tags;
