import axios from 'axios';
import { all, call, put } from '@redux-saga/core/effects';
import { takeLatest } from 'redux-saga/effects';

import { isUndefined, SNACK_CRITICAL, SNACK_SUCCESS } from '../tools/helpers';
import {
  getCreateCategoryRequest,
  getCreateTagRequest,
  getEditCategoryRequest,
  getEditTagRequest,
  getLoadCategoriesRequest,
  getLoadCuratorsRequest,
  getLoadEmptyCategoriesRequest,
  getLoadPostsRequest,
  getLoadTagsRequest,
  getRemoveCategoryRequest,
  getRemovePostRequest,
  getRemoveTagRequest
} from '../tools/api/post.endpoints';

import { addSystemNotice } from '../actions/system.actions';
import {
  CREATE_CATEGORY,
  CREATE_TAG,
  EDIT_CATEGORY,
  EDIT_TAG,
  LOAD_CATEGORIES,
  LOAD_CURATORS,
  LOAD_EMPTY_CATEGORIES,
  LOAD_POSTS,
  LOAD_TAGS,
  loadCategories,
  loadEmptyCategories,
  loadTags,
  REMOVE_CATEGORY,
  REMOVE_POST,
  REMOVE_TAG,
  SET_CATEGORIES,
  SET_CURATORS,
  SET_EMPTY_CATEGORIES,
  SET_POSTS,
  SET_TAGS
} from '../actions/post.actions';

export function* performLoadPosts({ onSuccess, nextPage }) {
  try {
    const [endpoint, requestOptions] = getLoadPostsRequest(nextPage);
    const { data } = yield call(axios, endpoint, requestOptions);

    let next = data.next;
    if (next !== null) {
      next = next.substring(next.indexOf('=') + 1);
    }

    yield put({ type: SET_POSTS, posts: data.results, count: data.count, nextPage: next, initial: nextPage === null });

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch (error) {
    yield put(addSystemNotice('We were not able to load your post feed.', SNACK_CRITICAL));
  }
}

export function* watchForLoadPostsRequest() {
  yield takeLatest(LOAD_POSTS, performLoadPosts);
}

export function* performLoadTags({ stopLoading }) {
  try {
    const [endpoint, requestOptions] = getLoadTagsRequest();
    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_TAGS, tags: data });

    if (!isUndefined(stopLoading)) {
      stopLoading();
    }
  } catch (error) {
    yield put({ type: SET_TAGS, tags: [] });
    yield put(addSystemNotice('We were not able to load the tags.', SNACK_CRITICAL));
  }
}

export function* watchForLoadTagsRequest() {
  yield takeLatest(LOAD_TAGS, performLoadTags);
}

export function* performLoadCategories({ stopLoading }) {
  try {
    const [endpoint, requestOptions] = getLoadCategoriesRequest();
    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_CATEGORIES, categories: data });

    if (stopLoading) {
      yield call(stopLoading);
    }
  } catch (error) {
    yield put({ type: SET_CATEGORIES, categories: [] });
    yield put(addSystemNotice('We were not able to load the categories.', SNACK_CRITICAL));
  }
}

export function* watchForLoadCategoriesRequest() {
  yield takeLatest(LOAD_CATEGORIES, performLoadCategories);
}

export function* performCreateTag({ tag, stopLoading }) {
  try {
    const [endpoint, requestOptions] = getCreateTagRequest(tag);
    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice(`The ${ tag.name } tag has been created.`, SNACK_SUCCESS));
    yield put(loadTags(stopLoading));
  } catch (error) {
    yield put(addSystemNotice(`We were not able to create the ${ tag.name } tag.`, SNACK_CRITICAL));
  }
}

export function* watchForCreateTagRequest() {
  yield takeLatest(CREATE_TAG, performCreateTag);
}

export function* performCreateCategory({ category, stopLoading }) {
  try {
    const [endpoint, requestOptions] = getCreateCategoryRequest(category);
    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice(`The ${ category.name } category has been created.`, SNACK_SUCCESS));
    yield put(loadCategories(stopLoading));
    yield put(loadEmptyCategories(stopLoading));
  } catch (error) {
    yield put(addSystemNotice(`We were not able to create the ${ category.name } category.`, SNACK_CRITICAL));
  }
}

export function* watchForCreateCategoryRequest() {
  yield takeLatest(CREATE_CATEGORY, performCreateCategory);
}

export function* performEditCategory({ category, stopLoading }) {
  try {
    const [endpoint, requestOptions] = getEditCategoryRequest(category.id, category);
    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice(`The ${ category.name } category has been updated.`, SNACK_SUCCESS));
    yield put(loadCategories(stopLoading));
    yield put(loadEmptyCategories(stopLoading));
  } catch (error) {
    yield put(addSystemNotice(`We were not able to update the ${ category.name } category.`, SNACK_CRITICAL));
  }
}

export function* watchForEditCategoryRequest() {
  yield takeLatest(EDIT_CATEGORY, performEditCategory);
}

export function* performRemoveCategory({ category, stopLoading }) {
  try {
    const [endpoint, requestOptions] = getRemoveCategoryRequest(category.id);
    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice(`The ${ category.name } category has been deleted.`, SNACK_SUCCESS));
    yield put(loadCategories(stopLoading));
    yield put(loadEmptyCategories(stopLoading));
  } catch (error) {
    yield put(addSystemNotice(`We were not able to delete the ${ category.name } category.`, SNACK_CRITICAL));
  }
}

export function* watchForRemoveCategoryRequest() {
  yield takeLatest(REMOVE_CATEGORY, performRemoveCategory);
}

export function* performEditTag({ tag, stopLoading }) {
  try {
    const [endpoint, requestOptions] = getEditTagRequest(tag.id, tag);
    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice(`The ${ tag.name } tag has been updated.`, SNACK_SUCCESS));
    yield put(loadTags(stopLoading));
  } catch (error) {
    yield put(addSystemNotice(`We were not able to update the ${ tag.name } tag.`, SNACK_CRITICAL));
  }
}

export function* watchForEditTagRequest() {
  yield takeLatest(EDIT_TAG, performEditTag);
}

export function* performRemoveTag({ tag, stopLoading }) {
  try {
    const [endpoint, requestOptions] = getRemoveTagRequest(tag.id);
    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice(`The ${ tag.name } tag has been deleted.`, SNACK_SUCCESS));
    yield put(loadTags(stopLoading));
  } catch (error) {
    yield put(addSystemNotice(`We were not able to delete the ${ tag.name } tag.`, SNACK_CRITICAL));
  }
}

export function* watchForRemoveTagRequest() {
  yield takeLatest(REMOVE_TAG, performRemoveTag);
}

export function* performLoadCurators({ onSuccess }) {
  try {
    const [endpoint, requestOptions] = getLoadCuratorsRequest();
    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_CURATORS, curators: data });

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch (error) {
    yield put({ type: SET_CURATORS, curators: [] });
    yield put(addSystemNotice('We were not able to load the curators.', SNACK_CRITICAL));
  }
}

export function* watchForLoadCuratorsRequest() {
  yield takeLatest(LOAD_CURATORS, performLoadCurators);
}

export function* performLoadEmptyCategories({ stopLoading }) {
  try {
    const [endpoint, requestOptions] = getLoadEmptyCategoriesRequest();
    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_EMPTY_CATEGORIES, emptyCategories: data });
    stopLoading();
  } catch (error) {
    yield put({ type: SET_EMPTY_CATEGORIES, emptyCategories: [] });
    yield put(addSystemNotice('We were not able to load the empty categories.', SNACK_CRITICAL));
  }
}

export function* watchForLoadEmptyCategoriesRequest() {
  yield takeLatest(LOAD_EMPTY_CATEGORIES, performLoadEmptyCategories);
}

export function* performRemovePost({ id, onSuccess }) {
  try {
    const [endpoint, requestOptions] = getRemovePostRequest(id);
    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('The post was successfully deleted.', SNACK_SUCCESS));

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch (error) {
    yield put(addSystemNotice('We were not able to delete the post.', SNACK_CRITICAL));
  }
}

export function* watchForRemovePostRequest() {
  yield takeLatest(REMOVE_POST, performRemovePost);
}

export default function* postSaga() {
  yield all([
    watchForLoadPostsRequest(),
    watchForLoadTagsRequest(),
    watchForLoadCategoriesRequest(),
    watchForCreateTagRequest(),
    watchForCreateCategoryRequest(),
    watchForEditCategoryRequest(),
    watchForRemoveCategoryRequest(),
    watchForRemoveTagRequest(),
    watchForEditTagRequest(),
    watchForLoadCuratorsRequest(),
    watchForLoadEmptyCategoriesRequest(),
    watchForRemovePostRequest()
  ]);
}
