/**
 * @module Tabs Component
 * @description
 * A grouped set of menu items, each of which can be selected.
 */
import React from 'react';
import { arrayOf, bool, func, node, number, oneOfType, shape, string } from 'prop-types';

import { getClassNames, isEmpty, noOp } from '../../tools/helpers';

import './tabs.scss';

/**
 * Loops through all `items` and renders them as list items.
 * @param {object[]} items - tab items
 * @param {number} activeItem - index of the active tab item
 * @param {function} onClickItem - function to trigger on click
 */
const renderItems = (items, activeItem, onClickItem) =>
  items.map((item, index) => (
    <li
      key={index}
      className={getClassNames('tabs__tab', {
        active: index === activeItem,
        complete: item.complete
      })}
      onClick={() => onClickItem(index)}
    >
      <p className="tab__label">{item.label}</p>
    </li>
  ));

/** Renders links adjacent to the tabs, if any. */
const renderLinks = (links) => {
  return (
    !isEmpty(links) && (
      <ul className="tabs__link-container">
        {links.map((link, index) => (
          <li key={index} className="tabs__link" onClick={link.onClick}>
            <p className="tab__label typo-small-title">{link.label}</p>
          </li>
        ))}
      </ul>
    )
  );
};

const Tabs = ({
  items,
  links,
  activeItem,
  onClickItem,
  checkbox,
  medium,
  small,
  alt,
  notCentered
}) => {
  const classNames = getClassNames('tabs', {
    checkbox,
    small,
    medium,
    'with-links': !isEmpty(links),
    alt
  });

  return (
    <div className={classNames}>
      <ul className={getClassNames('tabs__container', { 'not-centered': notCentered })}>
        {renderItems(items, activeItem, onClickItem, checkbox)}
      </ul>

      {renderLinks(links)}
    </div>
  );
};

Tabs.defaultProps = {
  items: [],
  checkbox: false,
  small: false,
  links: [],
  onClickItem: noOp,
  notCentered: false,
  activeItem: 0
};

Tabs.propTypes = {
  items: arrayOf(
    shape({
      label: oneOfType([string, node]).isRequired
    })
  ).isRequired,
  activeItem: number,
  onClickItem: func,
  checkbox: bool,
  small: bool,
  links: arrayOf(
    shape({
      label: string,
      onClick: func
    })
  ),
  notCentered: bool
};

export default Tabs;
