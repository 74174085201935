import React, { createRef } from 'react';
import { func } from 'prop-types';

import { noOp } from '../../../tools/helpers';

import FormAction from '../../form/formAction/FormAction';
import Input from '../../input/Input';

import './otp.scss';

const renderFields = (firstFieldRef, secondFieldRef, thirdFieldRef, fourthFieldRef, onChange) => (
  <>
    <Input
      placeholder=""
      large
      name="1"
      maxLength="1"
      inputRef={ firstFieldRef }
      onKeyUpCapture={ onChange }
    />
    <Input
      placeholder=""
      large
      name="2"
      maxLength="1"
      inputRef={ secondFieldRef }
      onKeyUpCapture={ onChange }
    />
    <Input
      placeholder=""
      large
      name="3"
      maxLength="1"
      inputRef={ thirdFieldRef }
      onKeyUpCapture={ onChange }
    />
    <Input
      placeholder=""
      large
      name="4"
      maxLength="1"
      inputRef={ fourthFieldRef }
      onKeyUpCapture={ onChange }
    />
  </>
);

const renderForm = (firstFieldRef, secondFieldRef, thirdFieldRef, fourthFieldRef, onSubmit, onChange, loading) => (
  <>
    <div className="otp__content">
      { renderFields(firstFieldRef, secondFieldRef, thirdFieldRef, fourthFieldRef, onChange) }
    </div>
    <FormAction loading={ loading } label="Continue" onClick={ onSubmit } />
  </>
);

const OTP = ({ onValidateOTP, onResendOtp, loading }) => {
  const firstFieldRef = createRef();
  const secondFieldRef = createRef();
  const thirdFieldRef = createRef();
  const fourthFieldRef = createRef();

  /* istanbul ignore next */
  const onChange = ({ code }) => {
    switch (document.activeElement) {
      case firstFieldRef.current:
        if (code !== 'Backspace') {
          secondFieldRef.current.focus();
        }
        break;
      case secondFieldRef.current:
        if (code === 'Backspace' && secondFieldRef.current.value === '') {
          firstFieldRef.current.value = '';
          firstFieldRef.current.focus();
        } else {
          thirdFieldRef.current.focus();
        }
        break;
      case thirdFieldRef.current:
        if (code === 'Backspace' && thirdFieldRef.current.value === '') {
          secondFieldRef.current.value = '';
          secondFieldRef.current.focus();
        } else {
          fourthFieldRef.current.focus();
        }
        break;
      case fourthFieldRef.current:
        if (code === 'Backspace' && fourthFieldRef.current.value === '') {
          thirdFieldRef.current.focus();
          thirdFieldRef.current.value = '';
        }
        break;
      default:
        return;
    }
  };

  const onSubmit = () => {
    const otp = `${ firstFieldRef.current.value }${ secondFieldRef.current.value }`
      + `${ thirdFieldRef.current.value }${ fourthFieldRef.current.value }`;
    onValidateOTP(otp);
  };

  return (
    <div className="otp">
      { renderForm(firstFieldRef, secondFieldRef, thirdFieldRef, fourthFieldRef, onSubmit, onChange, loading) }
      <div className="otp__reset">
        <p onClick={ onResendOtp }>Resend OTP</p>
      </div>
    </div>
  );
};

OTP.defaultProps = {
  onResendOtp: noOp,
  onValidateOTP: noOp
};

OTP.propTypes = {
  onResendOtp: func,
  onValidateOTP: func
};

export default OTP;
