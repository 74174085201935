export const tableColumns = [
  { Header: '', accessor: 'empty' },
  { Header: 'Category Name', accessor: 'name' },
  { Header: 'Date Created', accessor: 'date_created' },
  { Header: 'Related Posts', accessor: 'related_posts' },
  { Header: '', accessor: 'actions' }
];

export const tableModifiers = {};

export const sortItems = [
  {
    value: 'newest',
    text: 'Sort by Newest First'
  }
];
