import React from 'react';
import { bool, func, instanceOf, number, oneOfType, shape, string } from 'prop-types';

import { getClassNames, noOp } from '../../tools/helpers';

import './information-card.scss';

const renderIcon = (Icon) => Icon && (
  <Icon />
);

const InformationCard = ({ icon, label, numberToDisplay, large, navigate }) => (
  <div className="information-card">
    <div className="information-card__content">
      <h2>{ numberToDisplay }</h2>
      <label>{ label }</label>
      <p onClick={ navigate }>View All</p>
    </div>
    <div className={ getClassNames('information-card__icon', { large }) }>
      { renderIcon(icon) }
    </div>
  </div>
);

InformationCard.defaultProps = {
  icon: undefined,
  label: '',
  numberToDisplay: 0,
  large: false,
  navigate: noOp
};

InformationCard.propTypes = {
  icon: oneOfType([
    func,
    shape({
      current: instanceOf(Element)
    })
  ]),
  label: string,
  numberToDisplay: oneOfType([string, number]),
  large: bool,
  navigate: func
};

export default InformationCard;
