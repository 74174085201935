import React from 'react';
import { connect } from 'react-redux';

import { editCategory, loadEmptyCategories, removeCategory } from '../../../actions/post.actions';

import withAuthentication from '../../auth/withAuthentication';
import PageLayout from '../../../components/layout/page/PageLayout';
import Modal from '../../../components/modal/Modal';
import ModalLayout from '../../../components/modal/ModalLayout';
import EditCategory from '../../../components/admin/categories/EditCategory';
import DeleteCategory from '../../../components/admin/categories/DeleteCategory';
import EmptyPostCategories from '../../../components/admin/post-management/EmptyPostCategories';

class EmptyCategoriesContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showEditCategoryModal: false,
      showDeleteCategoryModal: false,
      selectedCategory: undefined,
      loading: true
    };
  }

  componentDidMount() {
    this.fetchEmptyCategories();
  }

  fetchEmptyCategories() {
    const { dispatch } = this.props;
    dispatch(loadEmptyCategories(this.stopLoading));
  }

  stopLoading = () => {
    this.setState({ loading: false });
  };

  onOpenEditModal = (category) => {
    this.setState({ showEditCategoryModal: true, selectedCategory: category });
  };

  onCloseEditModal = () => {
    this.setState({ showEditCategoryModal: false, selectedCategory: undefined });
  };

  onOpenDeleteModal = (category) => {
    this.setState({ showDeleteCategoryModal: true, selectedCategory: category });
  };

  onCloseDeleteModal = () => {
    this.setState({ showDeleteCategoryModal: false, selectedCategory: undefined });
  };

  onEdit = (category) => {
    this.onCloseEditModal();

    const { dispatch } = this.props;
    dispatch(editCategory(category, this.stopLoading));
  };

  onDelete = () => {
    this.onCloseDeleteModal();

    const { dispatch } = this.props;
    dispatch(removeCategory(this.state.selectedCategory, this.stopLoading));
  };

  renderEditCategoryModal = () => this.state.showEditCategoryModal && (
    <Modal>
      <ModalLayout title="Edit Category Details" onClose={ this.onCloseEditModal }>
        <EditCategory category={ this.state.selectedCategory } editCategory={ this.onEdit } onClose={ this.onCloseEditModal } />
      </ModalLayout>
    </Modal>
  );

  renderDeleteCategoryModal = () => this.state.showDeleteCategoryModal && (
    <Modal>
      <ModalLayout title="Delete Category" onClose={ this.onCloseDeleteModal }>
        <DeleteCategory removeCategory={ this.onDelete } onClose={ this.onCloseDeleteModal } />
      </ModalLayout>
    </Modal>
  );

  render() {
    const { categories } = this.props;
    const { loading } = this.state;

    return (
      <PageLayout title="Empty Categories">
        <EmptyPostCategories
          categories={ categories }
          onEdit={ this.onOpenEditModal }
          onDelete={ this.onOpenDeleteModal }
          loading={ loading }
        />
        { this.renderEditCategoryModal() }
        { this.renderDeleteCategoryModal() }
      </PageLayout>
    );
  }
}

const mapStateToProps = ({ post }) => ({
  categories: post.emptyCategories
});

export default connect(mapStateToProps)(withAuthentication(EmptyCategoriesContainer));
