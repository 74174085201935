import axios from 'axios';
import { all, call, put } from '@redux-saga/core/effects';
import { takeLatest } from 'redux-saga/effects';

import { SNACK_CRITICAL } from '../tools/helpers';
import { getRetrieveProfileRequest } from '../tools/api/profile.endpoints';

import { addSystemNotice } from '../actions/system.actions';
import { LOAD_PROFILE, SET_PROFILE } from '../actions/profile.actions';
import { loadNotifications, loadUnreadNotifications } from '../actions/member.actions';

export function* performLoadProfile() {
  try {
    const [endpoint, requestOptions] = getRetrieveProfileRequest();
    const { data } = yield call(axios, endpoint, requestOptions);
    const { profile } = data;

    yield put({ type: SET_PROFILE, profile: data.profile });
    yield put(loadNotifications(profile.id));
    yield put(loadUnreadNotifications(profile.id));
  } catch (error) {
    yield put(addSystemNotice('We were not able to load your profile.', SNACK_CRITICAL));
  }
}

export function* watchForLoadProfileRequest() {
  yield takeLatest(LOAD_PROFILE, performLoadProfile);
}

export default function* profileSaga() {
  yield all([
    watchForLoadProfileRequest()
  ]);
}
