import React from 'react';
import { arrayOf, bool, func, instanceOf, number, oneOfType, shape, string } from 'prop-types';

import { getClassNames, isUndefined, noOp } from '../../tools/helpers';
import { ReactComponent as IconError } from '../../icons/error-small-icon.svg';

import './select.scss';

const renderLabel = (label) => !isUndefined(label) && (
  <label className="select__label">{ label }</label>
);

/**
 * Django API will return error messages as an array, so we will need to display each of them.
 */
const renderErrors = (errors) => errors && errors.map((error) => (
  <div className="select__error" key={ error }>
    <IconError />
    <small className="select__error-msg" key={ error }>{ error }</small>
  </div>
));

const renderOptions = (items) => items.map((item) => (
  <option key={ item.value } value={ item.value }>{ item.text }</option>
));

const Select = ({
                  name,
                  label,
                  selectRef,
                  placeholder,
                  error,
                  onChange,
                  items,
                  value,
                  shadowless
                }) => (
  <div className={ getClassNames('select', { error, shadowless }) }>
    { renderLabel(label) }
    <div className="select__input-wrapper">
      <select
        onChange={ onChange }
        ref={ selectRef }
        name={ name }
        defaultValue={ value }
        placeholder={ placeholder }
      >
        <option value="">{ placeholder }</option>
        { renderOptions(items) }
      </select>
    </div>
    { renderErrors(error) }
  </div>
);

Select.defaultProps = {
  name: undefined,
  label: undefined,
  selectRef: undefined,
  shadowless: false,
  error: undefined,
  onChange: noOp
};

Select.propTypes = {
  name: string,
  label: string,
  onChange: func,
  shadowless: bool,
  placeholder: string.isRequired,
  error: arrayOf(string),
  items: arrayOf(shape({
    value: oneOfType([string, number]).isRequired,
    text: string.isRequired
  })),
  selectRef: oneOfType([
    func,
    shape({
      current: instanceOf(Element)
    })
  ])
};

export default Select;
