import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { loadCategories, loadCurators, loadPosts, loadTags } from '../../actions/post.actions';
import { noOp } from '../../tools/helpers';

import withAuthentication from '../auth/withAuthentication';
import PageLayout from '../../components/layout/page/PageLayout';
import PostsDashboard from '../../components/admin/post-dashboard/PostsDashboard';

const DashboardContainer = ({ dispatch, categories, tags, curators, posts }) => {
  useEffect(() => {
    dispatch(loadCategories());
    dispatch(loadTags());
    dispatch(loadCurators());
    dispatch(loadPosts(noOp, null));

    return () => {
    };
  }, [dispatch]);

  return (
    <PageLayout title="Post Management">
      <PostsDashboard
        categories={ categories }
        curators={ curators }
        tags={ tags }
        posts={ posts }
      />
    </PageLayout>
  );
};

const mapStateToProps = ({ post }) => ({
  categories: post.categories,
  tags: post.tags,
  curators: post.curators,
  posts: post.postCount
});

export default connect(mapStateToProps)(withAuthentication(DashboardContainer));
