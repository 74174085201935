import { func, oneOf } from 'prop-types';
import React, { useState } from 'react';

import { BAN_STATUSES, getAllBanStatusesForDropdown } from '../../member-moderation.helper';
import { noOp } from '../../../../../tools/helpers';

import Button from '../../../../button/Button';
import Form from '../../../../form/Form';
import FormRow from '../../../../form/formRow/FormRow';
import Select from '../../../../select/Select';

import './edit-moderation-status.scss';

const renderForm = (status, setStatus) => (
  <Form>
    <FormRow>
      <Select placeholder="None"
              label="Ban Status"
              items={ getAllBanStatusesForDropdown() }
              value={ status }
              onChange={ ({ target: { value } }) => setStatus(value) } />
    </FormRow>
  </Form>
);

const EditModerationStatus = ({ currentStatus, onSubmit, onCancel }) => {
  const [updatedStatus, setStatus] = useState(currentStatus);

  return (
    <div className="edit-moderation-status">
      { renderForm(updatedStatus, setStatus) }
      <div className="edit-moderation-status__actions">
        <Button label="Submit" onClick={ () => onSubmit(updatedStatus) } />
        <Button blank label="Cancel" onClick={ onCancel } />
      </div>
    </div>
  );
};

EditModerationStatus.defaultProps = {
  currentStatus: '',
  onSubmit: noOp,
  onCancel: noOp
};

EditModerationStatus.propTypes = {
  currentStatus: oneOf(BAN_STATUSES),
  onSubmit: func,
  onCancel: func
};

export default EditModerationStatus;
