import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as LockIcon } from '../../../icons/small-lock.svg';

import Form from '../../form/Form';
import FormRow from '../../form/formRow/FormRow';
import Input from '../../input/Input';
import FormAction from '../../form/formAction/FormAction';
import Button from '../../button/Button';

import './edit-tag.scss';

const renderForm = (name, setName, slug, onEdit, history) => (
  <Form>
    <FormRow>
      <Input
        placeholder="Tag Name"
        label="Tag Name"
        value={ name }
        onChange={ ({ target: { value } }) => setName(value) }
      />
    </FormRow>
    <div className="edit-tag__form--darken">
      <FormRow>
        <Input
          placeholder="tag-name"
          label="Format Name"
          value={ slug }
          icon={ LockIcon }
          readOnly
        />
      </FormRow>
    </div>
    <div className="edit-tag__action">
      <FormAction label="Save Changes" onClick={ onEdit } />
      <Button blank label="Cancel" onClick={ () => history.goBack() } />
    </div>
  </Form>
);

const EditTag = ({ tag, editTag }) => {
  const [name, setName] = useState(tag?.name);
  const [slug] = useState(tag?.slug);

  const history = useHistory();

  const onEdit = () => {
    editTag({
      ...tag,
      name
    });
  };

  return (
    <div className="edit-tag">
      <div className="edit-tag__form">
        { renderForm(name, setName, slug, onEdit, history) }
      </div>
    </div>
  );
};

export default EditTag;
