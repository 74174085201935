import React from 'react';

import Button from '../button/Button';

import './delete-post.scss';

const DeletePost = ({ removePost, onClose }) => (
  <div className="delete-post">
    <div className="delete-post__header">
      <p>Are you sure you want to delete this post?</p>
      <p>All the associated data will be lost</p>
    </div>
    <div className="delete-post__action">
      <Button label="Yes, Delete Post" onClick={ removePost } />
      <Button blank label="No, keep post" onClick={ onClose } />
    </div>
  </div>
);

export default DeletePost;
