import { func } from 'prop-types';
import React, { useState } from 'react';

import { noOp } from '../../../../tools/helpers';
import { REP_LEVELS } from '../rep.helper';

import Card from '../../../card/Card';
import Modal from '../../../modal/Modal';
import ModalLayout from '../../../modal/ModalLayout';
import ReputationLevelEdit from './edit/ReputationLevelEdit';
import { ReactComponent as IconPencil } from '../../../../icons/reputation/levels/edit/icon-pencil-grey-bg.svg';

import './reputation-levels.scss';

const renderLevel = (level, editLevel) => {
  const { rank, description, icon } = level;

  return (
    <Card key={ rank }>
      <div className="level">
        <div className="level__edit" onClick={ () => editLevel(level) }>
          <IconPencil />
        </div>
        { icon }
      </div>
      <h5 className="rank">{ rank }</h5>
      <p>{ description }</p>
    </Card>
  );
};

const ReputationLevels = ({ updateLevel }) => {
  const [showModal, setModalToShow] = useState(false);
  const [levelToEdit, setLevelToEdit] = useState();

  const onUpdateLevel = (badge) => {
    updateLevel(badge);
    setModalToShow(false);
  };

  const editLevel = (level) => {
    setModalToShow(true);
    setLevelToEdit(level);
  };

  const onCloseModal = () => {
    setModalToShow(false);
    setLevelToEdit(undefined);
  };

  return (
    <>
      <div className="reputation-levels">
        { REP_LEVELS.map(level => renderLevel(level, editLevel)) }
      </div>
      { showModal && <Modal>
        <ModalLayout onClose={ onCloseModal } title="EDIT BADGE">
          <ReputationLevelEdit level={ levelToEdit }
                               saveChanges={ onUpdateLevel }
                               onClose={ onCloseModal } />
        </ModalLayout>
      </Modal> }
    </>
  );
};

ReputationLevels.defaultProps = {
  updateLevel: noOp
};

ReputationLevels.propTypes = {
  updateLevel: func
};

export default ReputationLevels;
