import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';

import { removeSystemNotice } from './actions/system.actions';

import AuthContainer from './containers/auth/Auth.container';
import AdminContainer from './containers/admin/Admin.container';

import UnderConstruction from './components/under-construction/UnderConstruction';
import SnackBar from './components/snack-bar/SnackBar';
import { ReactComponent as UnderConstructionLogo } from './icons/under-construction.svg';

const style = {
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
};

const App = ({ notices, dispatch }) => {
  const handleCloseNotice = (noticeId) => {
    dispatch(removeSystemNotice(noticeId));
  };

  return (
    <div style={ style }>
      <Route path="/" component={ AuthContainer } />
      <Route path="/admin" component={ AdminContainer } />
      <Route path="/terms">
        <UnderConstruction
          title="This page is under construction"
          subtitle="Check back soon!"
          logo={ UnderConstructionLogo }
        />
      </Route>
      <SnackBar notices={ notices } onCloseNotice={ handleCloseNotice } />
    </div>
  );
};

const mapStateToProps = ({ system }) => ({
  notices: system.notices
});

export default connect(mapStateToProps)(App);
