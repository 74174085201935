import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Form from '../../form/Form';
import FormRow from '../../form/formRow/FormRow';
import Input from '../../input/Input';
import TextArea from '../../textarea/TextArea';
import FormAction from '../../form/formAction/FormAction';
import Button from '../../button/Button';

import './edit-category.scss';

const renderForm = (nameRef, descriptionRef, onSave, name, setName, description, setDescription, history) => (
  <Form>
    <FormRow>
      <Input
        label="Category Name"
        placeholder="Category name"
        value={ name }
        onChange={ ({ target: { value } }) => setName(value) }
      />
    </FormRow>
    <FormRow>
      <TextArea
        label="Description"
        placeholder="Enter a description..."
        value={ description }
        onChange={ ({ target: { value } }) => setDescription(value) }
      />
    </FormRow>
    <div className="edit-category__action">
      <FormAction label="Continue" onClick={ onSave } />
      <Button blank label="Cancel" onClick={ () => history.goBack() } />
    </div>
  </Form>
);

const EditCategory = ({ category, editCategory }) => {
  const [name, setName] = useState(category?.name);
  const [description, setDescription] = useState(category?.description);

  const history = useHistory();

  const onSave = () => {
    editCategory({
      ...category,
      name,
      description
    });
  };

  return (
    <div className="edit-category">
      <div className="edit-category__form">
        { renderForm(name, description, onSave, name, setName, description, setDescription, history) }
      </div>
    </div>
  );
};

export default EditCategory;
