import React from 'react';

import { ReactComponent as BlackBelt } from '../../../icons/reputation/levels/black-belt.svg';
import { ReactComponent as BrownBelt } from '../../../icons/reputation/levels/brown-belt.svg';
import { ReactComponent as GreenBelt } from '../../../icons/reputation/levels/green-belt.svg';
import { ReactComponent as MasterBelt } from '../../../icons/reputation/levels/master-belt.svg';
import { ReactComponent as PurpleBelt } from '../../../icons/reputation/levels/purple-belt.svg';
import { ReactComponent as WhiteBelt } from '../../../icons/reputation/levels/white-belt.svg';

export const REP_LEVELS = [{
  rank: 'White Belt',
  description: 'The white belt signifies the beginning of your SmartBiii Journey.',
  icon: <WhiteBelt />,
  percentage: 100,
  minPostCount: 0,
  maxPostCount: 2
}, {
  rank: 'Green Belt',
  description: 'The green belt signifies your growth as a member of the SmartBiii community.',
  icon: <GreenBelt />,
  percentage: 80,
  minPostCount: 2,
  maxPostCount: 5
}, {
  rank: 'Purple Belt',
  description: 'The purple belt represents the start of your transition into a highly regarded member.',
  icon: <PurpleBelt />,
  percentage: 60,
  minPostCount: 5,
  maxPostCount: 10
}, {
  rank: 'Brown Belt',
  description: 'The brown belt signifies  your maturity into a top tier member.',
  icon: <BrownBelt />,
  percentage: 40,
  minPostCount: 10,
  maxPostCount: 15
}, {
  rank: 'Black Belt',
  description: 'The black belt signifies reaching the highest level of members in the community. Representing power and knowledge.',
  icon: <BlackBelt />,
  percentage: 10,
  minPostCount: 15,
  maxPostCount: 20
}, {
  rank: 'Master Belt',
  description: 'The master belt is reserved for the ultimate SmartBiii achiever. This member teaches and leads the members of the ' +
    'community on posting and finding the best deals.',
  icon: <MasterBelt />,
  percentage: 1,
  minPostCount: 20,
  maxPostCount: 100
}];

export const getPointWithSign = (points) => {
  if (points > 0) {
    return ` +${ points }`;
  }

  return ` ${ points }`;
};

export function getDropdownPercentages() {
  const percentages = [];

  for (let i = 1; i <= 100; i++) {
    percentages.push({
      text: `${ i }%`,
      value: i
    });
  }

  return percentages;
}