export const howLongAgo = (date) => {
    const dateObj = new Date(date);
  
    if (!dateObj) {
      return 'Invalid Date';
    }
  
    const now = new Date();
    const millisecondDiffFromNow = now.getTime() - dateObj.getTime();
    const seconds = millisecondDiffFromNow / 1000;
    const minutes = seconds / 60;
  
    if (minutes < 1) {
      const secondRounded = Math.round(seconds);
      const secondOrSeconds = secondRounded === 1 ? 'second' : 'seconds';
  
      return `${secondRounded} ${secondOrSeconds} ago`;
    }
  
    const hours = minutes / 60;
    if (hours < 1) {
      const minutesRounded = Math.round(minutes);
      const minuteOrMinutes = minutesRounded === 1 ? 'minute' : 'minutes';
  
      return `${minutesRounded} ${minuteOrMinutes} ago`;
    }
  
    const days = hours / 24;
    if (days < 1) {
      const hoursRounded = Math.round(hours);
      const hourOrHours = hoursRounded === 1 ? 'hour' : 'hours';
  
      return `${hoursRounded} ${hourOrHours} ago`;
    }
  
    const weeks = days / 7;
    if (weeks < 1) {
      const dayRounded = Math.round(days);
      const dayOrDays = dayRounded === 1 ? 'day' : 'days';
  
      return `${dayRounded} ${dayOrDays} ago`;
    }
  
    // almost 4 weeks per month so 4.345
    const months = weeks / 4.345;
    if (months < 1) {
      const weeksRounded = Math.round(weeks);
      const weekOrWeeks = weeksRounded === 1 ? 'week' : 'weeks';
  
      return `${weeksRounded} ${weekOrWeeks} ago`;
    }
  
    if (months < 12) {
      const monthsRounded = Math.round(months);
      const monthOrMonths = monthsRounded === 1 ? 'month' : 'months';
  
      return `${monthsRounded} ${monthOrMonths} ago`;
    }
  
    const years = Math.floor(months / 12);
    const yearOrYears = years === 1 ? 'year' : 'years';
  
    return `Over ${years} ${yearOrYears} ago`;
  };
  