import React, { useEffect, useState } from 'react';
import { arrayOf, bool, node, number, oneOfType, string } from 'prop-types';
import ToolTip from 'react-portal-tooltip';

import { isLargeScreen, noOp } from '../../tools/helpers';

const Tooltip = ({ children, delay, content, position, parentId, renderForMobile }) => {
  const [active, setActive] = useState(false);
  const [wrapperElement, setWrapperElement] = useState(null);

  let timeout;
  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay);
  };

  const hideTip = () => {
    setActive(false);
    clearInterval(timeout);
  };

  useEffect(() => {
    /**
     * Close the select options if clicked outside of element.
     */
    const handleClickOutside = (event) => {
      if (wrapperElement && !wrapperElement.contains(event.target)) {
        setActive(false);
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  const style = {
    style: {
      textAlign: 'center',
      padding: '8px 16px',
      maxWidth: '250px',
      color: '#757575',
      boxShadow: '0 3px 6px rgba(0, 0, 0, .09)',
      borderRadius: '10px',
      fontSize: '14px',
      zIndex: 10000000
    },
    arrowStyle: {
      borderColor: false,
      color: '#fff'
    }
  };

  return (
    <>
      <div
        className="tooltip__wrapper"
        ref={setWrapperElement}
        onMouseEnter={isLargeScreen() ? showTip : noOp}
        onMouseLeave={isLargeScreen() ? hideTip : noOp}
        onClick={!isLargeScreen() && renderForMobile ? () => setActive(!active) : noOp}
      >
        {children}
      </div>
      <ToolTip parent={parentId} active={active} position={position} style={style}>
        <div className="tooltip__tip">{content}</div>
      </ToolTip>
    </>
  );
};

Tooltip.defaultProps = {
  delay: 300,
  position: 'bottom',
  renderForMobile: true
};

Tooltip.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  delay: number,
  content: string.isRequired,
  parentId: string.isRequired,
  position: string,
  renderForMobile: bool
};

export default Tooltip;
