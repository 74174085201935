import { func, number, shape } from 'prop-types';
import React from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import { noOp } from '../../tools/helpers';
import { tableColumns, tableModifiers } from './post-list.helpers';

import { ReactComponent as DeleteIcon } from '../../icons/delete-icon.svg';

import Table from '../table/Table';
import Loader from '../loader/Loader';

import './post-list.scss';

const renderActions = (item, onDelete) => <DeleteIcon onClick={ () => onDelete(item) } />;

const formatRows = (profile, items, onDelete) => items
  .map((item) => ({
    ...item,
    actions:
      <div className="post-list__actions">
        { renderActions(item, onDelete) }
      </div>
  }));

const PostList = ({ profile, posts, loading, onDelete, count, onLoadMore }) => {
  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: () => posts.length === count,
    onLoadMore: () => onLoadMore()
  });

  return (
    <div className="post-list">
      <Table
        cols={ tableColumns }
        headerModifiers={ tableModifiers }
        columnModifiers={ tableModifiers }
        rowData={ formatRows(profile, posts, onDelete) }
        loading={ loading }
      />
      { !loading && posts.length !== count && <div ref={ sentryRef }><Loader orange /></div> }
    </div>
  );
};

PostList.defaultProps = {
  profile: undefined,
  posts: [],
  onDelete: noOp
};

PostList.propTypes = {
  profile: shape({
    id: number.isRequired
  }),
  onDelete: func
};

export default PostList;
