import React from 'react';

import withAuthentication from '../../auth/withAuthentication';
import PageLayout from '../../../components/layout/page/PageLayout';
import ReportedPosts from '../../../components/admin/reported-posts/ReportedPosts';

const mockReportedPosts = [
  {
    title: 'Title',
    date: '2021/01/01',
    reason: 'My reason',
    name: 'Full Name'
  }, {
    title: 'Title',
    date: '2021/01/01',
    reason: 'My reason',
    name: 'Full Name'
  }, {
    title: 'Title',
    date: '2021/01/01',
    reason: 'My reason',
    name: 'Full Name'
  }, {
    title: 'Title',
    date: '2021/01/01',
    reason: 'My reason',
    name: 'Full Name'
  }, {
    title: 'Title',
    date: '2021/01/01',
    reason: 'My reason',
    name: 'Full Name'
  }, {
    title: 'Title',
    date: '2021/01/01',
    reason: 'My reason',
    name: 'Full Name'
  }, {
    title: 'Title',
    date: '2021/01/01',
    reason: 'My reason',
    name: 'Full Name'
  }, {
    title: 'Title',
    date: '2021/01/01',
    reason: 'My reason',
    name: 'Full Name'
  }, {
    title: 'Title',
    date: '2021/01/01',
    reason: 'My reason',
    name: 'Full Name'
  }, {
    title: 'Title',
    date: '2021/01/01',
    reason: 'My reason',
    name: 'Full Name'
  }
];

const ReportedPostsContainer = () => (
  <PageLayout title="Reported Posts">
    <ReportedPosts reportedPosts={ mockReportedPosts } />
  </PageLayout>
);

export default withAuthentication(ReportedPostsContainer);
