import { func } from 'prop-types';
import React  from 'react';
import { noOp } from '../../../../../tools/helpers';

import Button from '../../../../button/Button';

import './confirm-moderation-status.scss';

const ConfirmModerationStatus = ({ onConfirm, onCancel }) => (
  <div className="confirm-moderation-status">
    <div>
      <label>Are you sure you want to update this user's status?</label>
    </div>
    <label>You can change their account status at any time</label>
    <div className="confirm-moderation-status__actions">
      <Button label="Confirm" onClick={ onConfirm } />
      <Button blank label="Cancel" onClick={ onCancel } />
    </div>
  </div>
);

ConfirmModerationStatus.defaultProps = {
  onConfirm: noOp,
  onCancel: noOp
};

ConfirmModerationStatus.propTypes = {
  onConfirm: func,
  onCancel: func
};

export default ConfirmModerationStatus;
