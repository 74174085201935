import React, { useState } from 'react';
import { arrayOf, bool, func, node, number, oneOfType } from 'prop-types';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';

import { getClassNames, noOp } from '../../tools/helpers';

import { ReactComponent as IconX } from '../../icons/x-icon.svg';
import { ReactComponent as IconXLarge } from '../../icons/x-icon-large.svg';

import './expander-sheet.scss';

const ExpanderSheet = ({
  children,
  onDismiss,
  onOpen,
  onEnd,
  heightRatio,
  blocking,
  scrollLocking,
  transparent,
  snapPoints,
  expandOnContentDrag,
  initialFocusRef
}) => {
  const [openSheet] = useState(true);

  return (
    <BottomSheet
      open={openSheet}
      onDismiss={onDismiss}
      onSpringStart={onOpen}
      onSpringEnd={onEnd}
      expandOnContentDrag={expandOnContentDrag}
      defaultSnap={({ maxHeight }) => maxHeight * heightRatio}
      snapPoints={snapPoints}
      blocking={blocking}
      scrollLocking={scrollLocking}
      initialFocusRef={initialFocusRef}
    >
      <div className="expander-sheet">
        <div
          className={getClassNames('expander-sheet__close', { transparent })}
          onClick={onDismiss}
        >
          {transparent ? <IconXLarge /> : <IconX />}
        </div>
        <div className="expander-sheet__content">{children}</div>
      </div>
    </BottomSheet>
  );
};

ExpanderSheet.defaultProps = {
  onOpen: noOp,
  onEnd: noOp,
  heightRatio: 0.75,
  blocking: true,
  scrollLocking: true,
  expandOnContentDrag: true,
  snapPoints: ({ maxHeight }) => [maxHeight * 0.96, maxHeight * 1, maxHeight * 0.45],
  initialFocusRef: false
};

ExpanderSheet.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  onDismiss: func.isRequired,
  onOpen: func,
  onEnd: func,
  heightRatio: number,
  blocking: bool,
  scrollLocking: bool,
  expandOnContentDrag: bool
};

export default ExpanderSheet;
