import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { resetPassword } from '../../../actions/auth.actions';

import { ReactComponent as AdminLogo } from '../../../icons/admin-logo.svg';

import AuthLayout from '../../../components/layout/auth/AuthLayout';
import ConfirmPassword from '../../../components/auth/confirm-password/ConfirmPassword';

const ConfirmPasswordContainer = ({ dispatch, errors, match: { params: { token } } }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const onConfirmPassword = ({ password, password_confirm }) => {
    setLoading(true);
    dispatch(resetPassword(token, password, password_confirm, history, () => setLoading(false)));
  };

  return (
    <AuthLayout title="Confirm password" subtitle="Please enter your new password" logo={ <AdminLogo /> }>
      <ConfirmPassword errors={ errors } onConfirmPassword={ onConfirmPassword } loading={ loading } />
    </AuthLayout>
  );
};

const mapStateToProps = ({ auth }) => ({
  errors: auth.passwordErrors
});

export default connect(mapStateToProps)(ConfirmPasswordContainer);
