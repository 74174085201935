import React, { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router';
import { connect } from 'react-redux';

import { getRememberMeCookie } from '../../tools/auth.util';
import { REMEMBER_ME } from '../../actions/auth.actions';

import VerifyAccountContainer from './verify-account/VerifyAccount.container';
import CompleteProfileContainer from './complete-profile/CompleteProfile.container';
import LoginContainer from './login/Login.container';
import ForgotPasswordContainer from './forgot-password/ForgotPassword.container';
import OTPContainer from './otp/OTP.container';
import ConfirmPasswordContainer from './confirm-password/ConfirmPassword.container';
import PasswordSuccessContainer from './password-success/PasswordSuccess.container';

const checkRememberMe = (dispatch, history) => {
  const token = getRememberMeCookie();
  if (!token) {
    return;
  }

  dispatch({ type: REMEMBER_ME, token });
  history.push('/admin/dashboard');
};

const AuthContainer = ({ dispatch }) => {
  const history = useHistory();
  useEffect(() => {
    checkRememberMe(dispatch, history);

    return () => {
    };
  }, [dispatch, history]);

  return (
    <Switch>
      <Route exact path="/" component={ LoginContainer } />
      <Route exact path="/verify" component={ VerifyAccountContainer } />
      <Route exact path="/complete-profile" component={ CompleteProfileContainer } />
      <Route exact path="/forgot-password" component={ ForgotPasswordContainer } />
      <Route exact path="/forgot-password/:token/otp" component={ OTPContainer } />
      <Route exact path="/forgot-password/:token/reset" component={ ConfirmPasswordContainer } />
      <Route exact path="/forgot-password/success" component={ PasswordSuccessContainer } />
    </Switch>
  );
};

export default connect(undefined)(AuthContainer);
