import React from 'react';
import { arrayOf, node, oneOfType, string } from 'prop-types';

import './success-layout.scss';

const renderLogo = (logo) => (
  <div className="success-layout__logo">
    { logo }
  </div>
);

const SuccessLayout = ({ title, subtitle, children, logo }) => (
  <div className="success-layout">
    { logo && renderLogo(logo) }
    <div className="success-layout__header">
      <h1 className="success-layout__title">{ title }</h1>
      <p className="success-layout__subtitle">{ subtitle }</p>
    </div>
    <div className="success-layout__content">
      { children }
    </div>
  </div>
);

SuccessLayout.defaultProps = {
  subtitle: '',
  logo: undefined
};

SuccessLayout.propTypes = {
  title: string.isRequired,
  subtitle: string,
  children: oneOfType([arrayOf(node), node]).isRequired,
  logo: node
};

export default SuccessLayout;
