import { combineReducers } from 'redux';

import { authReducer } from './auth.reducer';
import { systemReducer } from './system.reducer';
import { memberReducer } from './member.reducer';
import { profileReducer } from './profile.reducer';
import { postReducer } from './post.reducer';
import { reputationReducer } from './reputation.reducer';
import { moderationReducer } from './moderation.reducer';

const rootReducer = combineReducers({
  auth: authReducer,
  member: memberReducer,
  profile: profileReducer,
  system: systemReducer,
  post: postReducer,
  reputation: reputationReducer,
  moderation: moderationReducer
});

export default rootReducer;
