import React, { Component } from 'react';
import { connect } from 'react-redux';
import { func } from 'prop-types';

import { loadPosts, removePost } from '../../actions/post.actions';
import withAuthentication from '../auth/withAuthentication';

import PageLayout from '../../components/layout/page/PageLayout';
import PostList from '../../components/posts/PostList';
import Modal from '../../components/modal/Modal';
import ModalLayout from '../../components/modal/ModalLayout';
import DeletePost from '../../components/posts/DeletePost';

class PostsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      showDelete: false,
      post: undefined
    };
  }

  componentDidMount() {
    this.findPosts();
  }

  stopLoading = () => {
    this.setState({ loading: false });
  };

  findPosts() {
    const { dispatch } = this.props;
    dispatch(loadPosts(this.stopLoading, null));
  }

  onDelete = (post) => {
    this.setState({ showDelete: true, post });
  };

  onCloseDelete = () => {
    this.setState({ showDelete: false, post: undefined });
  };

  onSuccess = () => {
    this.onCloseDelete();
    this.findPosts();
  };

  deletePost = () => {
    const { post } = this.state;
    const { dispatch } = this.props;
    dispatch(removePost(post.id, this.onSuccess));
  };

  renderDeletePost = (showDelete) => showDelete && (
    <Modal>
      <ModalLayout title="Delete Post" onClose={ this.onCloseDelete }>
        <DeletePost removePost={ this.deletePost } onClose={ this.onCloseDelete } />
      </ModalLayout>
    </Modal>
  );

  loadMore = () => {
    const { dispatch, nextPage } = this.props;
    if (nextPage !== null) {
      dispatch(loadPosts(this.stopLoading, nextPage));
    }
  };

  render() {
    const { posts, profile, count } = this.props;
    const { loading, showDelete, post } = this.state;

    return (
      <PageLayout title="Post Management">
        <label style={{ justifyContent: 'flex-start', marginBottom: 10, width: '100%' }}>Displaying { count } posts</label>
        <PostList
          profile={ profile }
          posts={ posts }
          onDelete={ this.onDelete }
          loading={ loading }
          count={ count }
          onLoadMore={ this.loadMore }
        />
        { this.renderDeletePost(showDelete, post) }
      </PageLayout>
    );
  }
}

PostsContainer.defaultProps = {
  posts: []
};

PostsContainer.propTypes = {
  dispatch: func.isRequired
};

const mapStateToProps = ({ post, profile }) => ({
  posts: post.posts,
  profile: profile.profile,
  count: post.postCount,
  nextPage: post.nextPage
});

export default connect(mapStateToProps)(withAuthentication(PostsContainer));
