import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { validateEmail } from '../../../actions/auth.actions';

import { ReactComponent as AdminLogo } from '../../../icons/admin-logo.svg';

import AuthLayout from '../../../components/layout/auth/AuthLayout';
import ForgotPassword from '../../../components/auth/forgot-password/ForgotPassword';

const ForgotPasswordContainer = ({ dispatch, errors }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const onValidateEmail = (email) => {
    setLoading(true);
    dispatch(validateEmail(email, history, () => setLoading(false)));
  };

  return (
    <AuthLayout
      title="Forgot password"
      subtitle="Please enter your email and we will send you an OTP to return to your account"
      logo={ <AdminLogo /> }
    >
      <ForgotPassword onValidateEmail={ onValidateEmail } errors={ errors } loading={ loading } />
    </AuthLayout>
  );
};

const mapStateToProps = ({ auth }) => ({
  errors: auth.loginErrors
});

export default connect(mapStateToProps)(ForgotPasswordContainer);
