import { func } from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { MEMBER_MOD_HEADERS } from './member-moderation.helper';
import { REPORTED_POST_PROP_TYPES } from '../../../tools/prop.types';
import { noOp } from '../../../tools/helpers';

import AcceptRejectModeration from './action/AcceptRejectModeration';
import Button from '../../button/Button';
import CommentModerationDetails from './comment/CommentModerationDetails';
import Modal from '../../modal/Modal';
import ModalLayout from '../../modal/ModalLayout';
import PostModerationDetails from './post/PostModerationDetails';
import Table from '../../table/Table';
import { ReactComponent as IconEyeOpen } from '../../../icons/moderation/icon-eye-full.svg';

import './member-moderation-dashboard.scss';

const renderCurator = (item, viewDetails) => {
  const { post, comment, type } = item;

  const firstName = type === 'Post' ? post?.member?.first_name : comment.member?.first_name;
  const lastName = type === 'Post' ? post?.member?.last_name : comment.member?.last_name;

  return (
    <div className="member-moderation__table-curator">
      <span>{ `${ firstName } ${ lastName }` }</span>
      <IconEyeOpen onClick={ () => viewDetails(item) } />
    </div>
  );
};

const formatRows = (items, viewDetails) => items.map((item) => ({
  ...item,
  curator: renderCurator(item, viewDetails),
  dateReported: new Date(item.created_at).toLocaleDateString(),
  reporter: `${ item.reporter.first_name } ${ item.reporter.last_name }`
}));

const renderTable = (items, viewDetails) => <Table cols={ MEMBER_MOD_HEADERS }
                                                   rowData={ formatRows(items, viewDetails) }
                                                   pageLength={ 8 } />;

const renderDetailModal = (item, onAcceptOrReject, onCloseModal) => {
  if (item.type === 'Comment') {
    return (
      <Modal>
        <ModalLayout onClose={ onCloseModal } title="REPORT DETAILS">
          <CommentModerationDetails item={ item } onAcceptOrReject={ onAcceptOrReject } />
        </ModalLayout>
      </Modal>
    );
  }

  return (
    <Modal>
      <ModalLayout onClose={ onCloseModal } title="REPORT DETAILS" large>
        <PostModerationDetails item={ item } onAcceptOrReject={ onAcceptOrReject } />
      </ModalLayout>
    </Modal>
  );
};

const renderAcceptRejectModal = (action, acceptOrReject, onSubmitAcceptOrReject, onCloseModal) => (
  <Modal>
    <ModalLayout onClose={ onCloseModal } title={ `${ acceptOrReject.toUpperCase() } MODERATION` }>
      <AcceptRejectModeration acceptOrReject={ acceptOrReject }
                              action={ action }
                              onAcceptOrReject={ onSubmitAcceptOrReject }
                              onCancel={ onCloseModal } />
    </ModalLayout>
  </Modal>
);

const MemberModerationDashboard = ({ items, submitModerationAction }) => {
  const history = useHistory();
  const [showDetailModal, setDetailModalToShow] = useState(false);
  const [showAcceptOrRejectModal, setAcceptOrRejectModalToShow] = useState(false);
  const [acceptOrReject, setAcceptOrReject] = useState(undefined);
  const [itemToHandle, setItemToHandle] = useState();

  const navTo = (path) => {
    history.push(path);
  };

  const onAcceptOrReject = (acceptOrReject) => {
    setAcceptOrRejectModalToShow(true);
    setDetailModalToShow(false);
    setAcceptOrReject(acceptOrReject);
  };

  const onSubmit = ({ action, reason, state }) => {
    submitModerationAction({ action, reason, state, item: itemToHandle });
    onCloseModal();
  };

  const viewDetails = (item) => {
    setDetailModalToShow(true);
    setItemToHandle(item);
  };

  const onCloseModal = () => {
    setDetailModalToShow(false);
    setAcceptOrRejectModalToShow(false);
    setAcceptOrReject(undefined);
    setItemToHandle(undefined);
  };

  return (
    <div className="member-moderation">
      <div className="member-moderation__actions">
        <Button label="View Moderated Users" onClick={ () => navTo('moderation/moderated') } />
      </div>
      <div className="member-moderation__table">
        { renderTable(items, viewDetails) }
      </div>
      { showDetailModal && renderDetailModal(itemToHandle, onAcceptOrReject, onCloseModal) }
      { showAcceptOrRejectModal && renderAcceptRejectModal(itemToHandle.action, acceptOrReject, onSubmit, onCloseModal) }
    </div>
  );
};

MemberModerationDashboard.defaultProps = {
  items: [],
  submitModerationAction: noOp
};

MemberModerationDashboard.propTypes = {
  items: REPORTED_POST_PROP_TYPES,
  submitModerationAction: func
};

export default MemberModerationDashboard;
