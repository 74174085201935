import { func, node, number, shape, string } from 'prop-types';
import React, { useState } from 'react';

import { noOp } from '../../../../../tools/helpers';

import Button from '../../../../button/Button';
import Form from '../../../../form/Form';
import FormRow from '../../../../form/formRow/FormRow';
import Input from '../../../../input/Input';

import './reputation-badge-edit.scss';

const ReputationBadgeForm = ({ name, description, points, icon }, setPoints, onSave, onCancel, updatedValue) => (
  <div className="rep-badge-form">
    <Form>
      <FormRow>
        <Input label="Badge Name"
               placeholder="Name"
               value={ name }
               disabled
               leftLabel />
      </FormRow>
      <FormRow>
        <Input label="Badge Description"
               placeholder="Description"
               value={ description }
               disabled
               leftLabel />
      </FormRow>
      <FormRow>
        <Input label="Points"
               placeholder="points"
               type="number"
               value={ updatedValue }
               onChange={ ({ target: { value } }) => setPoints(value) }
               leftLabel />
      </FormRow>
      <FormRow>
        <Button label="Save Changes" onClick={ onSave } />
      </FormRow>
      <FormRow>
        <Button label="Cancel" onClick={ onCancel } blank />
      </FormRow>
    </Form>
  </div>
);

const ReputationBadgeEdit = ({ badge, saveChanges, onCancel }) => {
  const [points, setPoints] = useState(badge.points);

  const onSave = () => {
    saveChanges({
      ...badge,
      points
    });
  };

  return (
    <div className="rep-badge-edit">
      <div className="rep-badge-edit__icon">
        { badge.icon }
      </div>
      { ReputationBadgeForm(badge, setPoints, onSave, onCancel, points) }
    </div>
  );
};

ReputationBadgeEdit.defaultProps = {
  badge: {},
  saveChanges: noOp,
  onCancel: noOp
};

ReputationBadgeEdit.propTypes = {
  badge: shape({
    name: string.isRequired,
    description: string.isRequired,
    points: number.isRequired,
    icon: node.isRequired
  }),
  saveChanges: func,
  onCancel: func
};

export default ReputationBadgeEdit;
