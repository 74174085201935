import axios from 'axios';
import { all, call, put } from '@redux-saga/core/effects';
import { takeLatest } from 'redux-saga/effects';

import { SNACK_CRITICAL } from '../tools/helpers';
import { getRetrieveMatrixRequest, getUpdateActionRequest } from '../tools/api/reputation.endpoints';

import { addSystemNotice } from '../actions/system.actions';
import { LOAD_MATRIX, SET_MATRIX, UPDATE_ACTION } from '../actions/reputation.actions';

export function* performLoadMatrix({ onSuccess }) {
  try {
    const [endpoint, requestOptions] = getRetrieveMatrixRequest();
    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_MATRIX, matrix: data });

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch (error) {
    yield put(addSystemNotice('We were not able to load the reputation matrix.', SNACK_CRITICAL));
  }
}

export function* watchForLoadMatrixRequest() {
  yield takeLatest(LOAD_MATRIX, performLoadMatrix);
}

export function* performUpdateAction({ id, action, onSuccess }) {
  try {
    const [endpoint, requestOptions] = getUpdateActionRequest(id, action);
    yield call(axios, endpoint, requestOptions);

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch (error) {
    yield put(addSystemNotice('We were not able to update the reputation matrix action.', SNACK_CRITICAL));
  }
}

export function* watchForUpdateActionRequest() {
  yield takeLatest(UPDATE_ACTION, performUpdateAction);
}

export default function* reputationSaga() {
  yield all([
    watchForLoadMatrixRequest(),
    watchForUpdateActionRequest()
  ]);
}
