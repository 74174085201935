import React, { useMemo } from 'react';
import { usePagination, useSortBy, useTable } from 'react-table';
import { arrayOf, bool, node, number, oneOfType, shape, string } from 'prop-types';

import { getClassNames, isEmpty } from '../../tools/helpers';

import { ReactComponent as NoDataIcon } from './../../icons/no-data-icon.svg';
import PaginationControls from '../../components/pagination/PaginationControls';
import Loader from '../loader/Loader';

import './table.scss';

/**
 * Renders the header row, applying react-table properties to `tr` and `th`.
 * @param {Object[]} headerGroups - Header row, containing header column properties
 * @param {Object} headerModifiers - Object with keys being the column id,
 *  containing class modifiers for the header column.
 */
const renderHeaders = (headerGroups, headerModifiers) => headerGroups.map((headerGroup) => (
  <tr { ...headerGroup.getHeaderGroupProps() }>
    { headerGroup.headers.map((column) => (
      <th
        { ...column.getHeaderProps() }
        className={ getClassNames('table-heading', headerModifiers[column.id]) }
      >
        { column.render('Header') }
      </th>
    )) }
  </tr>
));

/**
 * Renders each data row, and their `td` elements, applying react-table properties.
 * @param {Object[]} page - A collection of data rows, contained within the current page
 * @param {function} prepareRow - react-table provided function to execute on a row before display
 * @param {Object} columnModifiers - Object with keys being the column id,
 *  containing class modifiers for a column of data.
 */
const renderRows = (page, prepareRow, columnModifiers, cols) =>
  page.map((row) => {
    prepareRow(row);

    return (
      <tr className="table-row" { ...row.getRowProps() }>
        { row.cells.map((cell) => (
          <td
            { ...cell.getCellProps() }
            className={ getClassNames('table-cell', columnModifiers[cell.column.id]) }
          >
            { cell.render('Cell') }
          </td>
        )) }
      </tr>
    );
  });

/** Only render pagination controls if there is more than one page. */
const renderPaginationControls = (canPreviousPage,
                                  canNextPage,
                                  pageCount,
                                  gotoPage, nextPage,
                                  previousPage,
                                  pageIndex,
                                  pageSize) => pageCount > 1 && (
  <div className="table__pagination">
    <PaginationControls
      pageIndex={ pageIndex }
      pageSize={ pageSize }
      pageCount={ pageCount }
      canPreviousPage={ canPreviousPage }
      previousPage={ previousPage }
      canNextPage={ canNextPage }
      nextPage={ nextPage }
      gotoPage={ gotoPage }
    />
  </div>
);

const Table = ({ cols, rowData, headerModifiers, columnModifiers, pageLength, loading }) => {
  // react-table requires us to use memoization on the columns and data to gain speed optimisations
  const columns = useMemo(() => cols, [cols]);
  const data = useMemo(() => rowData, [rowData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageSize,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: pageLength }
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <table { ...getTableProps() } className="table">
        <thead className="table__header">
        { renderHeaders(headerGroups, headerModifiers) }
        </thead>
        <tbody { ...getTableBodyProps() } className="table__body">
        { !loading && renderRows(page, prepareRow, columnModifiers, cols) }
        </tbody>
      </table>
      { loading && <div className="table-row--loading">
        <Loader orange />
      </div> }
      { isEmpty(page) && !loading && (
        <div className="table-row--empty">
          <NoDataIcon />
          <label>No data found</label>
        </div>
      ) }
      { renderPaginationControls(canPreviousPage, canNextPage, pageCount, gotoPage, nextPage, previousPage, pageIndex, pageSize) }
    </>
  );
};

Table.defaultProps = {
  rowData: [],
  headerModifiers: {},
  columnModifiers: {},
  pageLength: 1000,
  loading: false
};

Table.propTypes = {
  cols: arrayOf(shape({
    Header: oneOfType([string, node]).isRequired,
    accessor: string.isRequired
  })).isRequired,
  rowData: arrayOf(shape({})),
  headerModifiers: shape({}),
  columnModifiers: shape({}),
  pageLength: number,
  loading: bool
};

export default Table;
