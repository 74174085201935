export const getNotificationSettings = (notificationsSettings) => {
    return [
      {
        title: 'Administrative Notifications',
        settings: [
          {
            label: 'Smartbiii Communication',
            description: 'Get news, announcements, and product updates',
            checked: notificationsSettings?.communication,
            field: 'communication'
          },
          {
            label: 'Account Activity',
            description: 'Get notifications when you have not been active for a while',
            checked: notificationsSettings?.account_activity,
            field: 'account_activity'
          },
          {
            label: 'Danger',
            description: 'Get notifications when there is a warning on your account',
            checked: notificationsSettings?.danger,
            field: 'danger'
          }
        ]
      },
      {
        title: 'Posts Notifications',
        settings: [
          {
            label: 'Liked User',
            description: 'Get notifications when a user likes your post',
            checked: notificationsSettings?.liked_user,
            field: 'liked_user'
          },
          {
            label: 'Sharing is caring',
            description: 'Get notifications when you have not been active for a while',
            checked: notificationsSettings?.sharing,
            field: 'sharing'
          },
          {
            label: 'Cared For',
            description: 'Get notifications when a user shares another post with you',
            checked: notificationsSettings?.cared_for,
            field: 'cared_for'
          },
          {
            label: 'Listed',
            description: 'Get notifications when a user adds one of your posts to their list',
            checked: notificationsSettings?.listed,
            field: 'listed'
          },
          {
            label: 'Join the convo',
            description: 'Get notifications when a user comments on your post',
            checked: notificationsSettings?.comments,
            field: 'comments'
          },
          {
            label: 'Keep it going',
            description: 'Get notifications when a user replies to your post comment or reply',
            checked: notificationsSettings?.comment_replies,
            field: 'comment_replies'
          },
          {
            label: 'First to know',
            description: 'Get notifications when a user you are connected with creates a new post',
            checked: notificationsSettings?.new_post,
            field: 'new_post'
          },
          {
            label: 'Naughty, Naughty!',
            description: 'Get notifications when a user reports your post',
            checked: notificationsSettings?.reported_post,
            field: 'reported_post'
          }
        ]
      },
      {
        title: 'Connections Notifications',
        settings: [
          {
            label: 'Connections Activity',
            description: 'Get notifications when you receive a connection request',
            checked: notificationsSettings?.connection_request,
            field: 'connection_request'
          },
          {
            label: 'Accepted',
            description: 'Get notifications when a user accepts your connection request',
            checked: notificationsSettings?.accepted_connection,
            field: 'accepted_connection'
          },
          {
            label: 'Pied Piper',
            description: 'Get notifications when a user starts following you',
            checked: notificationsSettings?.new_follower,
            field: 'new_follower'
          },
          {
            label: 'Boo!',
            description: 'Get notifications when a user unfollows you',
            checked: notificationsSettings?.new_unfollow,
            field: 'new_unfollow'
          }
        ]
      },
      {
        title: 'Lists Notifications',
        settings: [
          {
            label: 'List Activity',
            description: 'Get notifications when you are invited to collaborate on a list',
            checked: notificationsSettings?.new_list,
            field: 'new_list'
          },
          {
            label: 'Up To Date',
            description:
              'Get notifications when one of your list collaborators has changed your list or list item',
            checked: notificationsSettings?.updated_list,
            field: 'updated_list'
          },
          {
            label: 'Join The Convo',
            description:
              'Get notifications when one of your list collaborators has commented on your list or list item',
            checked: notificationsSettings?.list_comments,
            field: 'list_comments'
          },
          {
            label: 'Relatable',
            description: 'Get notifications when a related post for your list items is found',
            checked: notificationsSettings?.related_posts,
            field: 'related_posts'
          }
          // TODO: Re-implement once notifications are active
          // {
          //   label: 'Out Shopping!',
          //   description: 'Get notifications when you are going shopping',
          //   checked: notificationsSettings?.going_shopping,
          //   field: 'going_shopping'
          // },
          // {
          //   label: 'Done Shopping!',
          //   description: 'Get notifications when a user unfollows you',
          //   checked: notificationsSettings?.done_shopping,
          //   field: 'done_shopping'
          // }
        ]
      },
      {
        title: 'Reputation Notifications',
        settings: [
          {
            label: 'Top Achiever',
            description: 'Get notifications when you achieve a new level ',
            checked: notificationsSettings?.new_level,
            field: 'new_level'
          },
          {
            label: 'Show us your badge',
            description: 'Get notifications when you receive a new badge',
            checked: notificationsSettings?.new_badge,
            field: 'new_badge'
          },
          {
            label: 'Almost There',
            description: 'Get notifications about are close to reaching a new level',
            checked: notificationsSettings?.achieving_level,
            field: 'achieving_level'
          },
          {
            label: 'Send your wishes',
            description:
              'Get notifications when one of your connections achieves a new level or receives a badge',
            checked: notificationsSettings?.connection_new_level,
            field: 'connection_new_level'
          }
        ]
      }
    ];
  };
  