export const tableColumns = [
  { Header: '', accessor: 'avatar' },
  { Header: 'Full Name', accessor: 'fullName' },
  { Header: 'Email Address', accessor: 'email' },
  { Header: 'User Role', accessor: 'role' },
  { Header: 'Status', accessor: 'status' },
  { Header: '', accessor: 'actions' }
];

export const tableModifiers = {
  avatar: {
    centered: true,
    thin: true
  },
  actions: {
    centered: true
  }
};

export const roleItems = [
  {
    value: 0,
    text: 'Admin'
  },
  {
    value: 1,
    text: 'Member'
  }
];

export const sortItems = [
  {
    value: 'first_name',
    text: 'Sort by First Name'
  },
  {
    value: 'last_name',
    text: 'Sort by Last Name'
  },
  {
    value: 'email',
    text: 'Sort by Email Address'
  }
];
