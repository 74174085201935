import React from 'react';
import { useHistory } from 'react-router-dom';

import { MODERATED_MEMBER_HEADERS } from '../member-moderation.helper';
import { MODERATED_MEMBER_PROP_TYPES } from '../../../../tools/prop.types';

import Table from '../../../table/Table';
import { ReactComponent as IconEyeOpen } from '../../../../icons/moderation/icon-eye-full.svg';

import './moderated-members.scss';

const renderUserName = (item, navToDetails) => (
  <div className="moderated-members__table-username">
    <span>{ item.userName }</span>
    { item.actionTaken !== 'Permanent Ban' && <IconEyeOpen onClick={ () => navToDetails(item.id) } /> }
  </div>
);

const formatRows = (items, viewDetails) => items.map((item) => ({
  ...item,
  userName: renderUserName(item, viewDetails),
  dateOfAction: item.dateOfAction.toLocaleDateString()
}));

const renderTable = (items, viewDetails) => <Table cols={ MODERATED_MEMBER_HEADERS }
                                                   rowData={ formatRows(items, viewDetails) }
                                                   pageLength={ 8 } />;

const ModeratedMembers = ({ items }) => {
  const history = useHistory();

  const navToDetails = (id) => {
    history.push(`moderated/${ id }`);
  };

  return (
    <div className="moderated-members">
      <div className="moderated-members__table">
        { renderTable(items, navToDetails) }
      </div>
    </div>
  );
};

ModeratedMembers.defaultProps = {
  items: []
};

ModeratedMembers.propTypes = {
  items: MODERATED_MEMBER_PROP_TYPES
};

export default ModeratedMembers;
