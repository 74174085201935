import React from 'react';

import { ReactComponent as HomeIcon } from '../../icons/icon-home.svg';
import { ReactComponent as UsersIcon } from '../../icons/people-icon.svg';
import { ReactComponent as WindowIcon } from '../../icons/windows-icon.svg';
import { ReactComponent as TagIcon } from '../../icons/tag-icon.svg';
import { ReactComponent as IconGavel } from '../../icons/sidebar/icon-gavel.svg';
import { ReactComponent as BoxIcon } from '../../icons/hollow-box-icon.svg';

export const sidebarItems = [
  {
    path: '/admin/dashboard',
    title: 'Dashboard',
    icon: <HomeIcon />
  },
  {
    path: '/admin/posts',
    title: 'Posts',
    icon: <BoxIcon />
  },
  {
    path: '/admin/categories',
    title: 'Categories',
    icon: <WindowIcon />
  },
  {
    path: '/admin/members',
    title: 'Members',
    icon: <UsersIcon />
  },
  {
    path: '/admin/tags',
    title: 'Tags',
    icon: <TagIcon />
  },
  // TODO: Replace commented options once these are in scope and defined.
  // {
  //   path: '/admin/reports',
  //   title: 'Reports',
  //   icon: <BoxIcon />
  // },
  // {
  //   path: '/admin/reputation',
  //   title: 'Reputation',
  //   icon: <IconMedal />
  // },
  {
    path: '/admin/moderation',
    title: 'Moderation',
    icon: <IconGavel />
  }
  // {
  //   path: '/admin/settings',
  //   title: 'Settings',
  //   icon: <SettingsIcon />
  // }
];
