export const tableColumns = [
  { Header: '', accessor: 'avatar' },
  { Header: 'Title', accessor: 'title' },
  { Header: 'Status', accessor: (row) => row.status === 0 ? 'Draft' : 'Published' },
  { Header: 'Current Price', accessor: (row) => `R${row.discount_price}` },
  { Header: 'User', accessor: (row) => `${row.member.first_name} ${row.member.last_name}` },
  { Header: '', accessor: 'actions' }
];

export const tableModifiers = {
  actions: {
    centered: true
  }
};
