import React from 'react';
import { arrayOf, bool, func, shape } from 'prop-types';

import { noOp } from '../../../../tools/helpers';

import Loader from '../../../loader/Loader';
import ToggleSwitch from '../../../toggle-switch/ToggleSwitch';

import './notifications-settings.scss';

const NotificationsSettings = ({ sections, onToggleAll, loading }) => {
  if (loading) {
    return <Loader orange />;
  }

  return (
    <div className="notifications-settings">
      {sections
        // TODO: remove when reputation is completed
        .filter((section) => section.title !== 'Reputation Notifications')
        .map((section) => (
          <div className="notifications-settings__section" key={section.title}>
            <div className="notifications-settings__section-header">
              <h5>{section.title}:</h5>
              <ToggleSwitch
                onChange={() => onToggleAll(section.title)}
                checked={section.settings.every((setting) => setting.checked)}
              />
            </div>
          </div>
        ))}
    </div>
  );
};

NotificationsSettings.defaultProps = {
  sections: [],
  onChange: noOp,
  onToggleAll: noOp,
  loading: false
};

NotificationsSettings.propTypes = {
  sections: arrayOf(shape({})),
  onChange: func,
  onToggleAll: func,
  loading: bool
};

export default NotificationsSettings;
