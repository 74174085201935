import { arrayOf, number, shape, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import withAuthentication from '../../auth/withAuthentication';
import { loadMatrix, updateAction } from '../../../actions/reputation.actions';

import PageLayout from '../../../components/layout/page/PageLayout';
import ReputationPointsMatrix from '../../../components/members/reputation/points-matrix/ReputationPointsMatrix';
import Loader from '../../../components/loader/Loader';
import { addSystemNotice } from '../../../actions/system.actions';
import { SNACK_SUCCESS } from '../../../tools/helpers';

const ReputationPointsMatrixContainer = ({ matrix, dispatch }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
      dispatch(loadMatrix(() => setLoading(false)));

      return () => {
      };
    },
    [dispatch]
  );

  const onSave = (matrix) => {
    const actions = matrix.map((group) => group.actions);
    const mappedActions = [].concat.apply([], actions);

    mappedActions.forEach((action, index) => {
      const onSuccess = index === mappedActions.length - 1
        ? () => {
          dispatch(loadMatrix());
          dispatch(addSystemNotice('Updated points matrix successfully.', SNACK_SUCCESS));
        }
        : undefined;
      dispatch(updateAction(action.id, action, onSuccess));
    });
  };

  return (
    <PageLayout title="Points Matrix">
      { loading && <Loader orange /> }
      { !loading && <ReputationPointsMatrix matrix={ matrix } onSave={ onSave } /> }
    </PageLayout>
  );
};

ReputationPointsMatrixContainer.defaultProps = {
  matrix: []
};

ReputationPointsMatrixContainer.propTypes = {
  matrix: arrayOf(shape({
    name: string.isRequired,
    actions: arrayOf(shape({
      id: number.isRequired,
      description: string.isRequired,
      points: number.isRequired
    }))
  }))
};

const mapStateToProps = ({ reputation }) => ({
  matrix: reputation.matrix
});

export default connect(mapStateToProps)(withAuthentication(ReputationPointsMatrixContainer));
