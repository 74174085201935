import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { arrayOf, node, oneOfType } from 'prop-types';

import './modal.scss';

const Modal = ({ children }) => {
  const mount = document.getElementById('modal-root');

  const overlay = document.createElement('div');
  overlay.setAttribute('class', 'modal-overlay');

  const modalEl = document.createElement('div');
  modalEl.setAttribute('class', 'modal');

  useEffect(() => {
    mount.appendChild(overlay);
    mount.appendChild(modalEl);

    return () => {
      mount.removeChild(overlay);
      return mount.removeChild(modalEl);
    };
  }, [modalEl, mount, overlay]);

  return createPortal(children, modalEl);
};

Modal.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired
};

export default Modal;
