import React from 'react';
import { useHistory } from 'react-router';

import { ReactComponent as SuccessIcon } from '../../../icons/big-success-icon.svg';
import FormRow from '../../../components/form/formRow/FormRow';
import FormAction from '../../../components/form/formAction/FormAction';
import SuccessLayout from '../../../components/layout/success/SuccessLayout';

const PasswordSuccessContainer = () => {
  const history = useHistory();

  const onNavigateLogin = () => {
    history.push('/');
  };

  return (
    <SuccessLayout title="Success"
                   subtitle="Your password has been reset"
                   logo={ <SuccessIcon /> }
    >
      <FormRow>
        <FormAction onClick={ onNavigateLogin } label="Back to login" />
      </FormRow>
    </SuccessLayout>
  );
};

export default PasswordSuccessContainer;
