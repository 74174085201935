export const LOAD_MATRIX = '[REPUTATION_STORE] Load reputation matrix';
export const SET_MATRIX = '[REPUTATION_STORE] Set reputation matrix';
export const loadMatrix = (onSuccess) => ({
  type: LOAD_MATRIX,
  onSuccess
});

export const UPDATE_ACTION = '[REPUTATION_STORE] Update reputation action';
export const updateAction = (id, action, onSuccess) => ({
  type: UPDATE_ACTION,
  id,
  action,
  onSuccess
});
