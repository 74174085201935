import { API_HOST, getHttpDeleteOptions, getHttpGetOptions, getHttpPostOptions, getHttpPutOptions } from './index';
import { getAuthHeaders } from '../auth.util';

const getPostsEndpoint = (nextPage) => nextPage === null
  ? `${ API_HOST }/posts/`
  : `${ API_HOST }/posts/?page=${nextPage}`;
export const getLoadPostsRequest = (nextPage) => [
  getPostsEndpoint(nextPage),
  getHttpGetOptions(getAuthHeaders())
];

const getPostEndpoint = (id) => `${ API_HOST }/posts/${id}/`;
export const getRemovePostRequest = (id) => [
  getPostEndpoint(id),
  getHttpDeleteOptions(getAuthHeaders())
];

const getTagsEndpoint = () => `${ API_HOST }/tags/`;
export const getLoadTagsRequest = () => [
  getTagsEndpoint(),
  getHttpGetOptions(getAuthHeaders())
];

export const getCreateTagRequest = (tag) => [
  getTagsEndpoint(),
  getHttpPostOptions(tag, getAuthHeaders())
];

const getTagEndpoint = (id) => `${ API_HOST }/tags/${ id }/`;
export const getEditTagRequest = (id, tag) => [
  getTagEndpoint(id),
  getHttpPutOptions(tag, getAuthHeaders())
];

export const getRemoveTagRequest = (id) => [
  getTagEndpoint(id),
  getHttpDeleteOptions(getAuthHeaders())
];

const getCategoriesEndpoint = () => `${ API_HOST }/categories/`;
export const getLoadCategoriesRequest = () => [
  getCategoriesEndpoint(),
  getHttpGetOptions(getAuthHeaders())
];

const getEmptyCategoriesEndpoint = () => `${ API_HOST }/categories/empty/`;
export const getLoadEmptyCategoriesRequest = () => [
  getEmptyCategoriesEndpoint(),
  getHttpGetOptions(getAuthHeaders())
];

export const getCreateCategoryRequest = (category) => [
  getCategoriesEndpoint(),
  getHttpPostOptions(category, getAuthHeaders())
];

const getCategoryEndpoint = (id) => `${ API_HOST }/categories/${ id }/`;
export const getEditCategoryRequest = (id, category) => [
  getCategoryEndpoint(id),
  getHttpPutOptions(category, getAuthHeaders())
];

const getCuratorsEndpoint = () => `${ API_HOST }/posts/curators/`;
export const getLoadCuratorsRequest = () => [
  getCuratorsEndpoint(),
  getHttpGetOptions(getAuthHeaders())
];

export const getRemoveCategoryRequest = (id) => [
  getCategoryEndpoint(id),
  getHttpDeleteOptions(getAuthHeaders())
];
