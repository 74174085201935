import React from 'react';
import { arrayOf, node, oneOfType } from 'prop-types';

import './card.scss';

const renderFooter = (subtitle) => subtitle && (
  <div className="card__footer">
    <span>{ subtitle }</span>
  </div>
);

const Card = ({ children, subtitle }) => (
  <div className="card">
    { children }
    { renderFooter(subtitle) }
  </div>
);

Card.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired
};

export default Card;
