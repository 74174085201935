import React from 'react';
import { arrayOf, func, instanceOf, node, oneOfType, shape } from 'prop-types';

import { preventDefault } from '../../tools/helpers';

import './form.scss';

const Form = ({ children, formRef }) => (
  <form className="form" onSubmit={ preventDefault } ref={ formRef }>
    { children }
  </form>
);

Form.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  formRef: oneOfType([
    func,
    shape({
      current: instanceOf(Element)
    })
  ])
};

export default Form;
