import { arrayOf, func, number, shape } from 'prop-types';
import React from 'react';

import { noOp } from '../../tools/helpers';
import { MEMBER_PROP_TYPE } from '../../tools/prop.types';
import { roleItems, sortItems, tableColumns, tableModifiers } from './member-list.helpers';

import { ReactComponent as DeactivateIcon } from '../../icons/deactivate-icon.svg';
import { ReactComponent as ActivateIcon } from '../../icons/activate-icon.svg';
import { ReactComponent as PencilIcon } from '../../icons/pencil-icon.svg';

import Table from '../table/Table';
import Avatar from '../avatar/Avatar';
import Select from '../select/Select';
import ListAction from './list-action/ListAction';

import './member-list.scss';

const renderActions = (status, onDeactivateMember, onActivateMember) => {
  switch (status) {
    case 'Active':
      return <ActivateIcon className="members-list__activate" onClick={ onDeactivateMember } />;
    default:
      return <DeactivateIcon className="members-list__deactivate" onClick={ onActivateMember } />;
  }
};

const mapRole = (role, onChange) => (
  <Select
    placeholder="Select role"
    items={ roleItems }
    value={ role }
    onChange={ onChange }
    shadowless
  />
);

const mapStatus = (active, adminActive, role) => {
  if (role === 0) {
    return adminActive ? 'Active' : 'Admin account is inactive';
  }

  return active ? 'Active' : 'Not yet verified';
};

const renderFullName = (item, onEditProfile) => (
  <div className="members-list__full-name">
    <span>{ `${ item.first_name } ${ item.last_name }` }</span>
    <PencilIcon onClick={ () => onEditProfile(item) } />
  </div>
);

const formatRows = (profile, items, onEditProfile, onEditRole, onDeactivateMember, onActivateMember) => items
  .filter((item) => item.id !== profile.id)
  .map((item) => ({
    ...item,
    avatar: <Avatar imageSource={ item.profile_image } />,
    fullName: renderFullName(item, onEditProfile),
    role: mapRole(item.role, ({ target: { value } }) => onEditRole(value, item)),
    status: mapStatus(item.is_active, item.admin_active, item.role),
    actions:
      <div className="members-list__actions">
        { renderActions(
          mapStatus(item.is_active, item.admin_active, item.role),
          () => onDeactivateMember(item),
          () => onActivateMember(item)
        ) }
      </div>
  }));

const MemberList = ({
                      profile,
                      members,
                      onSearch,
                      onSort,
                      onInviteAdmin,
                      onEditProfile,
                      onEditRole,
                      onDeactivateMember,
                      onActivateMember,
                      loading
                    }) => (
  <div className="members-list">
    <div className="members-list__header">
      <ListAction buttonLabel="Invite Admin"
                  onSearch={ onSearch }
                  onSort={ onSort }
                  onActionClick={ onInviteAdmin }
                  inputPlaceholder="search users..."
                  itemsToSort={ sortItems }
      />
    </div>
    <Table
      cols={ tableColumns }
      headerModifiers={ tableModifiers }
      columnModifiers={ tableModifiers }
      rowData={ formatRows(profile, members, onEditProfile, onEditRole, onDeactivateMember, onActivateMember) }
      loading={ loading }
    />
  </div>
);

MemberList.defaultProps = {
  profile: undefined,
  members: [],
  onSearch: noOp,
  onSort: noOp,
  onInviteAdmin: noOp,
  onDeactivateMember: noOp,
  onActivateMember: noOp,
  onEditProfile: noOp
};

MemberList.propTypes = {
  profile: shape({
    id: number.isRequired
  }),
  members: arrayOf(MEMBER_PROP_TYPE),
  onSearch: func,
  onSort: func,
  onInviteAdmin: func,
  onDeactivateMember: func,
  onActivateMember: func,
  onEditProfile: func
};

export default MemberList;
