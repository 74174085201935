import React from 'react';
import { connect } from 'react-redux';

import { createTag, editTag, loadTags, removeTag } from '../../../actions/post.actions';

import withAuthentication from '../../auth/withAuthentication';
import PageLayout from '../../../components/layout/page/PageLayout';
import Tags from '../../../components/admin/tags/Tags';
import Modal from '../../../components/modal/Modal';
import ModalLayout from '../../../components/modal/ModalLayout';
import CreateTag from '../../../components/admin/tags/CreateTag';
import DeleteTags from '../../../components/admin/tags/DeleteTags';
import EditTag from '../../../components/admin/tags/EditTag';

class TagsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showCreateModal: false,
      showDeleteModal: false,
      showEditModal: false,
      createdTags: [],
      loading: true
    };
  }

  componentDidMount() {
    this.fetchTags();
  }

  fetchTags() {
    const { dispatch } = this.props;
    dispatch(loadTags(this.stopLoading));
  }

  stopLoading = () => {
    this.setState({ loading: false });
  };

  onOpenCreateModal = () => {
    this.setState({ showCreateModal: true, createdTags: [] });
  };

  onCloseCreateModal = () => {
    this.setState({ showCreateModal: false });
  };

  onOpenDeleteModal = (tag) => {
    this.setState({ showDeleteModal: true, selectedTag: tag });
  };

  onCloseDeleteModal = () => {
    this.setState({ showDeleteModal: false, selectedTag: undefined });
  };

  onOpenEditModal = (tag) => {
    this.setState({ showEditModal: true, selectedTag: tag });
  };

  onCloseEditModal = () => {
    this.setState({ showEditModal: false, selectedTag: undefined });
  };

  setTags = (createdTags) => {
    this.setState({ createdTags: createdTags });
  };

  renderCreateTagModal = () => this.state.showCreateModal && (
    <Modal>
      <ModalLayout title="Create New Tags" onClose={ this.onCloseCreateModal }>
        <CreateTag saveTags={ this.onSaveTags } createdTags={ this.state.createdTags } setTags={ this.setTags } />
      </ModalLayout>
    </Modal>
  );

  renderDeleteTagModal = () => this.state.showDeleteModal && (
    <Modal>
      <ModalLayout title="Delete Tag" onClose={ this.onCloseDeleteModal }>
        <DeleteTags deleteTag={ this.onDelete } onClose={ this.onCloseDeleteModal } />
      </ModalLayout>
    </Modal>
  );

  renderEditTagModal = () => this.state.showEditModal && (
    <Modal>
      <ModalLayout title="Edit Tag" onClose={ this.onCloseEditModal }>
        <EditTag editTag={ this.onEdit } tag={ this.state.selectedTag } />
      </ModalLayout>
    </Modal>
  );

  onSaveTags = () => {
    this.onCloseCreateModal();

    const { dispatch } = this.props;
    this.state.createdTags.forEach((tag) => {
      dispatch(createTag({ name: tag, active: true }, this.stopLoading));
    });
  };

  onEdit = (tag) => {
    this.onCloseEditModal();

    const { dispatch } = this.props;
    dispatch(editTag(tag, this.stopLoading));
  };

  onDelete = () => {
    this.onCloseDeleteModal();

    const { dispatch } = this.props;
    dispatch(removeTag(this.state.selectedTag, this.stopLoading));
  };

  render() {
    const { tags } = this.props;
    const { loading } = this.state;

    return (
      <PageLayout title="Tag Management">
        <Tags
          tags={ tags }
          onClickAction={ this.onOpenCreateModal }
          onDelete={ this.onOpenDeleteModal }
          onEdit={ this.onOpenEditModal }
          loading={ loading }
        />
        { this.renderCreateTagModal() }
        { this.renderDeleteTagModal() }
        { this.renderEditTagModal() }
      </PageLayout>
    );
  }
}

const mapStateToProps = ({ post }) => ({
  tags: post.tags
});

export default connect(mapStateToProps)(withAuthentication(TagsContainer));
