import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { arrayOf, node, oneOfType, shape, string } from 'prop-types';
import { NavLink } from 'react-router-dom';

import { getClassNames } from '../../../tools/helpers';

import { ReactComponent as OpenIcon } from '../../../icons/open-icon.svg';
import { ReactComponent as Logo } from '../../../icons/icon-small-food.svg';

import './sidebar.scss';

const renderTitle = (title) => (
  <div className="sidebar__menu-title">
    { title }
  </div>
);

const NavItem = ({ item, expanded }) => (
  <li className="sidebar__menu-item">
    <NavLink to={ item.path } className="sidebar__link" activeClassName="sidebar__link--active">
      { item.icon }
      { expanded && renderTitle(item.title) }
    </NavLink>
  </li>
);

const renderMenuItems = (expanded, items) => (
  items.map((item) =>
    <NavItem key={ item.title } item={ item } expanded={ expanded } />
  )
);

const Sidebar = ({ expanded, items, onExpand }) => {
  return <div className={ getClassNames('sidebar', { expanded }) }>
    <div>
      <div className="sidebar__header">
        <div className="sidebar__header-action">
          <OpenIcon onClick={ onExpand } />
        </div>
        <div className="sidebar__header-item">
          <Logo />
        </div>
      </div>
      <ul className="sidebar__menu-items">
        { renderMenuItems(expanded, items) }
      </ul>
    </div>
  </div>;
};

Sidebar.defaultProps = {
  items: []
};

Sidebar.propTypes = {
  items: arrayOf(shape({
    path: string.isRequired,
    title: string.isRequired,
    icon: oneOfType([arrayOf(node), node]).isRequired
  }))
};

const mapStateToProps = (props) => props;

export default withRouter(connect(mapStateToProps)(Sidebar));
