import axios from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { addSystemNotice } from '../actions/system.actions';
import { SNACK_CRITICAL, SNACK_SUCCESS } from '../tools/helpers';
import {
  COMPLETE_PROFILE,
  LOGIN,
  LOGOUT,
  PROFILE_ID,
  RESEND_OTP,
  RESET_PASSWORD,
  SET_COMPLETE_ERRORS,
  SET_LOGIN_ERRORS,
  SET_PASSWORD_ERRORS,
  SET_TOKEN,
  VALIDATE_EMAIL,
  VALIDATE_OTP,
  VERIFY_EMAIL
} from '../actions/auth.actions';
import {
  getCompleteProfileRequest,
  getLoginRequest,
  getResendOtpRequest,
  getResetPasswordRequest,
  getValidateEmailRequest,
  getValidateOtpRequest,
  getVerifyEmailRequest
} from '../tools/api/auth.endpoints';
import { removeAuthTokenCookie, removeRememberMeCookie, setAuthTokenCookie, setRememberMeCookie } from '../tools/auth.util';

export function* performLogin({ email, password, history, rememberMe, onDone }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoginRequest(email, password);

    // make the request, extract the response
    const { data } = yield call(axios, endpoint, requestOptions);
    const { token } = data;

    // clear out any registration errors
    yield put({ type: SET_LOGIN_ERRORS, loginErrors: undefined });

    // set the auth token for future requests
    yield put({ type: SET_TOKEN, token });
    yield call(setAuthTokenCookie, token);

    if (rememberMe) {
      yield call(setRememberMeCookie, token);
    }

    // navigate to dashboard page
    yield call(history.push, '/admin/dashboard');
  } catch ({ response }) {
    yield put({ type: SET_LOGIN_ERRORS, loginErrors: response?.data });
    yield put({ type: SET_TOKEN, token: undefined });
    yield put(addSystemNotice('We were not able to login with your SmartBiii account.', SNACK_CRITICAL));
  }

  if (onDone) {
    yield call(onDone);
  }
}

export function* watchForLoginRequest() {
  yield takeLatest(LOGIN, performLogin);
}

export function* performVerifyEmail({ email, history, onDone }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getVerifyEmailRequest(email);

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: PROFILE_ID, profileId: data.id });

    // clear out any registration errors
    yield put({ type: SET_PASSWORD_ERRORS, passwordErrors: undefined });

    // navigate to login page
    if (data.first_name) {
      yield put(addSystemNotice('Your account has been verified.', SNACK_SUCCESS));
      yield call(history.push, '/');
    } else {
      yield call(history.push, '/complete-profile');
    }
  } catch ({ response }) {
    yield put({ type: SET_PASSWORD_ERRORS, passwordErrors: response?.data });
    yield put(addSystemNotice('The captured email address is invalid.', SNACK_CRITICAL));
  }

  if (onDone) {
    yield call(onDone);
  }
}

export function* watchForVerifyEmailRequest() {
  yield takeLatest(VERIFY_EMAIL, performVerifyEmail);
}

export function* performCompleteProfile({ profile, profileId, history, onDone }) {
  try {
    const [endpoint, requestOptions] = getCompleteProfileRequest(profile, profileId);

    // make the request, no need to check the response
    yield call(axios, endpoint, requestOptions);

    // clear out any registration errors
    yield put({ type: SET_COMPLETE_ERRORS, completeErrors: undefined });

    yield put(addSystemNotice('Successfully completed profile.', SNACK_SUCCESS));

    // navigate to login page
    yield call(history.push, '/');
  } catch ({ response }) {
    yield put({ type: SET_COMPLETE_ERRORS, completeErrors: response?.data });
    yield put(addSystemNotice('We were not able to complete your SmartBiii profile.', SNACK_CRITICAL));
  }

  if (onDone) {
    yield call(onDone);
  }
}

export function* watchForCompleteProfileRequest() {
  yield takeLatest(COMPLETE_PROFILE, performCompleteProfile);
}

export function* performValidateEmail({ email, history, onDone }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getValidateEmailRequest(email);

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    // clear out any registration errors
    yield put({ type: SET_PASSWORD_ERRORS, passwordErrors: undefined });

    yield put(addSystemNotice('You will receive an email to start the reset password process', SNACK_SUCCESS));

    if (!!history) {
      // navigate to otp page
      yield call(history.push, `/forgot-password/${ data.token }/otp`);
    }
  } catch ({ response }) {
    yield put({ type: SET_PASSWORD_ERRORS, passwordErrors: response?.data });
    yield put(addSystemNotice('We were not able to validate your email with a valid SmartBiii account', SNACK_CRITICAL));
  }

  if (onDone) {
    yield call(onDone);
  }
}

export function* watchForValidateEmailRequest() {
  yield takeLatest(VALIDATE_EMAIL, performValidateEmail);
}

export function* performValidateOTP({ token, otp, history, onDone }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getValidateOtpRequest(token, otp);

    // make the request, no need to check the response
    yield call(axios, endpoint, requestOptions);

    // clear out any registration errors
    yield put({ type: SET_PASSWORD_ERRORS, passwordErrors: undefined });

    // navigate to confirm password page
    yield call(history.push, `/forgot-password/${ token }/reset`);
  } catch ({ response }) {
    yield put({ type: SET_PASSWORD_ERRORS, passwordErrors: response?.data });
    yield put(addSystemNotice('The One Time Pin provided is not valid.', SNACK_CRITICAL));
  }

  if (onDone) {
    yield call(onDone);
  }
}

export function* watchForValidateOtpRequest() {
  yield takeLatest(VALIDATE_OTP, performValidateOTP);
}

export function* performResendOTP({ token }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getResendOtpRequest(token);

    // make the request, no need to check the response
    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('A new One Time Pin has been sent to you.', SNACK_SUCCESS));
  } catch ({ response }) {
    yield put(addSystemNotice('A new One Time Pin could not be sent to you.', SNACK_CRITICAL));
  }
}

export function* watchForResendOtpRequest() {
  yield takeLatest(RESEND_OTP, performResendOTP);
}

export function* performResetPassword({ token, password, password_confirm, history, onDone }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getResetPasswordRequest(token, password, password_confirm);

    // make the request, no need to check the response
    yield call(axios, endpoint, requestOptions);

    // clear out any password errors
    yield put({ type: SET_PASSWORD_ERRORS, passwordErrors: undefined });

    // navigate to password success page
    yield call(history.push, '/forgot-password/success');
    yield put(addSystemNotice('Your password has been updated successfully', SNACK_SUCCESS));
  } catch ({ response }) {
    yield put({ type: SET_PASSWORD_ERRORS, passwordErrors: response?.data });
    yield put(addSystemNotice('A problem occurred when trying to save your password. Please contact the system admin.', SNACK_CRITICAL));
  }

  if (onDone) {
    yield call(onDone);
  }
}

export function* watchForResetPasswordRequest() {
  yield takeLatest(RESET_PASSWORD, performResetPassword);
}

export function* performLogout() {
  yield put({ type: SET_TOKEN, token: undefined });
  yield call(removeAuthTokenCookie);
  yield call(removeRememberMeCookie);
}

export function* watchForLogoutRequest() {
  yield takeLatest(LOGOUT, performLogout);
}

export default function* authSaga() {
  yield all([
    watchForVerifyEmailRequest(),
    watchForLoginRequest(),
    watchForCompleteProfileRequest(),
    watchForValidateEmailRequest(),
    watchForValidateOtpRequest(),
    watchForResendOtpRequest(),
    watchForResetPasswordRequest(),
    watchForLogoutRequest()
  ]);
}
