import React from 'react';
import { tableColumns, tableModifiers, sortItems } from './reported-posts.helpers';
import { arrayOf, shape, string } from 'prop-types';

import { ReactComponent as PencilIcon } from '../../../icons/pencil-icon.svg';
import { ReactComponent as VisibilityIcon } from '../../../icons/visibility-icon.svg';
import Table from '../../table/Table';
import ListAction from '../../members/list-action/ListAction';

import './reported-posts.scss';

const renderActions = () => (
  <VisibilityIcon />
);

const renderTitle = (item) => (
  <div className="reported-posts__name">
    <span>{ item.title }</span>
    <PencilIcon />
  </div>
);

const formatRows = (items) => items
  .map((item) => ({
    ...item,
    title: renderTitle(item),
    actions:
      <div className="post-categories__actions">
        { renderActions() }
      </div>
  }));

const ReportedPosts = ({ reportedPosts }) => (
  <div className="reported-posts">
    <div className="reported-posts__header">
      <ListAction
        buttonLabel="Add new category"
        noButton
        inputPlaceholder="search posts"
        itemsToSort={ sortItems }
      />
    </div>
    <Table
      cols={ tableColumns }
      headerModifiers={ tableModifiers }
      columnModifiers={ tableModifiers }
      rowData={ formatRows(reportedPosts) }
    />
  </div>
);

ReportedPosts.defaultProps = {
  reportedPosts: []
};

ReportedPosts.propTypes = {
  reportedPosts: arrayOf(shape({
    title: string,
    date: string,
    reason: string,
    name: string
  }))
};

export default ReportedPosts;
