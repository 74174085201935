export const MEMBER_MOD_HEADERS = [
  { Header: 'Curator', accessor: 'curator' },
  { Header: 'Date Reported', accessor: 'dateReported' },
  { Header: 'Reason for report', accessor: 'reason' },
  { Header: 'Type', accessor: 'type' },
  { Header: 'Reporter', accessor: 'reporter' },
  { Header: 'Action', accessor: 'action' }
];

export const MODERATED_MEMBER_HEADERS = [
  { Header: 'User name', accessor: 'userName' },
  { Header: 'Action taken', accessor: 'actionTaken' },
  { Header: 'Date of action', accessor: 'dateOfAction' },
  { Header: 'Previous offences', accessor: 'previousOffences' },
  { Header: 'Status', accessor: 'status' },
  { Header: 'Actions', accessor: 'actions' }
];

export const MODERATED_DETAIL_HEADERS = [
  { Header: 'Reason for report', accessor: 'reasonForReport' },
  { Header: 'Date of action', accessor: 'dateOfAction' },
  { Header: 'Action Taken', accessor: 'actionTaken' }
];

export const BAN_STATUSES = ['Warning', 'Temporary Ban', 'Permanent Ban'];

export const getAllBanStatusesForDropdown = () => {
  return BAN_STATUSES.map((status) => ({ value: status, text: status }));
};

export const getBanStatusesForDropdown = (action) => {
  const indexOfAction = BAN_STATUSES.indexOf(action);

  return BAN_STATUSES.slice(indexOfAction).map((status) => ({ value: status, text: status }));
};

export const isMoreSevere = (originalAction, updatedAction) => {
  const ogIndex = BAN_STATUSES.indexOf(originalAction);
  const updatedIndex = BAN_STATUSES.indexOf(updatedAction);

  return updatedIndex > ogIndex;
};

export const getAction = (reason) => {
  switch (reason) {
    case 'Racist, discriminatory or incites violence':
      return 'Permanent Ban';
    case  'Inappropriate, explicit, uncivil, rude or vulgar':
      return 'Temporary Ban';
    case 'Potentially fraudulent or illegal':
      return 'Temporary Ban';
    case 'A duplicate Post':
      return 'Warning';
    default:
      return undefined;
  }
};