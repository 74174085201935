import React from 'react';
import { arrayOf, bool, func, instanceOf, number, oneOfType, shape, string } from 'prop-types';

import { getClassNames, isUndefined, noOp } from '../../tools/helpers';
import { ReactComponent as IconError } from '../../icons/error-small-icon.svg';

import './input.scss';

const renderLabel = (label, leftLabel) => !isUndefined(label) && (
  <div className={ getClassNames('input__label', { left: leftLabel }) }>
    <label>{ label }</label>
  </div>
);

/**
 * Django API will return error messages as an array, so we will need to display each of them.
 */
const renderErrors = (errors) => errors && typeof errors !== 'string' && errors.map((error) => (
  <div className="input__error" key={ error }>
    <IconError />
    <small className="input__error-msg" key={ error }>{ error }</small>
  </div>
));

const renderIcon = (Icon, onIconClick) => Icon && (
  <div className="input__icon">
    <Icon onClick={ onIconClick } />
  </div>
);

const renderAction = (action, onActionClick) => action && (
  <div className="input__icon">
    <p onClick={ onActionClick }>{ action }</p>
  </div>
);

const renderLink = (link, onLinkClick) => link && (
  <div className="input__link">
    <p onClick={ onLinkClick }>{ link }</p>
  </div>
);

const Input = ({
                 readOnly,
                 borderless,
                 disabled,
                 type,
                 name,
                 label,
                 inputRef,
                 placeholder,
                 error,
                 onChange,
                 onKeyDown,
                 onKeyUpCapture,
                 icon,
                 large,
                 medium,
                 maxLength,
                 onIconClick,
                 action,
                 onActionClick,
                 link,
                 onLinkClick,
                 value,
                 leftLabel
               }) => (
  <div className={ getClassNames('input', { error, disabled }) }>
    { renderLabel(label, leftLabel) }
    <div className={ getClassNames('input__input-wrapper', { large, borderless, medium }) }>
      <input
        disabled={ disabled }
        onKeyUpCapture={ onKeyUpCapture }
        onKeyDown={ onKeyDown }
        onChange={ onChange }
        ref={ inputRef }
        type={ type }
        name={ name }
        placeholder={ placeholder }
        maxLength={ maxLength }
        readOnly={ readOnly }
        value={ value }
      />
      { renderIcon(icon, onIconClick) }
      { renderAction(action, onActionClick) }
      { renderLink(link, onLinkClick) }
    </div>
    { renderErrors(error) }
  </div>
);

Input.defaultProps = {
  name: undefined,
  type: 'text',
  link: undefined,
  inputRef: undefined,
  error: undefined,
  onChange: noOp,
  onKeyDown: noOp,
  onKeyUpCapture: noOp,
  icon: undefined,
  large: false,
  medium: false,
  maxLength: undefined,
  onClick: noOp,
  readOnly: false,
  borderless: false,
  disabled: false,
  leftLabel: true
};

Input.propTypes = {
  name: string,
  type: string,
  label: string,
  readOnly: bool,
  onChange: func,
  onKeyDown: func,
  onKeyUpCapture: func,
  placeholder: string.isRequired,
  link: string,
  error: oneOfType([arrayOf(string), string]),
  large: bool,
  medium: bool,
  borderless: bool,
  disabled: bool,
  onClick: func,
  maxLength: oneOfType([number, string]),
  inputRef: oneOfType([
    func,
    shape({
      current: instanceOf(Element)
    })
  ]),
  icon: oneOfType([
    func,
    shape({
      current: instanceOf(Element)
    })
  ])
};

export default Input;
