import React, { createRef, useState } from 'react';
import { arrayOf, func, shape, string } from 'prop-types';

import { noOp } from '../../../tools/helpers';

import { ReactComponent as OpenEyeIcon } from '../../../icons/open-eye-icon.svg';
import { ReactComponent as ClosedEyeIcon } from '../../../icons/closed-eye-icon.svg';

import Form from '../../form/Form';
import FormRow from '../../form/formRow/FormRow';
import Input from '../../input/Input';
import FormAction from '../../form/formAction/FormAction';

import './confirm-password.scss';

const renderFields = (passwordRef, confirmPasswordRef, isPasswordShown, setPasswordShown, isConfirmShown, setConfirmShown, errors) => (
  <>
    <FormRow>
      <Input
        onIconClick={ () => setPasswordShown(!isPasswordShown) }
        type={ isPasswordShown ? 'text' : 'password' }
        placeholder="Enter your password"
        icon={ isPasswordShown ? ClosedEyeIcon : OpenEyeIcon }
        inputRef={ passwordRef }
        label="Password"
        error={ errors?.password }
      />
    </FormRow>
    <FormRow>
      <Input
        onIconClick={ () => setConfirmShown(!isConfirmShown) }
        type={ isConfirmShown ? 'text' : 'password' }
        placeholder="Confirm your password"
        icon={ isConfirmShown ? ClosedEyeIcon : OpenEyeIcon }
        inputRef={ confirmPasswordRef }
        label="Confirm Password"
        error={ errors?.password_confirm }
      />
    </FormRow>
  </>
);

const renderForm = (passwordRef,
                    confirmPasswordRef,
                    onSubmit,
                    isPasswordShown,
                    setPasswordShown,
                    isConfirmShown,
                    setConfirmShown,
                    loading,
                    errors) => (
  <Form>
    { renderFields(passwordRef, confirmPasswordRef, isPasswordShown, setPasswordShown, isConfirmShown, setConfirmShown, errors) }
    <FormAction loading={ loading } label="Confirm" onClick={ onSubmit } />
  </Form>
);

const ConfirmPassword = ({ errors, onConfirmPassword, loading }) => {
  const passwordRef = createRef();
  const confirmPasswordRef = createRef();

  const [isPasswordShown, setPasswordShown] = useState(false);
  const [isConfirmShown, setConfirmShown] = useState(false);

  const onSubmit = () => {
    onConfirmPassword({
      password: passwordRef.current.value,
      password_confirm: confirmPasswordRef.current.value
    });
  };

  return (
    <div className="confirm-password">
      { renderForm(passwordRef,
        confirmPasswordRef,
        onSubmit,
        isPasswordShown,
        setPasswordShown,
        isConfirmShown,
        setConfirmShown,
        loading,
        errors) }
    </div>
  );
};

ConfirmPassword.defaultProps = {
  onConfirmPassword: noOp
};

ConfirmPassword.propTypes = {
  errors: shape({
    password: arrayOf(string),
    password_confirm: arrayOf(string)
  }),
  onConfirmPassword: func
};

export default ConfirmPassword;
