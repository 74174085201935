export const tableColumns = [
  { Header: '', accessor: 'empty' },
  { Header: 'Post Title', accessor: 'title' },
  { Header: 'Date Created', accessor: 'date' },
  { Header: 'Reason', accessor: 'reason' },
  { Header: 'Curator Name', accessor: 'name' },
  { Header: '', accessor: 'actions' }
];

export const tableModifiers = {
  actions: {
    centered: true
  }
};

export const sortItems = [
  {
    value: 'newest',
    text: 'Sort by Newest First'
  }
];
