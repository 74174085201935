import { API_HOST, getHttpGetOptions, getHttpPutOptions } from './index';
import { getAuthHeaders } from '../auth.util';

const getUpdateNotificationSettingsEndpoint = (id, settingsId) =>
  `${API_HOST}/members/${id}/notification-settings/${settingsId}/`;
export const getUpdateNotificationSettingsRequest = (id, settingsId, notificationSettings) => [
  getUpdateNotificationSettingsEndpoint(id, settingsId),
  getHttpPutOptions(notificationSettings, getAuthHeaders())
];

const getRetrieverNotificationSettingsEndpoint = (id) =>
  `${API_HOST}/members/${id}/notification-settings/`;
export const getRetrieveNotificationSettingsRequest = (id) => [
  getRetrieverNotificationSettingsEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];
