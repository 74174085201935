import React from 'react';

import withAuthentication from '../../auth/withAuthentication';

import PageLayout from '../../../components/layout/page/PageLayout';
import ReputationDashboard from '../../../components/members/reputation/ReputationDashboard';

const ReputationContainer = () => (
  <PageLayout title="Reputation Management">
    <ReputationDashboard />
  </PageLayout>
);

export default withAuthentication(ReputationContainer);
