import React, { createRef } from 'react';
import { arrayOf, func, shape, string } from 'prop-types';

import { getClassNames, isEmpty, noOp } from '../../../tools/helpers';

import { ReactComponent as CancelIcon } from '../../../icons/cancel-icon.svg';

import Input from '../../input/Input';
import Button from '../../button/Button';

import './invite-admin.scss';

const Tag = ({ email, onRemove, error }) => (
  <div className={ getClassNames('tag', { error }) }>
    { email }
    <CancelIcon onClick={ () => onRemove(email) } />
  </div>
);

const renderTags = (emails, onRemove, errors) => emails.map((email) =>
  <Tag key={ email } email={ email } onRemove={ onRemove } error={ errors[email] } />
);

const renderErrorMessage = (errors) => !isEmpty(Object.keys(errors)) && (
  <p className="invite-admin__error-msg">One or more of the usernames you have added already exists</p>
);

const InviteAdmin = ({ errors, onInvite, onValidate, emails, setEmails, removeTag }) => {
  const emailRef = createRef();

  const addEmail = (email) => {
    onValidate(email);
    setEmails([...emails.filter((item) => item !== email), email]);
    emailRef.current.value = '';
    emailRef.current.focus();
  };

  const onKeyUp = (e) => {
    const email = emailRef.current.value;
    if (e.code === 'Enter' && email !== '') {
      addEmail(email);
    } else if (!e.code) {
      addEmail(email);
    }
  };

  const onRemove = (value) => {
    removeTag(value);
    const updatedEmails = emails.filter((email) => email !== value);
    setEmails(updatedEmails);
  };

  return (
    <div className="invite-admin">
      <div className="invite-admin__search">
        <Input
          type="email"
          inputRef={ emailRef }
          label="Type the email address of the users you wish to invite and push enter to apply"
          placeholder="Email Address"
          onKeyUpCapture={ (e) => onKeyUp(e) }
          action="Add"
          onActionClick={ onKeyUp }
        />
      </div>
      { renderErrorMessage(errors) }
      <div className="invite-admin__emails">
        { !isEmpty(emails) && renderTags(emails, onRemove, errors) }
      </div>
      <div className="invite-admin__action">
        <Button
          disabled={ isEmpty(emails) || Object.keys(errors)?.length > 0 }
          label="Invite User(s)"
          onClick={ () => onInvite(emails) }
        />
      </div>
    </div>
  );
};

InviteAdmin.propTypes = {
  onValidate: noOp,
  setEmails: noOp,
  emails: [],
  errors: {}
};

InviteAdmin.propTypes = {
  onInvite: func.isRequired,
  emails: arrayOf(string),
  setEmails: func,
  onValidate: func,
  errors: shape({})
};

export default InviteAdmin;
