import React from 'react';
import { connect } from 'react-redux';
import { func } from 'prop-types';

import { getNotificationSettings } from '../../../components/admin/notifications/settings/notifications.helper';
import { setAppTitle } from '../../../actions/system.actions';
import {
  loadNotificationSettings,
  updateNotificationSettings
} from '../../../actions/notifications.actions';

import withAuthentication from '../../auth/withAuthentication';
import NotificationsSettings from '../../../components/admin/notifications/settings/NotificationsSettings';

class NotificationsSettingsContainer extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: {
        params: { id }
      }
    } = props;
    this.profileId = id;

    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(setAppTitle('Notification Settings'));
    dispatch(loadNotificationSettings(this.profileId, this.stopLoading));
  }

  stopLoading = () => {
    this.setState({
      loading: false
    });
  };

  onChange = (field) => {
    const { notificationSettings, dispatch } = this.props;

    notificationSettings[field] = !notificationSettings[field];
    dispatch(
      updateNotificationSettings(notificationSettings, this.profileId, notificationSettings.id)
    );
  };

  onToggleAll = (title) => {
    const { notificationSettings, dispatch } = this.props;
    const sections = getNotificationSettings(notificationSettings);

    const section = sections.find((section) => section.title === title);

    const anyUnchecked = section.settings.some((setting) => !setting.checked);

    section.settings.forEach((setting) => (notificationSettings[setting.field] = anyUnchecked));

    dispatch(
      updateNotificationSettings(notificationSettings, this.profileId, notificationSettings.id)
    );
  };

  render() {
    const { notificationSettings } = this.props;
    const { loading } = this.state;

    const sections = getNotificationSettings(notificationSettings);

    return (
      <NotificationsSettings
        onChange={this.onChange}
        sections={sections}
        onToggleAll={this.onToggleAll}
        loading={loading}
      />
    );
  }
}

NotificationsSettings.defaultProps = {
  notificationSettings: undefined
};

NotificationsSettings.propTypes = {
  dispatch: func
};

const mapStateToProps = ({ notification }) => ({
  notificationSettings: notification.notificationSettings
});

export default connect(mapStateToProps)(withAuthentication(NotificationsSettingsContainer));
