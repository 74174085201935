import React from 'react';
import { func, string } from 'prop-types';

import { getTitleInitials, noOp } from '../../tools/helpers';

import './image-initials-placeholder.scss';

const ImageInitialsPlaceholder = ({ title, onClick }) => (
  <div className="image-initials__placeholder" onClick={onClick}>
    {getTitleInitials(title)}
  </div>
);

ImageInitialsPlaceholder.defaultProps = {
  onClick: noOp
};

ImageInitialsPlaceholder.propTypes = {
  title: string,
  onClick: func
};

export default ImageInitialsPlaceholder;
