import React, { createRef, useState } from 'react';

import { arrayOf, func, shape, string } from 'prop-types';
import { noOp } from '../../../tools/helpers';

import { ReactComponent as ClosedEyeIcon } from '../../../icons/closed-eye-icon.svg';
import { ReactComponent as OpenEyeIcon } from '../../../icons/open-eye-icon.svg';

import Form from '../../form/Form';
import FormAction from '../../form/formAction/FormAction';
import FormRow from '../../form/formRow/FormRow';
import Input from '../../input/Input';
import Checkbox from '../../checkbox/Checkbox';

import './complete-profile.scss';

const renderFields = (firstNameRef, lastNameRef, cellPhoneNumberRef, passwordRef, confirmRef,
                      isChecked, setChecked, errors, isPasswordShown, setPasswordShown, isConfirmShown, setConfirmShown) => (
  <>
    <FormRow>
      <Input placeholder="Enter your first name"
             label="First Name"
             inputRef={ firstNameRef }
             error={ errors?.first_name }
      />
    </FormRow>
    <FormRow>
      <Input placeholder="Enter your last name"
             label="Last Name"
             inputRef={ lastNameRef }
             error={ errors?.last_name }
      />
    </FormRow>
    <FormRow>
      <Input placeholder="Enter your cellphone number"
             label="Cellphone Number"
             inputRef={ cellPhoneNumberRef }
             error={ errors?.contact_number }
      />
    </FormRow>
    <FormRow>
      <Input placeholder="Enter your password"
             onIconClick={ () => setPasswordShown(!isPasswordShown) }
             type={ isPasswordShown ? 'text' : 'password' }
             icon={ isPasswordShown ? ClosedEyeIcon : OpenEyeIcon }
             label="Password"
             inputRef={ passwordRef }
             error={ errors?.password }
      />
    </FormRow>
    <FormRow>
      <Input placeholder="Confirm your password"
             onIconClick={ () => setConfirmShown(!isConfirmShown) }
             type={ isConfirmShown ? 'text' : 'password' }
             icon={ isConfirmShown ? ClosedEyeIcon : OpenEyeIcon }
             label="Confirm Password"
             inputRef={ confirmRef }
             error={ errors?.password_confirm }
      />
    </FormRow>
    <FormRow>
      <Checkbox
        label="I agree with Smartbiii's"
        link="/terms"
        linkText="Terms and Conditions"
        checked={ isChecked }
        onChange={ () => setChecked(!isChecked) }
      />
    </FormRow>
  </>
);

const renderForm = (firstNameRef, lastNameRef, cellPhoneNumberRef, passwordRef, confirmRef,
                    onSubmit, isChecked, setChecked, loading, errors, isPasswordShown, setPasswordShown,
                    isConfirmShown, setConfirmShown) => (
  <Form>
    { renderFields(firstNameRef, lastNameRef, cellPhoneNumberRef, passwordRef, confirmRef,
      isChecked, setChecked, errors, isPasswordShown, setPasswordShown, isConfirmShown, setConfirmShown) }
    <FormAction
      loading={ loading }
      label="Complete"
      onClick={ onSubmit }
      disabled={ !isChecked }
    />
  </Form>
);

const CompleteProfile = ({ errors, onCompleteProfile, loading }) => {
  const firstNameRef = createRef();
  const lastNameRef = createRef();
  const cellPhoneNumberRef = createRef();
  const passwordRef = createRef();
  const confirmRef = createRef();

  const [isChecked, setChecked] = useState(false);
  const [isPasswordShown, setPasswordShown] = useState(false);
  const [isConfirmShown, setConfirmShown] = useState(false);

  const onSubmit = async () => {
    onCompleteProfile({
      first_name: firstNameRef.current.value,
      last_name: lastNameRef.current.value,
      contact_number: cellPhoneNumberRef.current.value,
      password: passwordRef.current.value,
      password_confirm: confirmRef.current.value
    });
  };

  return (
    <div className="complete-profile">
      { renderForm(firstNameRef, lastNameRef, cellPhoneNumberRef, passwordRef, confirmRef,
        onSubmit, isChecked, setChecked, loading, errors, isPasswordShown, setPasswordShown, isConfirmShown, setConfirmShown) }
    </div>
  );
};

CompleteProfile.defaultProps = {
  onCompleteProfile: noOp
};

CompleteProfile.propTypes = {
  errors: shape({
    first_name: arrayOf(string),
    last_name: arrayOf(string),
    contact_number: arrayOf(string)
  }),
  onCompleteProfile: func
};

export default CompleteProfile;
