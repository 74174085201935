import React, { createRef, useState } from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import { Link } from 'react-router-dom';

import { noOp } from '../../../tools/helpers';

import { ReactComponent as OpenEyeIcon } from '../../../icons/open-eye-icon.svg';
import { ReactComponent as ClosedEyeIcon } from '../../../icons/closed-eye-icon.svg';

import Form from '../../form/Form';
import FormAction from '../../form/formAction/FormAction';
import FormRow from '../../form/formRow/FormRow';
import Input from '../../input/Input';
import Checkbox from '../../checkbox/Checkbox';

import './login.scss';

const renderFields = (emailRef, passwordRef, isChecked, setChecked, isShown, setShown, errors) => (
  <>
    <FormRow>
      <Input
        type="email"
        placeholder="Enter your email"
        inputRef={ emailRef }
        label="Email"
        error={ errors?.username }
      />
    </FormRow>
    <FormRow>
      <Input
        onIconClick={ () => setShown(!isShown) }
        type={ isShown ? 'text' : 'password' }
        placeholder="Enter your password"
        icon={ isShown ? ClosedEyeIcon : OpenEyeIcon }
        inputRef={ passwordRef }
        label="Password"
        error={ errors?.password }
      />
    </FormRow>
    <FormRow>
      <Checkbox label="Remember me" checked={ isChecked } onChange={ () => setChecked(!isChecked) } />
      <Link className="login__forgot-password" to="/forgot-password">Forgot Password</Link>
    </FormRow>
  </>
);

const renderForm = (emailRef, passwordRef, onSubmit, isChecked, setChecked, isShown, setShown, loading, errors) => (
  <Form>
    { renderFields(emailRef, passwordRef, isChecked, setChecked, isShown, setShown, errors) }
    <FormAction loading={ loading } label="Login" onClick={ onSubmit } />
  </Form>
);

const Login = ({ errors, onLogin, loading }) => {
  const emailRef = createRef();
  const passwordRef = createRef();

  const [isChecked, setChecked] = useState(false);
  const [isShown, setShown] = useState(false);

  const onSubmit = () => {
    onLogin({
      email: emailRef.current.value,
      password: passwordRef.current.value,
      rememberMe: isChecked
    });
  };

  return (
    <div className="login">
      { renderForm(emailRef, passwordRef, onSubmit, isChecked, setChecked, isShown, setShown, loading, errors) }
    </div>
  );
};

Login.defaultProps = {
  onLogin: noOp
};

Login.propTypes = {
  onLogin: func,
  errors: shape({
    email: arrayOf(string),
    password: arrayOf(string)
  })
};

export default Login;
