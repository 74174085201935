import React from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as IconAchievementBadges } from '../../../icons/reputation/icon-achievement-badges.svg';
import { ReactComponent as IconLevels } from '../../../icons/reputation/icon-level.svg';
import { ReactComponent as IconPointsMatrix } from '../../../icons/reputation/icon-points-matrix.svg';

import './reputation-dashboard.scss';

const InfoCard = ({ title, description, icon, onActionClick }) => (
  <div className="info-card">
    <div className="info-card__header">
      <h4>{ title }</h4>
      <label onClick={ onActionClick }>View all</label>
    </div>
    <div className="info-card__body">
      <label className="info-card__body-desc">{ description }</label>
      { icon }
    </div>
  </div>
);

const items = [{
  title: 'Levels',
  description: 'Edit names, level badge icon and the list of requirements related to each reputation level',
  icon: <IconLevels />,
  path: 'reputation/levels'
}, {
  title: 'Points Matrix',
  description: 'Edit points value for a defined user action',
  icon: <IconPointsMatrix />,
  path: 'reputation/points-matrix'
}, {
  title: 'Achievement Badges',
  description: 'Edit the points value for a defined achievement',
  icon: <IconAchievementBadges />,
  path: 'reputation/badges'
}];

const ReputationDashboard = () => {
  const history = useHistory();

  const navTo = (path) => {
    history.push(path);
  };

  return (
    <div className="reputation-dashboard">
      { items.map(({ title, description, icon, path }) =>
        <InfoCard
          key={ title }
          title={ title }
          description={ description }
          icon={ icon }
          onActionClick={ () => navTo(path) }
        />
      ) }
    </div>
  );
};

export default ReputationDashboard;
