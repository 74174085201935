export const tableColumns = [
    { Header: '', accessor: 'empty' },
    { Header: 'Tag Name', accessor: 'name' },
    { Header: 'Format Name', accessor: 'slug' },
    { Header: 'Related Categories', accessor: (tag) => tag.related_categories ?? 0 },
    { Header: 'Related Posts', accessor: (tag) => tag.related_posts ?? 0 },
    { Header: '', accessor: 'actions' }
];

export const tableModifiers = {
    actions: {
        centered: true
    }
};

export const sortItems = [
    {
        value: 'category',
        text: 'Sort by Category'
    },
    {
        value: 'newest',
        text: 'Sort Newest First'
    },
    {
        value: 'oldest',
        text: 'Sort by Oldest First'
    },
    {
        value: 'updated',
        text: 'Sort by Updated Last'
    }
];
