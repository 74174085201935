import React from 'react';
import { connect } from 'react-redux';

import MemberModerationDashboard from '../../../components/members/moderation/MemberModerationDashboard';
import PageLayout from '../../../components/layout/page/PageLayout';

import withAuthentication from '../../auth/withAuthentication';
import { actionReport, removeReport, retrieveReportedPosts } from '../../../actions/moderation.actions';
import { REPORTED_POST_PROP_TYPES } from '../../../tools/prop.types';
import { getAction } from '../../../components/members/moderation/member-moderation.helper';

class MemberModerationContainer extends React.Component {
  componentDidMount() {
    this.props.dispatch(retrieveReportedPosts());
  }

  onSubmit = ({ item, state }) => {
    const { dispatch } = this.props;
    if (state === 'accept') {
      const report = {
        ...item,
        reporter_id: item.reporter.id,
        post_id: item.post?.id,
        comment_id: item.comment?.id
      };
      dispatch(actionReport(item.id, report));
    } else if (state === 'reject') {
      dispatch(removeReport(item.id));
    }
  };

  fuelActions = (reportedPosts) => reportedPosts.map((post) => ({
    ...post,
    action: getAction(post.reason)
  }));

  render() {
    const postsWithActions = this.fuelActions(this.props.reportedPosts);

    return (
      <PageLayout title="Member Moderation">
        <MemberModerationDashboard items={ postsWithActions } submitModerationAction={ this.onSubmit } />
      </PageLayout>
    );
  }
}

MemberModerationContainer.defaultProps = {
  items: []
};

MemberModerationContainer.propTypes = {
  reportedPosts: REPORTED_POST_PROP_TYPES
};

const mapStateToProps = ({ moderation }) => ({
  reportedPosts: moderation.reportedPosts
});

export default connect(mapStateToProps)(withAuthentication(MemberModerationContainer));
