import React from 'react';
import { connect } from 'react-redux';

import { createCategory, editCategory, loadCategories, removeCategory } from '../../../actions/post.actions';

import PageLayout from '../../../components/layout/page/PageLayout';
import PostCategories from '../../../components/admin/post-management/PostCategories';
import Modal from '../../../components/modal/Modal';
import ModalLayout from '../../../components/modal/ModalLayout';
import CreateCategory from '../../../components/admin/categories/CreateCategory';
import EditCategory from '../../../components/admin/categories/EditCategory';
import DeleteCategory from '../../../components/admin/categories/DeleteCategory';
import withAuthentication from '../../auth/withAuthentication';

class PostCategoriesContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showCreateCategoryModal: false,
      showEditCategoryModal: false,
      showDeleteCategoryModal: false,
      loading: true
    };
  }

  componentDidMount() {
    this.fetchCategories();
  }

  fetchCategories() {
    const { dispatch } = this.props;
    dispatch(loadCategories(this.stopLoading));
  }

  stopLoading = () => {
    this.setState({ loading: false });
  };

  onOpenCreateModal = () => {
    this.setState({ showCreateCategoryModal: true });
  };

  onCloseCreateModal = () => {
    this.setState({ showCreateCategoryModal: false });
  };

  onOpenEditModal = (category) => {
    this.setState({ showEditCategoryModal: true, selectedCategory: category });
  };

  onCloseEditModal = () => {
    this.setState({ showEditCategoryModal: false, selectedCategory: undefined });
  };

  onOpenDeleteModal = (category) => {
    this.setState({ showDeleteCategoryModal: true, selectedCategory: category });
  };

  onCloseDeleteModal = () => {
    this.setState({ showDeleteCategoryModal: false, selectedCategory: undefined });
  };

  renderCreateCategoryModal = () => this.state.showCreateCategoryModal && (
    <Modal>
      <ModalLayout title="Add New Category" onClose={ this.onCloseCreateModal }>
        <CreateCategory saveCategory={ this.onSave } onClose={ this.onCloseCreateModal } />
      </ModalLayout>
    </Modal>
  );

  onSave = (category) => {
    this.onCloseCreateModal();

    const { dispatch } = this.props;
    dispatch(createCategory(category, this.stopLoading));
  };

  onEdit = (category) => {
    this.onCloseEditModal();

    const { dispatch } = this.props;
    dispatch(editCategory(category, this.stopLoading));
  };

  onDelete = () => {
    this.onCloseDeleteModal();

    const { dispatch } = this.props;
    dispatch(removeCategory(this.state.selectedCategory, this.stopLoading));
  };

  renderEditCategoryModal = () => this.state.showEditCategoryModal && (
    <Modal>
      <ModalLayout title="Edit Category Details" onClose={ this.onCloseEditModal }>
        <EditCategory category={ this.state.selectedCategory } onClose={ this.onCloseEditModal } editCategory={ this.onEdit } />
      </ModalLayout>
    </Modal>
  );

  renderDeleteCategoryModal = () => this.state.showDeleteCategoryModal && (
    <Modal>
      <ModalLayout title="Delete Category" onClose={ this.onCloseDeleteModal }>
        <DeleteCategory removeCategory={ this.onDelete } onClose={ this.onCloseDeleteModal } />
      </ModalLayout>
    </Modal>
  );

  render() {
    const { categories } = this.props;
    const { loading } = this.state;

    return (
      <PageLayout title="Post Categories">
        <PostCategories
          categories={ categories }
          onClickAction={ this.onOpenCreateModal }
          onEdit={ this.onOpenEditModal }
          onDelete={ this.onOpenDeleteModal }
          loading={ loading }
        />
        { this.renderCreateCategoryModal() }
        { this.renderEditCategoryModal() }
        { this.renderDeleteCategoryModal() }
      </PageLayout>
    );
  }
}

const mapStateToProps = ({ post }) => ({
  categories: post.categories
});

export default connect(mapStateToProps)(withAuthentication(PostCategoriesContainer));
