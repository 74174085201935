import { arrayOf, node, number, shape, string } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import withAuthentication from '../../auth/withAuthentication';

import PageLayout from '../../../components/layout/page/PageLayout';
import ReputationBadges from '../../../components/members/reputation/badges/ReputationBadges';
import { ReactComponent as BlackBelt } from '../../../icons/reputation/levels/black-belt.svg';

const ReputationBadgesContainer = ({ badges }) => (
  <PageLayout title="Achievement Badges">
    <ReputationBadges badges={ badges } />
  </PageLayout>
);

ReputationBadgesContainer.defaultProps = {
  badges: [{
    name: 'The Warrior',
    description: 'The user helps the team enforce the community rules',
    icon: <BlackBelt />,
    points: 20
  }, {
    name: 'Night Owl',
    description: 'The user must create 100+ posts between 1AM and 5AM',
    icon: <BlackBelt />,
    points: 50
  }, {
    name: 'Well Traveled',
    description: 'The user posts deals in more than 2 countries',
    icon: <BlackBelt />,
    points: 100
  }]
};

ReputationBadgesContainer.propTypes = {
  badges: arrayOf(shape({
    name: string.isRequired,
    description: string.isRequired,
    points: number.isRequired,
    icon: node.isRequired
  }))
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(withAuthentication(ReputationBadgesContainer));
