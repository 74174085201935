import React from 'react';
import { connect } from 'react-redux';
import { func } from 'prop-types';

import { loadProfile } from '../../../actions/profile.actions';
import { updateAdmin } from '../../../actions/member.actions';

import withAuthentication from '../../auth/withAuthentication';
import AdminDetails from '../../../components/admin/personal-details/AdminDetails';
import PageLayout from '../../../components/layout/page/PageLayout';

class AdminDetailsContainer extends React.Component {
  async componentDidMount() {
    const { dispatch } = this.props;

    dispatch(loadProfile());
  }

  onUpdateAdmin = (admin) => {
    const { dispatch, profile } = this.props;
    dispatch(updateAdmin(profile.id, admin));
  };

  render() {
    const { profile, errors } = this.props;

    return (
      <PageLayout title="Personal Details">
        <AdminDetails profile={ profile } onSave={ this.onUpdateAdmin } errors={ errors } />
      </PageLayout>
    );
  }
}

AdminDetailsContainer.defaultProps = {
  profile: undefined
};

AdminDetailsContainer.propTypes = {
  dispatch: func.isRequired
};

const mapStateToProps = ({ profile, member }) => ({
  profile: profile.profile,
  errors: member.memberErrors
});

export default connect(mapStateToProps)(withAuthentication(AdminDetailsContainer));
