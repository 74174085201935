import React from 'react';

import { MODERATED_MEMBER_DETAIL_PROP_TYPE } from '../../../tools/prop.types';
import withAuthentication from '../../auth/withAuthentication';

import ModeratedMemberDetail from '../../../components/members/moderation/moderated/detail/ModeratedMemberDetail';
import PageLayout from '../../../components/layout/page/PageLayout';

class ModeratedMemberDetailContainer extends React.Component {
  componentDidMount() {
    // TODO: get id from url and fetch
  }

  updateStatus(updatedStatus) {
    // TODO: call service
  }

  render() {
    return (
      <PageLayout title="Moderation Details">
        <ModeratedMemberDetail item={ this.props.item } confirmStatusUpdate={ this.updateStatus } />
      </PageLayout>
    );
  }
}

ModeratedMemberDetailContainer.defaultProps = {
  item: {
    userName: 'Sam Smith',
    status: 'Temporary Ban',
    dateJoined: new Date(2021, 1, 1),
    location: 'Sandton, Johannesburg',
    previousOffences: [
      { reasonForReport: 'Inappropriate', dateOfAction: new Date(2020, 1, 1), actionTaken: 'Permanent Ban' },
      { reasonForReport: 'Inappropriate', dateOfAction: new Date(2020, 1, 1), actionTaken: 'Temporary Ban' },
      { reasonForReport: 'Inappropriate', dateOfAction: new Date(2020, 1, 1), actionTaken: 'Permanent Ban' }
    ],
    imgSrc: 'https://avatars.githubusercontent.com/u/46442564?v=4'
  }
};

ModeratedMemberDetailContainer.propTypes = {
  item: MODERATED_MEMBER_DETAIL_PROP_TYPE
};

export default withAuthentication(ModeratedMemberDetailContainer);
