export const tableColumns = [
  { Header: '', accessor: 'avatar' },
  { Header: 'Full Name', accessor: 'fullName' },
  { Header: 'Date', accessor: 'date_joined' },
  { Header: 'User level', accessor: (curator) => curator.user_level ?? 'N/A' },
  { Header: 'Followers', accessor: (curator) => curator.followers?.length ?? 0 },
  { Header: 'Connections', accessor: (curator) => curator.connections?.length ?? 0 },
  { Header: 'Posts', accessor: (curator) => curator.posts_count ?? 0 },
  { Header: 'Most used category', accessor: (curator) => curator.mostUsedCategory ?? 'N/A' }
];

export const tableModifiers = {
  avatar: {
    centered: true,
    thin: true
  },
  actions: {
    centered: true
  }
};

export const sortItems = [
  {
    value: 'newest',
    text: 'Sort by Newest First'
  }
];
