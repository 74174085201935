export const LOGIN = '[AUTH_STORE] Login with SmartBiii account';
export const SET_LOGIN_ERRORS = '[AUTH_STORE] Set login errors';
export const SET_TOKEN = '[AUTH_STORE] Set auth token';
export const REMEMBER_ME = '[AUTH_STORE] Set remember me';
export const requestLogin = (email, password, rememberMe, history, onDone) => ({
  type: LOGIN,
  email,
  password,
  rememberMe,
  history,
  onDone
});

export const COMPLETE_PROFILE = '[AUTH_STORE] Complete profile';
export const SET_COMPLETE_ERRORS = '[AUTH_STORE] Set complete profile errors';
export const requestCompleteProfile = (profileId, profile, history, onDone) => ({
  type: COMPLETE_PROFILE,
  profileId,
  profile,
  history,
  onDone
});

export const PROFILE_ID = '[AUTH_STORE] Profile ID';

export const VALIDATE_EMAIL = '[AUTH_STORE] Validate email';
export const SET_PASSWORD_ERRORS = '[AUTH_STORE] Set password errors';
export const validateEmail = (email, history, onDone) => ({
  type: VALIDATE_EMAIL,
  email,
  history,
  onDone
});

export const VERIFY_EMAIL = '[AUTH_STORE] Verify email';
export const verifyEmail = (email, history, onDone) => ({
  type: VERIFY_EMAIL,
  email,
  history,
  onDone
});

export const VALIDATE_OTP = '[AUTH_STORE] Validate OTP';
export const validateOTP = (token, otp, history) => ({
  type: VALIDATE_OTP,
  token,
  otp,
  history
});

export const RESEND_OTP = '[AUTH_STORE] Resend OTP';
export const resendOTP = (token) => ({
  type: RESEND_OTP,
  token
});

export const RESET_PASSWORD = '[AUTH_STORE] Reset password';
export const resetPassword = (token, password, password_confirm, history, onDone) => ({
  type: RESET_PASSWORD,
  token,
  password,
  password_confirm,
  history,
  onDone
});

export const LOGOUT = '[AUTH_STORE] Perform Logout';
export const performLogout = () => ({
  type: LOGOUT
});
