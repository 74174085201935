import Cookies from 'js-cookie';

/** Retrieves the remember me cookie */
export const getRememberMeCookie = () => Cookies.get('smartbiii-remember');

/** Sets the remember me cookie */
export const setRememberMeCookie = (token) => Cookies.set('smartbiii-remember', token);

/** Remove the remember me cookie */
export const removeRememberMeCookie = (token) => Cookies.remove('smartbiii-remember');

/** Sets the auth token cookie */
export const setAuthTokenCookie = (token) => Cookies.set('smartbiii-auth-token', token);

/** Get the auth token cookie */
export const getAuthTokenCookie = () => Cookies.get('smartbiii-auth-token');

/** Remove the auth token cookie */
export const removeAuthTokenCookie = () => Cookies.remove('smartbiii-auth-token');


const ACCESS_TOKEN_ID = 'smartbiii-auth-token';
export const getAuthHeaders = () => ({ Authorization: `Token ${ Cookies.get(ACCESS_TOKEN_ID) }` });
