import { SET_PROFILE } from '../actions/profile.actions';

export const initialState = {
  profile: undefined
};

export function profileReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PROFILE:
      const { profile } = action;
      return {
        ...state,
        profile
      };
    default:
      return state;
  }
}
