import { arrayOf, func, number, shape, string } from 'prop-types';
import React, { useState } from 'react';

import { clone, noOp } from '../../../../tools/helpers';
import { getPointWithSign } from '../rep.helper';

import Button from '../../../button/Button';
import { ReactComponent as IconIncrement } from '../../../../icons/reputation/matrix/icon-increment.svg';
import { ReactComponent as IconDecrement } from '../../../../icons/reputation/matrix/icon-decrement.svg';

import './reputation-points-matrix.scss';

const Incrementor = ({ action, onIconClick }) => (
  <div className="incrementor">
    <div className="incrementor__decrease" onClick={ () => onIconClick(action, -1) }><IconDecrement /></div>
    <span>{ getPointWithSign(action.points) }</span>
    <div className="incrementor__increase" onClick={ () => onIconClick(action, 1) }><IconIncrement /></div>
  </div>
);

const renderPoints = (action, editMode, onIconClick) => {
  if (!editMode) {
    return <label className="points">{ getPointWithSign(action.points) }</label>;
  }

  return <Incrementor action={ action } onIconClick={ onIconClick } />;
};

const renderActionGroupItems = (actions, editMode, onIconClick) => actions.map((action) => (
  <div className="action-group__item" key={ action.id }>
    <label>{ action.description }</label>
    { renderPoints(action, editMode, onIconClick) }
  </div>
));

const ActionGroup = ({ group: { name, actions }, editMode, onIconClick }) => (
  <div className="action-group" key={ name }>
    <h5 className="action-group__name">{ name }</h5>
    <div className="action-group__headers">
      <label>Action</label>
      <label>Points</label>
    </div>
    { renderActionGroupItems(actions, editMode, onIconClick) }
  </div>
);

const renderButtonActions = (editMode, editPoints, onCancel) => {
  const editOrSave = editMode ? 'Save Changes' : 'Edit Matrix';

  return (
    <div className="reputation-points-matrix__edit-actions">
      { editMode && <Button blank label="Cancel" onClick={ onCancel } /> }
      <Button label={ editOrSave } onClick={ editPoints } />
    </div>
  );
};

const ReputationPointsMatrix = ({ matrix, onSave }) => {
  const [editMode, setEditMode] = useState(false);
  const [groups, setGroups] = useState(clone(matrix));

  const editPoints = () => {
    if (!editMode) {
      setEditMode(true);
    } else {
      setEditMode(false);
      onSave(groups);
    }
  };

  const onCancel = () => {
    setEditMode(false);
    setGroups(clone(matrix));
  };

  const onIconClick = (item, value) => {
    for (const group of groups) {
      const matchingItem = group.actions.find(itemComparator => item.id === itemComparator.id);
      if (matchingItem) {
        matchingItem.points += value;
        break;
      }
    }

    setGroups([...groups]);
  };

  return (
    <div className="reputation-points-matrix">
      { renderButtonActions(editMode, editPoints, onCancel) }
      <div className="reputation-points-matrix__actions">
        { groups.map(group => <ActionGroup
          key={ group.name }
          setActions={ setGroups }
          group={ group }
          editMode={ editMode }
          onIconClick={ onIconClick }
        />) }
      </div>
    </div>
  );
};

ReputationPointsMatrix.defaultProps = {
  actions: [],
  onSave: noOp
};

ReputationPointsMatrix.propTypes = {
  matrix: arrayOf(shape({
    name: string.isRequired,
    actions: arrayOf(shape({
      description: string.isRequired,
      points: number.isRequired
    }))
  })),
  onSave: func
};

export default ReputationPointsMatrix;
