import React, { useMemo, useState } from 'react';
import { arrayOf, node, oneOfType, shape, number } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';

import { getClassNames } from '../../../tools/helpers';

import Profile from '../../profile/Profile';

import './admin-layout.scss';

const AdminLayout = ({ 
  sideContent, 
  profile, 
  activities,
  notifications,
  markAllAsRead,
  markAsRead,
  children,
  expanded,
  onConfirm,
  onDelete,
  onMarkAsProcessed,
  connections,
  onLogOut,
  notificationLoadMore,
  loading,
  count,
  unreadCount }) => {
  const history = useHistory();
  const location = useLocation();

  const isDashboard = useMemo(() => {
    return location.pathname === '/member/dashboard';
  }, [location.pathname]);

  const onNavigateDetails = () => {
    history.push('/admin/personal-details');
  };

  const onNavigateAccount = () => {
    history.push('/admin/account-details');
  };

  const onNavigateNotificationsSettings = () => {
    setOpenActivity(false);
    history.push(`/member/${profile?.id}/notification-settings`);
  };

  const [openActivity, setOpenActivity] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);

  return (
    <div className={ getClassNames('admin-layout', { expanded, full: isDashboard }) }>
      <div className="admin-layout__sidebar">
        { sideContent }
      </div>
      <div className="admin-layout__body">
        <div className="admin-layout__profile">
          <Profile
            onMarkAsProcessed={onMarkAsProcessed}
            onConfirm={onConfirm}
            onDelete={onDelete}
            profile={profile}
            activities={activities}
            notifications={notifications}
            notificationLoadMore={notificationLoadMore}
            loading={loading}
            count={count}
            unreadCount={unreadCount}
            connections={connections}
            markAsRead={markAsRead}
            markAllAsRead={markAllAsRead}
            onNavigateDetails={onNavigateDetails}
            onNavigateAccount={onNavigateAccount}
            onNavigateNotificationsSettings={onNavigateNotificationsSettings}
            activityOpen={openActivity}
            setOpenActivity={setOpenActivity}
            notificationsOpen={openNotifications}
            setNotificationsOpen={setOpenNotifications}
            onLogOut={onLogOut}
          />
        </div>
        <div className="admin-layout__content">
          { children }
        </div>
      </div>
    </div>
  );
};

AdminLayout.defaultProps = {
  activities: [],
  notifications: []
};

AdminLayout.propTypes = {
  sideContent: oneOfType([arrayOf(node), node]).isRequired,
  children: oneOfType([arrayOf(node), node]).isRequired,
  activities: arrayOf(
    shape({
      id: number,
      action: number
    })
  ),
  notifications: arrayOf(shape({}))
};

export default AdminLayout;
