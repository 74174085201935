import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { verifyEmail } from '../../../actions/auth.actions';

import { ReactComponent as AdminLogo } from '../../../icons/admin-logo.svg';

import AuthLayout from '../../../components/layout/auth/AuthLayout';
import VerifyAccount from '../../../components/auth/verify-account/VerifyAccount';

const VerifyAccountContainer = ({ dispatch, errors }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const onVerifyEmail = (email) => {
    setLoading(true);
    dispatch(verifyEmail(email, history, () => setLoading(false)));
  };

  return (
    <AuthLayout title="Admin Verification" subtitle="Please enter your email so we can verify who you are" logo={ <AdminLogo /> }>
      <VerifyAccount errors={ errors } onVerifyEmail={ onVerifyEmail } loading={ loading } />
    </AuthLayout>
  );
};

const mapStateToProps = ({ auth }) => ({
  errors: auth.passwordErrors
});

export default connect(mapStateToProps)(VerifyAccountContainer);
