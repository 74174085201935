import React from 'react';

import Button from '../../button/Button';

import './delete-tags.scss';

  const DeleteTags = ({ onClose, deleteTag }) => (
  <div className="delete-tags">
    <div className="delete-tags__header">
      <p>Are you sure you want to delete this tag? All associated data will be lost</p>
    </div>
    <div className="delete-tags__action">
      <Button label="Yes, Delete Tag" onClick={ deleteTag } />
      <Button blank label="No, Keep Tag" onClick={ onClose } />
    </div>
  </div>
);

export default DeleteTags;
